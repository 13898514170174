import { createAsyncThunk } from "@reduxjs/toolkit";
import { appInsights } from "../../config/appInsights";
import { postRequest } from "../utils/PostRequest";
import { v4 as uuidv4 } from 'uuid';

export const postAllDomainMappingsToExcel = createAsyncThunk<any, any>('DomainMapping/ExportAllDomainMappingsToExcel', async (payload: any) => {
  var correlationId = uuidv4();
  appInsights.trackEvent({ name: "Sending request to DomainMapping/ExportAllDomainMappingsToExcel data", properties: { payload: JSON.stringify(payload), correlationId: correlationId, page: "Domain Mapping" } });
  const response = await postRequest<any, any>('DomainMapping/ExportAllDomainMappingsToExcel', payload, correlationId);
  response.data === null ? appInsights.trackEvent({ name: "Data not fetched from ExportAllDomainMappingsToExcel api" }) : appInsights.trackEvent({ name: "Data fetched from GetJobTitleMappingData api", properties: { correlationId: correlationId } });
  return response.data;
})
