import { createAsyncThunk } from "@reduxjs/toolkit"
import { getRequestForExcel } from "../utils/GetRequest"
import { appInsights } from "../../config/appInsights";
import { DownloadExcelData } from "../utils/ExcelHandler";
import { v4 as uuidv4 } from 'uuid';

export const getLeadEnrichmentTemplate = createAsyncThunk<any>('FileUpload/DownloadTemplate', async () => {
  var correlationId = uuidv4();
  appInsights.trackEvent({ name: "Sending request to FileUpload/DownloadTemplate data", properties: { correlationId: correlationId, page: "Lead Enrichment" } });
  await getRequestForExcel<string>(`FileUpload/DownloadTemplate`, correlationId)
    .then(response => {
      DownloadExcelData(response);
      appInsights.trackEvent({ name: "Data fetched from FileUpload/DownloadTemplate api", properties: { correlationId: correlationId } })
    }).catch(error => {
      appInsights.trackEvent({ name: "Data fetched from FileUpload/DownloadTemplate api", properties: { error: error, correlationId: correlationId } })
    });
});

