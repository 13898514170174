import { createAsyncThunk } from "@reduxjs/toolkit"
import { getRequest } from "../../services/utils/GetRequest"
import { appInsights } from "../../config/appInsights";
import { v4 as uuidv4 } from 'uuid';

export const getAuthorizedLanguageData = createAsyncThunk<any>('JobTitleMapping/GetAuthorizedLanguageData', async () => {
  var correlationId = uuidv4();
  appInsights.trackEvent({ name: "Sending request to JobTitleMapping/GetAuthorizedLanguageData data", properties: { correlationId: correlationId, page: "Job Title Mapping" } });
  const response = await getRequest<string>('JobTitleMapping/GetAuthorizedLanguageData', correlationId);
  response.data === null ? appInsights.trackEvent({ name: "Data not fetched from JobTitleMapping/GetAuthorizedLanguageData api", properties: {correlationId: correlationId } }) : appInsights.trackEvent({ name: "Data fetched from JobTitleMapping/GetAuthorizedLanguageData api", properties: { data: JSON.stringify(response.data), correlationId: correlationId } });
  return response.data;
})


