import { createSlice } from "@reduxjs/toolkit";
import { RequestStatus } from '../../../model/ServiceRequestStatus.model'

import { getRequestFileTableData } from '../../../services/LeadEnrichment/GetRequestFileTableData.api'
import { uploadLeadsForEnrichment } from '../../../services/LeadEnrichment/PostUploadLeadsForEnrichment'
import { getEnrichedFile } from "../../../services/LeadEnrichment/DownloadEnrichedFile.api";
import { getLeadEnrichmentTemplate } from "../../../services/LeadEnrichment/GetLeadEnrichmentTemplate.api";

import { colObject, rowObject } from "../LeadEnrichmentAttributes";

import { convert_milliseconds_to_date } from "../../../utils/DateConversionUtil";

export const initialState = {
  rowData: [] as rowObject[],
  columnData: [] as colObject[],
  status: RequestStatus.idle,
  getRequestFileTableDataStatus: RequestStatus.idle,
  uploadLeadsForEnrichmentStatus: RequestStatus.idle,
  getEnrichedFileStatus: RequestStatus.idle,
  getLeadEnrichmentTemplateStatus: RequestStatus.idle,
  nothingToShow: undefined as string | undefined,

  errorUpload: undefined as string | undefined,
  errorFetch: undefined as string | undefined,
  successMsg: undefined as string | undefined,

  validationStatus: "Not validated"
}

const columns: colObject[] = [
  { field: "fileName", content: "File Name", sortable: true },
  { field: "uploadedBy", content: "Uploaded By", sortable: true },
  { field: "status", content: "Status", sortable: true },
  { field: "uploadedOn", content: "Uploaded On", sortable: true },
  { field: "download", content: "Download", sortable: false }
];

export const RESET_STATE = 'leadEnrichment/resetState';

export const resetState = () => ({
  type: RESET_STATE,
});

const leadEnrichmentSlice = createSlice({
  name: 'leadEnrichment',
  initialState,
  reducers: {
    setRowData: (state, action) => {
      state.rowData = action.payload;
    },
    setColumnData: (state, action) => {
      state.columnData = action.payload;
    },
    setValidationStatus: (state, action) => {
      state.validationStatus = action.payload;
    },
    setErrorFetch: (state, action) => {
      state.errorFetch = action.payload;
    },
    setErrorUpload: (state, action) => {
      state.errorFetch = action.payload;
    },
    clearErrorFetch: (state) => {
      state.errorFetch = undefined;
    },
    clearErrorUpload: (state) => {
      state.errorUpload = undefined;
    },
    clearSuccessMsg: (state) => {
      state.successMsg = undefined;
    },
    clearNothingToShow: (state) => {
      state.nothingToShow = undefined;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(RESET_STATE, (state, action) => {
        return initialState;
      })
      .addCase(getRequestFileTableData.pending, (state, _) => {
        state.getRequestFileTableDataStatus = RequestStatus.loading;
        state.errorFetch = undefined;
        state.nothingToShow = undefined;
        state.rowData = [];
      })
      .addCase(getRequestFileTableData.fulfilled, (state, action) => {
        state.getRequestFileTableDataStatus = RequestStatus.succeeded
        if (action.payload?.error?.length > 0) {
          state.errorFetch = action.payload.error;
        }
        else {
          const newData: rowObject[] = action.payload?.data?.map((row: any, index: number) => ({
            id: index + 1,
            fileName: row.FileName,
            uploadedBy: row.UploadedBy,
            status: row.Status,
            uploadedOn: convert_milliseconds_to_date(row.UploadedOn),
            partitionKey: row.PartitionKey,
            rowKey: row.RowKey
          }));
          if (newData.length === 0) {
            state.nothingToShow = "No bulk upload requests to show."
          }
          state.rowData = newData;
          state.columnData = columns;
          state.errorFetch = undefined
        }
      })
      .addCase(getRequestFileTableData.rejected, (state, action) => {
        state.getRequestFileTableDataStatus = RequestStatus.failed
        state.errorFetch = action.error?.message
        state.nothingToShow = undefined;
      })
      .addCase(uploadLeadsForEnrichment.pending, (state, _) => {
        state.uploadLeadsForEnrichmentStatus = RequestStatus.loading;
        state.errorUpload = undefined;
      })
      .addCase(uploadLeadsForEnrichment.fulfilled, (state, action) => {
        state.uploadLeadsForEnrichmentStatus = RequestStatus.succeeded
        if (action.payload?.errorMessage?.length > 0) {
          state.errorUpload = action.payload.errorMessage;
        }
        else {
          state.errorUpload = undefined;
          state.successMsg = "File submitted successfully.";
        }
      })
      .addCase(uploadLeadsForEnrichment.rejected, (state, action) => {
        state.uploadLeadsForEnrichmentStatus = RequestStatus.failed
        state.errorUpload = action.error?.message
      })
      .addCase(getEnrichedFile.pending, (state, _) => {
        state.getEnrichedFileStatus = RequestStatus.loading;
        state.errorFetch = undefined;
      })
      .addCase(getEnrichedFile.fulfilled, (state, action) => {
        state.getEnrichedFileStatus = RequestStatus.succeeded
        if (action.payload?.Error === "Error Response") {
          state.errorFetch = action.payload?.Response;
        }
        else {
          state.errorFetch = undefined;
        }
      })
      .addCase(getEnrichedFile.rejected, (state, action) => {
        state.getEnrichedFileStatus = RequestStatus.failed
        state.errorFetch = action.error?.message
      })
      .addCase(getLeadEnrichmentTemplate.pending, (state, _) => {
        state.getLeadEnrichmentTemplateStatus = RequestStatus.loading;
        state.errorFetch = undefined;
      })
      .addCase(getLeadEnrichmentTemplate.fulfilled, (state, action) => {
        state.getLeadEnrichmentTemplateStatus = RequestStatus.succeeded
        if (action.payload?.Error === "Error Response") {
          state.errorFetch = action.payload?.Response;
        }
        else {
          state.errorFetch = undefined;
        }
      })
      .addCase(getLeadEnrichmentTemplate.rejected, (state, action) => {
        state.getLeadEnrichmentTemplateStatus = RequestStatus.failed
        state.errorFetch = action.error?.message
      })

  }
})

export const { setRowData, setColumnData, setValidationStatus, setErrorFetch, setErrorUpload, clearErrorFetch, clearErrorUpload, clearSuccessMsg, clearNothingToShow } = leadEnrichmentSlice.actions;
export default leadEnrichmentSlice.reducer;