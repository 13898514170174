import { createSlice } from "@reduxjs/toolkit";
import { RequestStatus } from '../../../model/ServiceRequestStatus.model';

import { getAuthorizedCountryData } from "../../../services/CommonApi/GetAuthorizedCountryData.api";
import { getMappingDataByCountry } from "../../../services/DomainSuppression/GetMappingDataByCountry.api";
import { getIspDomainMappingRequest } from "../../../services/DomainSuppression/GetIspDomainMappingRequest.api";
import { uploadFileForSuppression } from "../../../services/DomainSuppression/PostUploadFileForSuppression.api";
import { saveData } from "../../../services/DomainSuppression/PostSaveData.api";
import { deleteMapping } from "../../../services/DomainSuppression/Delete.api";
import { getDomainMappingInputFile } from "../../../services/DomainMapping/GetDomainMappingInputFile.api";
import { getDomainMappingProcessedFile } from "../../../services/DomainMapping/GetDomainMappingProcessedFile.api";

import { authCountry, domainSuppressionColObject, domainSuppressionRowObject, bulkUploadColObject, bulkUploadRowObject } from "../DomainSuppressionAttributes";

import { convert_milliseconds_to_date } from "../../../utils/DateConversionUtil";

const bulkUploadColumns: bulkUploadColObject[] = [
    { field: "FileName", content: "File Name", sortable: true },
    { field: "UploadedBy", content: "Uploaded By", sortable: true },
    { field: "UploadedOn", content: "Uploaded On", sortable: true },
    { field: "Status", content: "Status", sortable: true },
    { field: "download", content: "Input File", sortable: false },
    { field: "download1", content: "Processed File", sortable: false },
];

const domainSuppressionColumns: domainSuppressionColObject[] = [
    { field: "ProviderName", content: "ISP Name", sortable: true },
    { field: "ProviderLink", content: "ISP Link", sortable: true },
    { field: "Domain", content: "Domain", sortable: true },
    { field: "Suppressed", content: "Suppressed", sortable: true },
    { field: "DateUpdated", content: "Updated Date", sortable: true },
    { field: "edit", content: "Edit", sortable: false },
    { field: "delete", content: "Delete", sortable: false },
];

const initialState = {
    domainSuppressionRowData: <any>[],
    domainSuppressionColumnData: <any>[],
    bulkDomainSuppressionRowData: <any>[],
    bulkDomainSuppressionColumnData: <any>[],
    authorizedCountry: [] as authCountry[],
    selectedCountry: { DisplayName: "", Value: "", IsEnabled: true } as authCountry,
    errorUpload: undefined as string | undefined,
    errorFetch: undefined as string | undefined,
    successMsg: undefined as string | undefined,
    nothingToShow: undefined as string | undefined,
    validationStatus: "Not validated",
    editIspName: "" as string,
    editIspLink: "" as string,
    editDomain: "" as string,
    editSuppressed: false as boolean,

    status: RequestStatus.idle,
    getAuthorizedCountryDataStatus: RequestStatus.idle,
    getMappingDataByCountryStatus: RequestStatus.idle,
    getIspDomainMappingRequestStatus: RequestStatus.idle,
    saveDataStatus: RequestStatus.idle,
    deleteMappingStatus: RequestStatus.idle,
    uploadFileForSuppressionStatus: RequestStatus.idle,
    getDomainMappingInputFileStatus: RequestStatus.idle,
    getDomainMappingProcessedFileStatus: RequestStatus.idle,
}

export const RESET_STATE = 'domainSuppression/resetState';


export const resetState = () => ({
    type: RESET_STATE,
});


const domainSuppressionSlice = createSlice({
    name: 'domainSuppression',
    initialState,
    reducers: {
        setDomainSuppressionRowData: (state, action) => {
            state.domainSuppressionRowData = [];
            state.domainSuppressionRowData = action.payload;
        },
        setDomainSuppressionColumnData: (state, action) => {
            state.domainSuppressionColumnData = [];
            state.domainSuppressionColumnData = action.payload;
        },
        setBulkDomainSuppressionRowData: (state, action) => {
            state.bulkDomainSuppressionRowData = [];
            state.bulkDomainSuppressionRowData = action.payload;
        },
        setBulkDomainSuppressionColumnData: (state, action) => {
            state.bulkDomainSuppressionColumnData = [];
            state.bulkDomainSuppressionColumnData = action.payload;
        },
        setAuthorizedCountry: (state, action) => {
            state.authorizedCountry = action.payload;
        },
        setSelectedCountry: (state, action) => {
            state.selectedCountry = action.payload;
        },
        setValidationStatus: (state, action) => {
            state.validationStatus = action.payload;
        },
        setErrorUploadMessage: (state, action) => {
            state.errorUpload = action.payload;
        },
        clearErrorUploadMessage: (state) => {
            state.errorUpload = undefined
        },
        setErrorFetchMessage: (state, action) => {
            state.errorFetch = action.payload;
        },
        clearErrorFetchMessage: (state) => {
            state.errorFetch = undefined
        },
        clearSuccessMsg: (state) => {
            state.successMsg = undefined;
        },
        clearNothingToShow: (state) => {
            state.nothingToShow = undefined;
        },
        setEditIspName: (state, action) => {
            state.editIspName = action.payload;
        },
        setEditIspLink: (state, action) => {
            state.editIspLink = action.payload;
        },
        setEditDomain: (state, action) => {
            state.editDomain = action.payload;
        },
        setEditSuppressed: (state, action) => {
            state.editSuppressed = action.payload;
        },

    },
    extraReducers(builder) {
        builder
            .addCase(RESET_STATE, (state, action) => {
                return initialState;
            })
            .addCase(getAuthorizedCountryData.pending, (state, action) => {
                state.getAuthorizedCountryDataStatus = RequestStatus.loading;
            })
            .addCase(getAuthorizedCountryData.fulfilled, (state, action) => {
                state.getAuthorizedCountryDataStatus = RequestStatus.succeeded;
                let authorizedCountry: authCountry[] = [];
                if (action.payload.Error === "Error Response") {
                    state.errorFetch = action.payload.Response;
                }
                else {
                    state.errorFetch = undefined
                }
            })
            .addCase(getAuthorizedCountryData.rejected, (state, action) => {
                state.getAuthorizedCountryDataStatus = RequestStatus.failed;
                state.errorFetch = action.error.message;
            })
            .addCase(getMappingDataByCountry.pending, (state, action) => {
                state.getMappingDataByCountryStatus = RequestStatus.loading;
                state.domainSuppressionRowData = [];
                state.errorFetch = undefined;
                state.nothingToShow = undefined;
            })
            .addCase(getMappingDataByCountry.fulfilled, (state, action) => {
                state.getMappingDataByCountryStatus = RequestStatus.succeeded;
                if (action.payload.Error === "Error Response") {
                    state.errorFetch = action.payload.Response;
                }
                else {
                    let domainSuppressionRowData: domainSuppressionRowObject[] = [];
                    for (let i = 0; i < action.payload.length; i++) {
                        let newObject: domainSuppressionRowObject = { id: action.payload[i].Id, ProviderName: action.payload[i].ProviderName, ProviderLink: action.payload[i].ProviderLink, Domain: action.payload[i].Domain, Suppressed: action.payload[i].Suppressed.toString(), DateUpdated: convert_milliseconds_to_date(action.payload[i].DateUpdated) };
                        domainSuppressionRowData.push(newObject);
                    }
                    if (domainSuppressionRowData.length === 0) {
                        state.nothingToShow = "No domain supressions to show."
                    }
                    domainSuppressionRowData.sort((a, b) => (Date.parse(a.DateUpdated) > Date.parse(b.DateUpdated)) ? -1 : 1);
                    state.domainSuppressionRowData = domainSuppressionRowData;
                    state.domainSuppressionColumnData = domainSuppressionColumns;
                    state.errorFetch = undefined;
                }
            })
            .addCase(getMappingDataByCountry.rejected, (state, action) => {
                state.getMappingDataByCountryStatus = RequestStatus.failed;
                state.errorFetch = action.error.message;
            })
            .addCase(getIspDomainMappingRequest.pending, (state, action) => {
                state.getIspDomainMappingRequestStatus = RequestStatus.loading;
                state.errorFetch = undefined;
                state.nothingToShow = undefined;
                state.bulkDomainSuppressionRowData = [];
            })
            .addCase(getIspDomainMappingRequest.fulfilled, (state, action) => {
                state.getIspDomainMappingRequestStatus = RequestStatus.succeeded;
                if (action.payload.errorMessage?.length > 0) {
                    state.errorFetch = action.payload.errorMessage;
                }
                else {
                    const data = action.payload.data;
                    let bulkUploadRowData: bulkUploadRowObject[] = [];
                    for (let i = 0; i < data.length; i++) {

                        let newObject: bulkUploadRowObject = { id: i + 1, RowKey: data[i].RowKey, FileName: data[i].FileName, UploadedBy: data[i].UploadedBy, UploadedOn: convert_milliseconds_to_date(data[i].UploadedOn), Status: data[i].Status };
                        bulkUploadRowData.push(newObject);
                    }
                    if (bulkUploadRowData.length === 0) {
                        state.nothingToShow = "No bulk upload requests to show."
                    }
                    state.bulkDomainSuppressionRowData = bulkUploadRowData;
                    state.bulkDomainSuppressionColumnData = bulkUploadColumns;
                    state.errorFetch = undefined;
                }
            })
            .addCase(getIspDomainMappingRequest.rejected, (state, action) => {
                state.getIspDomainMappingRequestStatus = RequestStatus.failed;
                state.errorFetch = action.error.message;
            })
            .addCase(saveData.pending, (state, action) => {
                state.saveDataStatus = RequestStatus.loading;
                state.errorUpload = undefined;
            })
            .addCase(saveData.fulfilled, (state, action) => {
                state.saveDataStatus = RequestStatus.succeeded;
                if (action.payload.Error === "Error Response") {
                    state.errorUpload = action.payload.Response;
                    state.successMsg = undefined;
                }
                else {
                    state.errorUpload = undefined;
                    if (action.payload.Response === "Success") {
                        state.successMsg = "Domain supression saved successfully.";
                    }
                }

            })
            .addCase(saveData.rejected, (state, action) => {
                state.saveDataStatus = RequestStatus.failed;
                state.errorUpload = action.error.message;
                state.successMsg = undefined;
            })
            .addCase(deleteMapping.pending, (state, action) => {
                state.deleteMappingStatus = RequestStatus.loading;
                state.errorUpload = undefined;
            })
            .addCase(deleteMapping.fulfilled, (state, action) => {
                state.deleteMappingStatus = RequestStatus.succeeded;
                if (action.payload.Error === "Error Response") {
                    state.errorUpload = action.payload.Response;
                }
                else {
                    state.errorUpload = undefined;
                    if (action.payload.Response === "Success") {
                        state.successMsg = "Domain Supression deleted successfully.";
                    }
                }
            })
            .addCase(deleteMapping.rejected, (state, action) => {
                state.deleteMappingStatus = RequestStatus.failed;
                state.errorUpload = action.error.message;
                state.successMsg = undefined;
            })
            .addCase(uploadFileForSuppression.pending, (state, action) => {
                state.uploadFileForSuppressionStatus = RequestStatus.loading;
                state.errorUpload = undefined;
            })
            .addCase(uploadFileForSuppression.fulfilled, (state, action) => {
                state.uploadFileForSuppressionStatus = RequestStatus.succeeded;
                if (action.payload.errorMessage?.length > 0) {
                    state.errorUpload = action.payload.errorMessage;
                    state.successMsg = undefined;
                }
                else {
                    state.errorUpload = undefined;
                    state.successMsg = "File submitted successfully.";
                }
            })
            .addCase(uploadFileForSuppression.rejected, (state, action) => {
                state.uploadFileForSuppressionStatus = RequestStatus.failed;
                state.errorUpload = action.error.message;
                state.successMsg = undefined;
            })
            .addCase(getDomainMappingInputFile.pending, (state, action) => {
                state.getDomainMappingInputFileStatus = RequestStatus.loading;
                state.errorFetch = undefined;
            })
            .addCase(getDomainMappingInputFile.fulfilled, (state, action) => {
                state.getDomainMappingInputFileStatus = RequestStatus.succeeded;
                if (action.payload.Error === "Error Response") {
                    state.errorFetch = action.payload.Response;
                }
                else {
                    state.errorFetch = undefined;
                }
            })
            .addCase(getDomainMappingInputFile.rejected, (state, action) => {
                state.getDomainMappingInputFileStatus = RequestStatus.failed;
                state.errorFetch = action.error.message;
            })
            .addCase(getDomainMappingProcessedFile.pending, (state, action) => {
                state.getDomainMappingProcessedFileStatus = RequestStatus.loading;
                state.errorFetch = undefined;
            })
            .addCase(getDomainMappingProcessedFile.fulfilled, (state, action) => {
                state.getDomainMappingProcessedFileStatus = RequestStatus.succeeded;
                if (action.payload.Error === "Error Response") {
                    state.errorFetch = action.payload.Response;
                }
                else {
                    state.errorFetch = undefined;
                }
            })
            .addCase(getDomainMappingProcessedFile.rejected, (state, action) => {
                state.getDomainMappingProcessedFileStatus = RequestStatus.failed;
                state.errorFetch = action.error.message;
            })

    }
})

export const {
    setDomainSuppressionRowData,
    setDomainSuppressionColumnData,
    setBulkDomainSuppressionRowData,
    setBulkDomainSuppressionColumnData,
    setSelectedCountry,
    setValidationStatus,
    setErrorUploadMessage,
    clearErrorUploadMessage,
    setErrorFetchMessage,
    clearErrorFetchMessage,
    clearSuccessMsg,
    clearNothingToShow,
    setEditIspLink,
    setEditIspName,
    setEditDomain,
    setEditSuppressed
} = domainSuppressionSlice.actions;
export default domainSuppressionSlice.reducer;