import { createAsyncThunk } from "@reduxjs/toolkit"
import { postRequest } from "../utils/PostRequest"
import { appInsights } from "../../config/appInsights";
import { v4 as uuidv4 } from 'uuid';

export const saveData = createAsyncThunk<any, any>('IspDomainMapping/SaveData', async (payload) => {
    var correlationId = uuidv4();
    appInsights.trackEvent({ name: "Sending request to IspDomainMapping/SaveData data", properties: { payload: JSON.stringify(payload), correlationId: correlationId, page: "Domain Suppression" } });
    const response = await postRequest<any, any>('IspDomainMapping/SaveData', payload, correlationId);
    response.data === null ? appInsights.trackEvent({ name: "Data not fetched from IspDomainMapping/SaveData api", properties: { correlationId: correlationId } }) : appInsights.trackEvent({ name: "Data fetched from IspDomainMapping/SaveData api", properties: { data: JSON.stringify(response.data), correlationId: correlationId } });
    return response.data
});