import { Grid } from "../grid/grid";
import { SpinnerBetween } from "../../shared-components/loader/Spinner";
import { NotAuthorizedPage } from "../no-authorization-page/NotAuthorizedPage";

import { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../root-redux/RootState";
import { setValidationStatus, setErrorUpload, clearErrorFetch, clearErrorUpload, clearSuccessMsg, resetState } from "./lead-enrichment-redux/LeadEnrichment.redux";
import { RequestStatus } from '../../model/ServiceRequestStatus.model';

import { Text, Button, Icon } from "../../common/HarmonyEnablers";

import { getRequestFileTableData } from "../../services/LeadEnrichment/GetRequestFileTableData.api";
import { uploadLeadsForEnrichment } from "../../services/LeadEnrichment/PostUploadLeadsForEnrichment";
import { getLeadEnrichmentTemplate } from "../../services/LeadEnrichment/GetLeadEnrichmentTemplate.api";
import { getEnrichedFile } from "../../services/LeadEnrichment/DownloadEnrichedFile.api";

import * as XLSX from "xlsx";

import * as Constants from "../inputValidation/Constants";

import { ValidateFileData, ValidateFile } from "../inputValidation/ExcelValidation";

import "./LeadEnrichment.css";

export const LeadEnrichment: React.FC = () => {

  const [notAuthorized, setNotAuthorized] = useState<boolean>(false);
  const [isPermissionsFetched, setIsPermissionsFetched] = useState<boolean>(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const { rowData, columnData, getRequestFileTableDataStatus, uploadLeadsForEnrichmentStatus, errorFetch, errorUpload, successMsg, nothingToShow } = useSelector((state: RootState) => state.leadEnrichment);
  const { isNavPanelOpen } = useSelector((state: RootState) => state.nav);

  const dispatch = useDispatch();
  const dispatchApp = useDispatch<AppDispatch>();

  var fileReadSupported = false;
  if (window.File && window.FileReader) {
    fileReadSupported = true;
  }

  const fetchGridData = async (isPageRefreshed: boolean = false) => {
    try {
      if (isPageRefreshed) {
        resetErrorMessagesBeforeAnyAction();
      }
      await dispatchApp(getRequestFileTableData());
      resetMessagesAfterFiveSeconds();

    } catch (error) {
      return error;
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileInput = event.target;
    const file = event.target.files![0];
    const fileValidationStatus = ValidateFile(file);
    if (fileValidationStatus !== Constants.INPUT_VALID) {
      dispatch(setValidationStatus(fileValidationStatus));
      dispatch(setErrorUpload(fileValidationStatus));
      event.target.value = "";
      return;
    }
    const reader = new FileReader();
    reader.onload = (event) => {
      const bstr = event.target!.result;
      let workbook;
      try {
        workbook = XLSX.read(bstr, { type: 'binary' });
      } catch (e) {
        dispatch(setValidationStatus(Constants.INPUT_COULD_NOT_PARSE));
        dispatch(setErrorUpload(fileValidationStatus));
        fileInput.value = "";
        return;
      }

      const fileDataValidationStatus = ValidateFileData(workbook, "LeadEnrichment");
      if (fileDataValidationStatus !== Constants.INPUT_VALID) {
        dispatch(setValidationStatus(fileDataValidationStatus));
        dispatch(setErrorUpload(fileDataValidationStatus));
        fileInput.value = "";
        return;
      }
      if (errorUpload === undefined) {
        setSelectedFile(file);
      }
    }
    reader.readAsBinaryString(file);

  };

  const downloadTemplate = async () => {
    resetErrorMessagesBeforeAnyAction();
    await dispatchApp(getLeadEnrichmentTemplate());
    resetMessagesAfterFiveSeconds();
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    var reader = new FileReader();
    if (selectedFile) {
      var fileData = new FormData();
      fileData.append(selectedFile.name, selectedFile, selectedFile.name);
      await dispatchApp(uploadLeadsForEnrichment(fileData));
      resetMessagesAfterFiveSeconds();
      fetchGridData();
      if (event.target) {
        (event.target as HTMLFormElement).reset();
      }
      setSelectedFile(null);
    }
  };

  const handleClear = (event: any): void => {
    if (event.target) {
      (event.target as HTMLFormElement).reset();
    }
    setSelectedFile(null);

  };

  const handleDownload = async (rowid: any, type: any, fileName: any) => {
    resetErrorMessagesBeforeAnyAction();
    try {
      const payload = { "fileName": fileName };
      if (type === "inputfile") {
        await dispatchApp(getEnrichedFile(payload));
        resetMessagesAfterFiveSeconds();
      }
    }
    catch (err) {
      return err;
    }
  };

  const handleClearSuccessMsg = (): void => {
    dispatch(clearSuccessMsg());
  };

  const handleCloseErrorUpload = (): void => {
    dispatch(clearErrorUpload());
  };

  const handleCloseErrorFetch = (): void => {
    dispatch(clearErrorFetch());
  };

  const resetErrorMessagesBeforeAnyAction = (): void => {
    dispatch(clearErrorUpload());
    dispatch(clearErrorFetch());
  };

  const resetMessagesAfterFiveSeconds = (): void => {
    setTimeout(() => {
      dispatch(clearSuccessMsg());
    }, 5000);
  };

  const isFileSelected = selectedFile !== null;

  useEffect(() => {
    var userGroupsFromStorage = window.localStorage.getItem("user_groups");
    if (userGroupsFromStorage) {
      var parsedJSONgroups = JSON.parse(userGroupsFromStorage);
      if (parsedJSONgroups.length > 0 && (parsedJSONgroups.includes("EnrichmentUsers") || parsedJSONgroups.includes("SuperAdmin"))) {
        fetchGridData();
      }
      else {
        setNotAuthorized(true);
      }
      setIsPermissionsFetched(true);
    }
  }, []);

  useEffect(() => {
    return () => {
      dispatch(resetState());
    };
  }, [dispatch]);

  return (
    isPermissionsFetched ?
      notAuthorized ? <NotAuthorizedPage /> :
        <div className={isNavPanelOpen ? "wrapper-collapsed" : "wrapper"}>

          <div className={isNavPanelOpen ? "upload-file-collapsed" : "upload-file"}>

                      <Text role="heading" aria-level={1} appearance="heading-2" className="heading">
              Lead Enrichment
            </Text>
                      <Text role="heading" aria-level={3} appearance="heading-4" className="heading">
              Upload File
            </Text>
            <Text appearance="heading-6" className="heading">
              Please select a file to upload. The standard template can be downloaded here: <a href="#" onClick={downloadTemplate} style={{ color: "#0b62e4" }}>Download Template</a>
            </Text>


            <form onSubmit={handleSubmit} onReset={handleClear}>
              <div className={isNavPanelOpen ? "input-field-collapsed" : "input-field"}>
                <div className={isNavPanelOpen ? "input-text-collapsed" : "input-text"}>
                  <input
                    type="text"
                    placeholder="Select a file to upload"
                    value={selectedFile?.name || ""}
                    onClick={() => { resetErrorMessagesBeforeAnyAction(); document.getElementById("file-input")!.click() }}
                    readOnly
                    onKeyDown={(e) => { if (e.key === "Enter") { resetErrorMessagesBeforeAnyAction(); document.getElementById("file-input")!.click() } }}
                  />
                </div>

                <div className="input-file">
                  <input type="file" id="file-input" onChange={handleFileChange} hidden />
                  <label htmlFor="file-input" className="custom-button" onClick={() => resetErrorMessagesBeforeAnyAction()} tabIndex={0} onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      resetErrorMessagesBeforeAnyAction();
                      document.getElementById("file-input")!.click();
                    }
                  }}>Select</label>
                </div>
              </div>
              <div>
                <Button className="button" type="submit" appearance="primary" disabled={!isFileSelected} tabIndex={selectedFile ? 0 : -1}>
                  <Text>Submit</Text>
                </Button>
                <Button type="reset" className="button" appearance="secondary" disabled={!isFileSelected} tabIndex={selectedFile ? 0 : -1}>
                  <Text>Clear</Text>
                </Button>
              </div>
            </form>

          </div>
          {
            successMsg !== undefined && (
              <div id="success" className="lead-enrichment-success">
                <span><p>{successMsg}</p></span>
                <Icon name="chromeclose" onClick={handleClearSuccessMsg} onKeyDown={(e) => { if (e.key === 'Enter') { handleClearSuccessMsg(); } }} className="lead-enrichment-close-icon" tabIndex={0}></Icon>
              </div>
            )
          }
          {errorUpload !== undefined && (
            <div id="error" className="lead-enrichment-error">
              <span><p>{errorUpload}</p></span>
              <Icon name="chromeclose" onClick={handleCloseErrorUpload} onKeyDown={(e) => { if (e.key === 'Enter') { handleCloseErrorUpload(); } }} className="lead-enrichment-close-icon" tabIndex={0}></Icon>
            </div>
          )}
          {errorFetch !== undefined && (
            <div id="error" className="lead-enrichment-error">
              <span><p>{errorFetch}</p></span>
              <Icon name="chromeclose" onClick={handleCloseErrorFetch} onKeyDown={(e) => { if (e.key === 'Enter') { handleCloseErrorFetch(); } }} className="lead-enrichment-close-icon" tabIndex={0}></Icon>
            </div>
          )}
          {uploadLeadsForEnrichmentStatus === RequestStatus.loading && <div><SpinnerBetween /></div>}
          <div className={isNavPanelOpen ? "wrapper-collapsed" : "wrapper"}>
            <div className="grid">
                          <Text role="heading" aria-level={2} appearance="heading-3" className="heading">
                File Status
              </Text>

              <div>
                {getRequestFileTableDataStatus === RequestStatus.loading && <SpinnerBetween />}
                {
                  nothingToShow !== undefined && (
                    <Text appearance="heading-3">{nothingToShow}</Text>
                  )
                }
                {getRequestFileTableDataStatus === RequestStatus.succeeded && rowData.length > 0 && <Grid columnData={columnData} rowData={rowData} page="leadEnrichment" handleDownload={handleDownload} onRefresh={() => fetchGridData(true)} sortType="uploadedOn" isAscending={false} />}
              </div>
            </div>
          </div>
        </div>
      :
      <></>
  );
};
