import { createAsyncThunk } from "@reduxjs/toolkit"
import { getRequest } from "../utils/GetRequest"
import { appInsights } from "../../config/appInsights";
import { v4 as uuidv4 } from 'uuid';

export const getAuthorizedLanguageData = createAsyncThunk<any>('JobTitleMappingV2/GetAuthorizedLanguageData', async () => {
  var correlationId = uuidv4();
  appInsights.trackEvent({ name: "Sending request to JobTitleMappingV2/GetAuthorizedLanguageData data", properties: { correlationId: correlationId, page: "Job Title Mapping" } });
  const response = await getRequest<string>('JobTitleMappingV2/GetAuthorizedLanguageData', correlationId);
  response.data === null ? appInsights.trackEvent({ name: "Data not fetched from JobTitleMappingV2/GetAuthorizedLanguageData api", properties: {correlationId: correlationId } }) : appInsights.trackEvent({ name: "Data fetched from JobTitleMappingV2/GetAuthorizedLanguageData api", properties: { data: JSON.stringify(response.data), correlationId: correlationId } });
  return response.data;
})


