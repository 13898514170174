import { createAsyncThunk } from "@reduxjs/toolkit"
import { getRequest } from "../utils/GetRequest"
import { appInsights } from "../../config/appInsights";
import { v4 as uuidv4 } from 'uuid';

export const getRequestFileTableData = createAsyncThunk<any>('FileUpload/RequestFileTableData', async () => {
  var correlationId = uuidv4();
  appInsights.trackEvent({ name: "Sending request to FileUpload/RequestFileTableData data", properties: { correlationId: correlationId, page: "Lead Enrichment" } });
  const response = await getRequest<any>(`FileUpload/RequestFileTableData?_=${new Date().getTime()}`, correlationId);
  response.data === null ? appInsights.trackEvent({ name: "Data not fetched from FileUpload/RequestFileTableData api", properties: { data: JSON.stringify(response.data), correlationId: correlationId } }) : appInsights.trackEvent({ name: "Data fetched from FileUpload/RequestFileTableData api", properties: { correlationId: correlationId } });
  return response.data
})

