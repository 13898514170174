import { createSlice } from "@reduxjs/toolkit";
import { RequestStatus } from '../../../model/ServiceRequestStatus.model'
import { getBulkUploadsAll } from '../../../services/DomainMapping/GetBulkUploadsAll.api'
import { getDomainMappingCount } from '../../../services/DomainMapping/GetDomainMappingCount.api'
import { getDomainMappingInputFile } from '../../../services/DomainMapping/GetDomainMappingInputFile.api'
import { getDomainMappingProcessedFile } from '../../../services/DomainMapping/GetDomainMappingProcessedFile.api'
import { getExcelFile } from '../../../services/DomainMapping/GetExcelFile.api'
import { getDomainMappingTemplate } from '../../../services/DomainMapping/GetDomainMappingTemplate.api'
import { getPagedDomainMappings } from '../../../services/DomainMapping/GetPagedDomainMappings.api'
import { getUnmappedDomainsSuggestions } from '../../../services/DomainMapping/GetUnmappedDomainMappingSuggestions.api'
import { getUnmappedDomainMappings } from '../../../services/DomainMapping/GetUnmappedDomainMappings.api'
import { postAllDomainMappingsToExcel } from '../../../services/DomainMapping/PostAllDomainMappingsToExcel.api'
import { postAllUnmappedDomainsToExcel } from '../../../services/DomainMapping/PostAllUnmappedDomainsToExcel.api'
import { postDomainMappingBulkFile } from '../../../services/DomainMapping/PostDomainMappingBulkFile.api'
import { createDomainMappingSingle } from '../../../services/DomainMapping/PostCreateDomainMappingSingle.api'
import { deleteDomainMappingSingle } from '../../../services/DomainMapping/PostDeleteDomainMapping.api'
import { updateDomainMappingSingle } from '../../../services/DomainMapping/PostUpdateDomainMappingSingle.api'
import { getAccountDetails } from '../../../services/DomainMapping/GetAccountDetails.api'
import { getAuthorizedCountryData } from '../../../services/CommonApi/GetAuthorizedCountryData.api'
import { authCountry, mappedDomainColObject, mappedDomainRowObject, unmappedDomainRowObject, serverSidePagination, DomainBulkRowObject } from "../DomainMappingAttributes";
import { convert_UTC_to_localdate } from "../../../utils/DateConversionUtil";

const mappedDomainColumnData: mappedDomainColObject[] = [

  { field: "certified", content: "Certified", sortable: false },
  { field: "Domain", content: "Domain", sortable: true },
  { field: "CompanyName", content: "Company Name", sortable: true },
  { field: "TPID", content: "TPID", sortable: false },
  { field: "CRMId", content: "CRM Id", sortable: false },
  { field: "ModifiedDate", content: "Modified Date", sortable: true },
  { field: "edit", content: "Edit", sortable: false },
  { field: "delete", content: "Delete", sortable: false }
];

const bulkDomainColumnData: mappedDomainColObject[] = [
  { field: "FileName", content: "File Name", sortable: true },
  { field: "UploadedBy", content: "Uploaded By", sortable: true },
  { field: "UploadedOn", content: "Uploaded On", sortable: true },
  { field: "Status", content: "Status", sortable: true },
  { field: "inputfile", content: "Input File", sortable: false },
  { field: "processedfile", content: "Processed File", sortable: false }
];

const unmappedDomainColumnData: mappedDomainColObject[] = [
  { field: "Domain", content: "Domain", sortable: true },
  { field: "HitCount", content: "Hit Count", sortable: true },
  { field: "CompanyName", content: "Suggestions Available", sortable: true },
  { field: "edit", content: "Edit", sortable: false }
];

const initialState = {
  mappedCompanyList: <any>[],
  unmappedDomainRowData: <any>[],
  unmappedDomainColumnData: <any>[],
  mappedDomainRowData: <any>[],
  mappedDomainColumnData: <any>[],
  bulkDomainColumnData: <any>[],
  bulkDomainRowData: <any>[],
  editDomainValue: "",
  totalCount: 0,
  tableData: <any>[],
  mappedCompanyName: "",
  isUnmapped: false,
  idForEditDelete: "",
  certifiedRowCheck: <any>{},
  currentPage: 0,
  activateSearch: false,
  certifiedFilter: '',
  companyStatusFilter: '',
  itemsPerPage: 20,
  searchTerm: "",
  certifyDomain: false,
  editData: false,
  selectedMappedDomain: <any>{},
  error: undefined as string | undefined,
  errorUpload: undefined as string | undefined,
  errorFetch: undefined as string | undefined,
  successMsg: undefined as string | undefined,
  validationStatus: "Not validated",
  filteredItems: [],
  serverSidePagination: { pageSize: 1000, currentOffset: 0, pagedData: null, filteredItems: null, totalRecordsCount: 0, totalPages: 0, minPage: 0, maxPage: 0, currentPage: 1, searchTerm: "", companyStatusFilter: "Active", certifiedStatusFilter: "All", sortType: "ModifiedDate", sortOrder: "DESC", isAscending: false } as serverSidePagination,
  isCertifiedUser: false,
  status: RequestStatus.idle,
  authorizedCountry: [] as authCountry[],
  getAuthorizedCountryDataStatus: RequestStatus.idle,
  getBulkUploadsAllStatus: RequestStatus.idle,
  getDomainMappingCountStatus: RequestStatus.idle,
  getDomainMappingInputFileStatus: RequestStatus.idle,
  getDomainMappingProcessedFileStatus: RequestStatus.idle,
  getExcelFileStatus: RequestStatus.idle,
  getDomainMappingTemplateStatus: RequestStatus.idle,
  getPagedDomainMappingsStatus: RequestStatus.idle,
  getUnmappedDomainsSuggestionsStatus: RequestStatus.idle,
  getUnmappedDomainMappingsStatus: RequestStatus.idle,
  postAllDomainMappingsToExcelStatus: RequestStatus.idle,
  postAllUnmappedDomainsToExcelStatus: RequestStatus.idle,
  postDomainMappingBulkFileStatus: RequestStatus.idle,
  createDomainMappingSingleStatus: RequestStatus.idle,
  deleteDomainMappingSingleStatus: RequestStatus.idle,
  updateDomainMappingSingleStatus: RequestStatus.idle,
  getAccountDetailsStatus: RequestStatus.idle,
  list: <any>[],
  editMapping: false,
  createMapping: false,
  isDataToBeEditedFound: false,
  selectedCountry: { DisplayName: "", Value: "", IsEnabled: true } as authCountry,
  response: "",
  resStatus: "",
  nothingToShow: undefined as string | undefined,
  isOpenMsgBar: false,
  accountName: "",
  msSalesAccountId: "",
  msxAccountNumber: "",
  activeTab: "manual",
  isSuggestionsAvailable: false,
  viewCompanySuggestions: true,
  mappedCompany: "",
  domainMappingManualPageLoaded: false
}
export const RESET_STATE = 'domainMapping/resetState';
export const resetState = () => ({
  type: RESET_STATE,
});



const DomainMappingSlice = createSlice({
  name: 'domainMapping',
  initialState,
  reducers: {

    setServerSidePaginationPageSize: (state, action) => {
      state.serverSidePagination.pageSize = action.payload;
    },
    setServerSidePaginationPagedData: (state, action) => {
      state.serverSidePagination.pagedData = action.payload;
    },
    setServerSidePaginationCurrentOffset: (state, action) => {
      state.serverSidePagination.currentOffset = action.payload;
    },
    setServerSidePaginationFilteredItems: (state, action) => {
      state.serverSidePagination.filteredItems = action.payload;
    },
    setServerSidePaginationTotalRecordsCount: (state, action) => {
      state.serverSidePagination.totalRecordsCount = action.payload;
    },
    setServerSidePaginationTotalPages: (state, action) => {
      state.serverSidePagination.totalPages = action.payload;
    },
    setServerSidePaginationMinPage: (state, action) => {
      state.serverSidePagination.minPage = action.payload;
    },
    setServerSidePaginationMaxPage: (state, action) => {
      state.serverSidePagination.maxPage = action.payload;
    },
    setServerSidePaginationCurrentPage: (state, action) => {
      state.serverSidePagination.currentPage = action.payload;
    },
    setServerSidePaginationSearchTerm: (state, action) => {
      state.serverSidePagination.searchTerm = action.payload;
    },
    setServerSidePaginationSortType: (state, action) => {
      state.serverSidePagination.sortType = action.payload;
    },
    setServerSidePaginationSortOrder: (state, action) => {
      state.serverSidePagination.sortOrder = action.payload;
    },
    setServerSidePaginationIsAscending: (state, action) => {
      state.serverSidePagination.isAscending = action.payload;
    },
    setIdForEditDelete: (state, action) => {
      state.idForEditDelete = action.payload;
    },
    setIsUnmapped: (state, action) => {
      state.isUnmapped = action.payload;
    },
    setEditDomainValue: (state, action) => {
      state.editDomainValue = action.payload;
    },
    setFilteredItems: (state, action) => {
      state.filteredItems = action.payload;
    },
    setTableData: (state, action) => {
      state.tableData = action.payload;
    },
    setMappedCompanyName: (state, action) => {
      state.mappedCompanyName = action.payload;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setSelectedCountry: (state, action) => {
      state.selectedCountry = action.payload;
    },
    setEditData: (state, action) => {
      state.editData = action.payload;
    },
    setMappedCompanyList: (state, action) => {
      state.mappedCompanyList.push(action.payload);
    },
    clearCompanyList: (state) => {
      state.mappedCompanyList = [];
    },

    setIsDataToBeEditedFound: (state, action) => {
      state.isDataToBeEditedFound = action.payload;
    },
    setUnmappedDomainRowData: (state, action) => {
      state.unmappedDomainRowData = [];
      state.unmappedDomainRowData = action.payload;
    },
    clearUnmappedDomainRowData: (state) => {
      state.unmappedDomainRowData = [];
    },
    setUnmappedDomainColumnData: (state, action) => {
      state.unmappedDomainColumnData = [];
      state.unmappedDomainColumnData = action.payload;
    },
    setMappedDomainRowData: (state, action) => {
      state.mappedDomainRowData = [];
      state.mappedDomainRowData = action.payload;
    },
    clearMappedDomainRowData: (state) => {
      state.mappedDomainRowData = [];
    },
    setMappedDomainColumnData: (state, action) => {
      state.mappedDomainColumnData = [];
      state.mappedDomainColumnData = action.payload;
    },

    setAuthorizedCountry: (state, action) => {
      state.authorizedCountry = action.payload;
    },
    clearNothingToShow: (state) => {
      state.nothingToShow = undefined;
    },
    setIsOpenMsgBar: (state, action) => {
      state.isOpenMsgBar = action.payload;
    },
    setResponse: (state, action) => {
      state.response = action.payload;
    },
    setResStatus: (state, action) => {
      state.resStatus = action.payload;
    },
    setIsCertifiedUser: (state, action) => {
      state.isCertifiedUser = action.payload;
    },
    setCertifyDomain: (state, action) => {
      state.certifyDomain = action.payload;
    },
    setAccountName: (state, action) => {
      state.accountName = action.payload;
    },
    setMsSalesAccountId: (state, action) => {
      state.msSalesAccountId = action.payload;
    },
    setMsxAccountNumber: (state, action) => {
      state.msxAccountNumber = action.payload;
    },
    setCompanyStatusFilter: (state, action) => {
      state.serverSidePagination.companyStatusFilter = action.payload;
    },
    setCertifiedFilter: (state, action) => {
      state.serverSidePagination.certifiedStatusFilter = action.payload;
    },
    setValidationStatus: (state, action) => {
      state.validationStatus = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    clearError: (state) => {
      state.error = undefined
    },
    setActiveTab: (state, action) => {
      state.activeTab = action.payload;
    },
    setIsSuggestionsAvailable: (state, action) => {
      state.isSuggestionsAvailable = action.payload;
    },
    setViewCompanySuggestions: (state, action) => {
      state.viewCompanySuggestions = action.payload;
    },
    setMappedCompany: (state, action) => {
      state.mappedCompany = action.payload;
    },
    setErrorUploadMessage: (state, action) => {
      state.errorUpload = action.payload;
    },
    clearErrorUploadMessage: (state) => {
      state.errorUpload = undefined
    },
    setErrorFetchMessage: (state, action) => {
      state.errorFetch = action.payload;
    },
    clearErrorFetchMessage: (state) => {
      state.errorFetch = undefined
    },
    setSuccessMessage: (state, action) => {
      state.successMsg = action.payload;
    },
    clearSuccessMessage: (state) => {
      state.successMsg = undefined
    },
    setCertifiedRowCheck: (state, action) => {
      state.certifiedRowCheck = action.payload;
    },
    setDomainMappingManualPageLoaded: (state, action) => {
      state.domainMappingManualPageLoaded = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(RESET_STATE, (state, action) => {
        return initialState;
      })
      .addCase(getAuthorizedCountryData.pending, (state, _) => {
        state.getAuthorizedCountryDataStatus = RequestStatus.loading;
      })
      .addCase(getAuthorizedCountryData.fulfilled, (state, action) => {
        state.getAuthorizedCountryDataStatus = RequestStatus.succeeded;
        let authorizedCountry: authCountry[] = [];
        if (action.payload.Error === "Error Response") {
          state.errorFetch = action.payload.Response;
        }
        else {
          state.errorFetch = undefined;
        }
      })
      .addCase(getAuthorizedCountryData.rejected, (state, action) => {
        state.getAuthorizedCountryDataStatus = RequestStatus.failed;
        state.errorFetch = action.error.message;
      })
      .addCase(getBulkUploadsAll.pending, (state, _) => {
        state.getBulkUploadsAllStatus = RequestStatus.loading;
        state.nothingToShow = undefined;
        state.bulkDomainRowData = [];
      })
      .addCase(getBulkUploadsAll.fulfilled, (state, action) => {
        state.getBulkUploadsAllStatus = RequestStatus.succeeded;
        if (action.payload.Error === "Error Response") {
          state.errorFetch = action.payload.Response;
        }
        else {
          state.bulkDomainColumnData = bulkDomainColumnData;
          state.tableData = action.payload.Response;
          var mappedRows: DomainBulkRowObject[] = [];
          for (let i = 0; i < state.tableData.length; i++) {
            let mappedRow: DomainBulkRowObject = {
              id: i + 1,
              FileName: state.tableData[i].FileName,
              UploadedBy: state.tableData[i].UploadedBy,
              UploadedOn: convert_UTC_to_localdate(state.tableData[i].UploadedOn),
              Status: state.tableData[i].Status,
              inputfile: state.tableData[i].InputFile,
              processedfile: state.tableData[i].ProcessedFile,
              RowKey: state.tableData[i].RowKey

            }
            mappedRows.push(mappedRow);
          }
          if (mappedRows.length === 0) {
            state.nothingToShow = "No bulk upload requests to show.";
          }
          state.bulkDomainRowData = mappedRows;
          state.errorFetch = undefined
        }
      })
      .addCase(getBulkUploadsAll.rejected, (state, action) => {
        state.getBulkUploadsAllStatus = RequestStatus.failed;
        state.errorFetch = action.error.message;
      })
      .addCase(getDomainMappingCount.pending, (state, _) => {
        state.getDomainMappingCountStatus = RequestStatus.loading;
      })
      .addCase(getDomainMappingCount.fulfilled, (state, action) => {
        state.getDomainMappingCountStatus = RequestStatus.succeeded
        if (action.payload.Error === "Error Response") {
          state.errorFetch = action.payload.Status;
        }
        else {
          state.totalCount = action.payload.Response;
          if (!state.activateSearch) {
            state.serverSidePagination.totalRecordsCount = state.totalCount;
          }
          state.errorFetch = undefined
        }
      })
      .addCase(getDomainMappingCount.rejected, (state, action) => {
        state.getDomainMappingCountStatus = RequestStatus.failed;
        state.errorFetch = action.error.message;
      })
      .addCase(getDomainMappingInputFile.pending, (state, _) => {
        state.getDomainMappingInputFileStatus = RequestStatus.loading;
      })
      .addCase(getDomainMappingInputFile.fulfilled, (state, action) => {
        state.getDomainMappingInputFileStatus = RequestStatus.succeeded
        if (action.payload.Error === "Error Response") {
          state.errorFetch = action.payload.Response;
        }
        else {
          state.errorFetch = undefined
        }
      })
      .addCase(getDomainMappingInputFile.rejected, (state, action) => {
        state.getDomainMappingInputFileStatus = RequestStatus.failed;
        state.errorFetch = action.error.message;
      })
      .addCase(getDomainMappingProcessedFile.pending, (state, _) => {
        state.getDomainMappingProcessedFileStatus = RequestStatus.loading;
      })
      .addCase(getDomainMappingProcessedFile.fulfilled, (state, action) => {
        state.getDomainMappingProcessedFileStatus = RequestStatus.succeeded
        if (action.payload.Error === "Error Response") {
          state.errorFetch = action.payload.Response;
        }
        else {
          state.errorFetch = undefined
        }
      })
      .addCase(getDomainMappingProcessedFile.rejected, (state, action) => {
        state.getDomainMappingProcessedFileStatus = RequestStatus.failed;
        state.errorFetch = action.error.message;
      })
      .addCase(getExcelFile.pending, (state, _) => {
        state.getExcelFileStatus = RequestStatus.loading;
      })
      .addCase(getExcelFile.fulfilled, (state, action) => {
        state.getExcelFileStatus = RequestStatus.succeeded
        if (action.payload.Error === "Error Response") {
          state.errorFetch = action.payload.Status;
        }
        else {
          state.errorFetch = undefined
        }
      })
      .addCase(getExcelFile.rejected, (state, action) => {
        state.getExcelFileStatus = RequestStatus.failed;
        state.errorFetch = action.error.message;
      })
      .addCase(getDomainMappingTemplate.pending, (state, _) => {
        state.getDomainMappingTemplateStatus = RequestStatus.loading;
      })
      .addCase(getDomainMappingTemplate.fulfilled, (state, action) => {
        state.getDomainMappingTemplateStatus = RequestStatus.succeeded
        state.errorFetch = undefined
      })
      .addCase(getDomainMappingTemplate.rejected, (state, action) => {
        state.getDomainMappingTemplateStatus = RequestStatus.failed;
        state.errorFetch = action.error.message;
      })

      .addCase(getPagedDomainMappings.pending, (state, _) => {
        state.getPagedDomainMappingsStatus = RequestStatus.loading;
        state.mappedDomainRowData = [];
      })
      .addCase(getPagedDomainMappings.fulfilled, (state, action) => {

        if (action.payload.Error === "Error Response") {
          state.errorFetch = action.payload.Response;
        }
        else {
          state.tableData = [];
          state.mappedDomainColumnData = mappedDomainColumnData;
          state.tableData = action.payload.Response;
          var mappedRows: mappedDomainRowObject[] = [];
          for (let i = 0; i < state.tableData.length; i++) {
            let mappedRow: mappedDomainRowObject = {
              id: i + 1,
              CRMId: state.tableData[i].CRMId,
              CompanyName: state.tableData[i].CompanyName,
              Status: state.tableData[i].CompanyStatus,
              Country: state.tableData[i].Country,
              Domain: state.tableData[i].Domain,
              HitCount: state.tableData[i].HitCount,
              InsertedDate: convert_UTC_to_localdate(state.tableData[i].InsertedDate),
              MappingId: state.tableData[i].MappingId,
              ModifiedBy: state.tableData[i].ModifiedBy,
              ModifiedDate: convert_UTC_to_localdate(state.tableData[i].ModifiedDate),
              SourceSystemName: state.tableData[i].SourceSystemName,
              TPID: state.tableData[i].MsSalesId,
              Certified: state.tableData[i].IsCertified === 0 ? false : true,
            }
            mappedRows.push(mappedRow);
          }
          state.mappedDomainRowData = mappedRows;
          state.getPagedDomainMappingsStatus = RequestStatus.succeeded
          state.errorFetch = undefined;
          state.domainMappingManualPageLoaded = true;
        }
      })
      .addCase(getPagedDomainMappings.rejected, (state, action) => {
        state.getPagedDomainMappingsStatus = RequestStatus.failed;
        state.errorFetch = action.error.message;
      })
      .addCase(getUnmappedDomainsSuggestions.pending, (state, _) => {
        state.getUnmappedDomainsSuggestionsStatus = RequestStatus.loading;
      })
      .addCase(getUnmappedDomainsSuggestions.fulfilled, (state, action) => {
        state.getUnmappedDomainsSuggestionsStatus = RequestStatus.succeeded;
        if (action.payload.Error === "Error Response") {
          state.errorFetch = action.payload.Error;
        }
        else {
          state.mappedCompanyList = action.payload.Response;
          state.errorFetch = undefined
        }
      })
      .addCase(getUnmappedDomainsSuggestions.rejected, (state, action) => {
        state.getUnmappedDomainsSuggestionsStatus = RequestStatus.failed;
        state.errorFetch = action.error.message;
      })
      .addCase(getUnmappedDomainMappings.pending, (state, _) => {
        state.getUnmappedDomainMappingsStatus = RequestStatus.loading;
        state.unmappedDomainRowData = [];
      })
      .addCase(getUnmappedDomainMappings.fulfilled, (state, action) => {
        state.getUnmappedDomainMappingsStatus = RequestStatus.succeeded
        if (action.payload?.Error === "Error Response") {
          state.errorFetch = action.payload.Status;
        }
        else {
          state.tableData = action.payload.Response;
          state.totalCount = state.tableData.length;
          if (state.totalCount === 0) {
            state.nothingToShow = "No unmapped domains to show.";
          }
          let unmappedRows: unmappedDomainRowObject[] = [];
          for (let i = 0; i < state.tableData.length; i++) {
            let unmappedRow: unmappedDomainRowObject = {
              id: i + 1,
              CRMId: state.tableData[i].CRMId,
              CompanyName: state.tableData[i].CompanyName,
              CompanyStatus: state.tableData[i].CompanyStatus,
              Country: state.tableData[i].Country,
              Domain: state.tableData[i].Domain,
              HitCount: state.tableData[i].HitCount,
              InsertedDate: convert_UTC_to_localdate(state.tableData[i].InsertedDate),
              MappingId: state.tableData[i].MappingId,
              ModifiedBy: state.tableData[i].ModifiedBy,
              ModifiedDate: convert_UTC_to_localdate(state.tableData[i].ModifiedDate),
              SourceSystemName: state.tableData[i].SourceSystemName,
              TPID: state.tableData[i].TPID,
            }
            unmappedRows.push(unmappedRow);
          }

          state.unmappedDomainRowData = unmappedRows;
          state.unmappedDomainColumnData = unmappedDomainColumnData;
          state.errorFetch = undefined
        }
      })
      .addCase(getUnmappedDomainMappings.rejected, (state, action) => {
        state.getUnmappedDomainMappingsStatus = RequestStatus.failed;
        state.errorFetch = action.error.message;
      })
      .addCase(postAllDomainMappingsToExcel.pending, (state, _) => {
        state.postAllDomainMappingsToExcelStatus = RequestStatus.loading;
      })
      .addCase(postAllDomainMappingsToExcel.fulfilled, (state, action) => {
        state.postAllDomainMappingsToExcelStatus = RequestStatus.succeeded
        if (action.payload.errorMessage?.length > 0) {
          state.errorFetch = action.payload.errorMessage;
        }
        else {
          state.errorFetch = undefined;
        }
      })
      .addCase(postAllDomainMappingsToExcel.rejected, (state, action) => {
        state.postAllDomainMappingsToExcelStatus = RequestStatus.failed;
        state.errorFetch = action.error.message;
      })
      .addCase(postAllUnmappedDomainsToExcel.pending, (state, _) => {
        state.postAllUnmappedDomainsToExcelStatus = RequestStatus.loading;
      })
      .addCase(postAllUnmappedDomainsToExcel.fulfilled, (state, action) => {
        state.postAllUnmappedDomainsToExcelStatus = RequestStatus.succeeded;
        if (action.payload.errorMessage?.length > 0) {
          state.errorFetch = action.payload.errorMessage;
        }
        else {
          state.errorFetch = undefined
        }
      })
      .addCase(postAllUnmappedDomainsToExcel.rejected, (state, action) => {
        state.postAllUnmappedDomainsToExcelStatus = RequestStatus.failed;
        state.errorFetch = action.error.message;
      })
      .addCase(postDomainMappingBulkFile.pending, (state, _) => {
        state.postDomainMappingBulkFileStatus = RequestStatus.loading;
      })
      .addCase(postDomainMappingBulkFile.fulfilled, (state, action) => {
        state.postDomainMappingBulkFileStatus = RequestStatus.succeeded
        if (action.payload.errorMessage?.length > 0) {
          state.errorUpload = action.payload.errorMessage;
          state.successMsg = undefined;
        }
        else {
          state.errorUpload = undefined;
          state.successMsg = "File uploaded successfully.";
        }
      })
      .addCase(postDomainMappingBulkFile.rejected, (state, action) => {
        state.postDomainMappingBulkFileStatus = RequestStatus.failed;
        state.errorUpload = action.error.message;
        state.successMsg = undefined;
      })
      .addCase(createDomainMappingSingle.pending, (state, _) => {
        state.createDomainMappingSingleStatus = RequestStatus.loading;
      })
      .addCase(createDomainMappingSingle.fulfilled, (state, action) => {
        state.createDomainMappingSingleStatus = RequestStatus.succeeded;
        if (action.payload.Error === "Error Response") {
          state.errorUpload = action.payload.Response + " : " + action.payload.Status;
          state.successMsg = undefined;
        }
        else {
          state.errorUpload = undefined;
          if (action.payload.Response === "success") {
            state.successMsg = "Created domain mapping successfully.";
          }
        }

      })
      .addCase(createDomainMappingSingle.rejected, (state, action) => {
        state.createDomainMappingSingleStatus = RequestStatus.failed;
        state.errorUpload = action.error.message;
        state.successMsg = undefined;
      })
      .addCase(deleteDomainMappingSingle.pending, (state, _) => {
        state.deleteDomainMappingSingleStatus = RequestStatus.loading;
      })
      .addCase(deleteDomainMappingSingle.fulfilled, (state, action) => {
        state.deleteDomainMappingSingleStatus = RequestStatus.succeeded
        if (action.payload.Error === "Error Response") {
          state.errorUpload = action.payload.Response + " : " + action.payload.Status;
          state.successMsg = undefined;
        }
        else {
          state.errorUpload = undefined;
          if (action.payload.Response === "success") {
            state.successMsg = "Deleted domain mapping successfully.";
          }
        }

      })
      .addCase(deleteDomainMappingSingle.rejected, (state, action) => {
        state.deleteDomainMappingSingleStatus = RequestStatus.failed;
        state.errorUpload = action.error.message;
        state.successMsg = undefined;
      })
      .addCase(updateDomainMappingSingle.pending, (state, _) => {
        state.updateDomainMappingSingleStatus = RequestStatus.loading;
      })
      .addCase(updateDomainMappingSingle.fulfilled, (state, action) => {
        state.updateDomainMappingSingleStatus = RequestStatus.succeeded
        if (action.payload.Error === "Error Response") {
          state.errorUpload = action.payload.Response + " : " + action.payload.Status;
          state.successMsg = undefined;
        }
        else {
          state.errorUpload = undefined;
          if (action.payload.Response === "success") {
            state.successMsg = "Updated domain mapping successfully.";
          }
        }
      })
      .addCase(updateDomainMappingSingle.rejected, (state, action) => {
        state.updateDomainMappingSingleStatus = RequestStatus.failed;
        state.errorUpload = action.error.message;
        state.successMsg = undefined;
      })
      .addCase(getAccountDetails.pending, (state, _) => {
        state.getAccountDetailsStatus = RequestStatus.loading;
      })
      .addCase(getAccountDetails.fulfilled, (state, action) => {
        state.getAccountDetailsStatus = RequestStatus.succeeded;
        if (action.payload.Response === 'No data found for the input account id') {
          state.error = 'No account details found for the input account id.';
          state.msSalesAccountId = '';
          state.msxAccountNumber = '';
          state.accountName = '';
        } else {
          state.msSalesAccountId = action.payload.Response.MsSalesAccountId;
          state.msxAccountNumber = action.payload.Response.MsxAccountNumber;
          state.accountName = action.payload.Response.AccountName;
          state.error = undefined
        }

      })
      .addCase(getAccountDetails.rejected, (state, action) => {
        state.getAccountDetailsStatus = RequestStatus.failed;
        state.errorFetch = action.error.message;
      })

  }
})

export const { 
  setEditData,
  setIsUnmapped,
  setMappedDomainRowData,
  setMappedDomainColumnData,
  setUnmappedDomainRowData,
  setUnmappedDomainColumnData,
  clearMappedDomainRowData,
  clearUnmappedDomainRowData,
  clearCompanyList,
  setMappedCompanyList,
  setViewCompanySuggestions,
  setMappedCompanyName,
  setIsDataToBeEditedFound,
  clearNothingToShow,
  setIsOpenMsgBar,
  setSelectedCountry,
  setResponse,
  setResStatus,
  setTableData,
  setFilteredItems,
  setCertifyDomain,
  setEditDomainValue,
  setIsSuggestionsAvailable,
  setIsCertifiedUser,
  setCurrentPage,
  setIdForEditDelete,
  setServerSidePaginationPageSize,
  setServerSidePaginationPagedData,
  setServerSidePaginationCurrentOffset,
  setServerSidePaginationFilteredItems,
  setServerSidePaginationTotalRecordsCount,
  setServerSidePaginationTotalPages,
  setServerSidePaginationMinPage,
  setServerSidePaginationMaxPage,
  setServerSidePaginationCurrentPage,
  setServerSidePaginationSearchTerm,
  setServerSidePaginationSortType,
  setServerSidePaginationSortOrder,
  setServerSidePaginationIsAscending,
  setAccountName,
  setMsSalesAccountId,
  setMsxAccountNumber,
  setCertifiedFilter,
  setCompanyStatusFilter,
  setValidationStatus,
  setError,
  clearError,
  setActiveTab,
  setMappedCompany,
  setErrorFetchMessage,
  setErrorUploadMessage,
  clearErrorFetchMessage,
  clearErrorUploadMessage,
  clearSuccessMessage,
  setCertifiedRowCheck,
  setDomainMappingManualPageLoaded
} = DomainMappingSlice.actions;
export const DomainMappingReducer = DomainMappingSlice.reducer

