
export const DownloadExcelData = async (response: any) => {
    try {
        const base64String = response.data.bytedata;
        const byteArray = Uint8Array.from(atob(base64String), c => c.charCodeAt(0));

        // Convert the byte array to an ArrayBuffer
        const arrayBuffer = byteArray.buffer;
        var file = new Blob([arrayBuffer], { type: response.data.format });
        var downloadURL = URL.createObjectURL(file);

        var link = document.createElement('a');
        link.href = downloadURL;
        link.download = response.data.name;
        link.click();
    } catch (err) {
        return err;
    }
}
