import React, { useState, useEffect, useReducer, useCallback, useMemo } from 'react';
import { RootState } from '../../root-redux/RootState';
import { useSelector } from 'react-redux';
import { Buffer } from 'buffer';
import {
  DataGridView,
  CommandBar,
  Button,
  SearchBox, 
  Pagination,
  Icon
} from '../../common/HarmonyEnablers';
import '../grid/grid.css';
type GridProps = {
  columnData: any[];
  rowData: any[];
  handleEditButtonClick?: (row: any) => void;
  handleDeleteButtonClick?: (row: any) => void;
  handleDownload?: (row: any, type: string, fileName: string) => void;
  page: string;
  isExcelUpload?: boolean;
  uploadToExcel?: () => any;
  onRefresh?: () => void;
  sortType?: string;
  isAscending?: boolean
}

export const Grid: React.FC<GridProps> = ({ columnData, rowData, handleDeleteButtonClick, handleEditButtonClick, handleDownload, page, isExcelUpload, uploadToExcel, onRefresh, sortType, isAscending }) => {
  const { isNavPanelOpen } = useSelector((state: RootState) => state.nav);
  const [sortBy, setSortBy] = useState(sortType);
  const [sortDirection, setSortDirection] = useState(isAscending);
  const columns = useMemo(() => {
    return columnData.map((x: any) => {
      return {
        field: x.field,
        content: x.content,
        sortable: x.sortable,
        display: x.display,
      };
    });
  }, [columnData]);

  const rows: { id: any; cells: Record<string, any> }[] = useMemo(() => {
    let value: string;
    return rowData.map((x: any) => {
      const row = {
        id: x.id ? x.id : x.RowKey,
        cells: columns.reduce((cell: Record<string, any>, column: any) => {
          if (column.field === "download" || column.field === "download1" || column.field === "inputfile" || column.field === "processedfile" || column.field === "InputFile" || column.field === "ProcessedFile") {
            switch (page) {
              case "leadEnrichment":
                cell[column.field] = `RowKey=${x.rowKey}&PartitionKey=${x.partitionKey}`;
                break;
              case "domainSuppression":
                value = x.RowKey ? Buffer.from(x.RowKey).toString('base64') : '';
                if (column.field === "download")
                  cell[column.field] = value;
                else
                  cell[column.field] = value;
                break;
              case "domainMappingBulk":
                value = x.RowKey ? Buffer.from(x.RowKey).toString('base64') : '';
                if (column.field === "inputfile")
                  cell[column.field] = value;
                else
                  cell[column.field] = value;
                break;
              case "CompanyMapping":
                value = x.RowKey ? Buffer.from(x.RowKey).toString('base64') : '';
                if (column.field === "inputfile")
                  cell[column.field] = value;
                else
                  cell[column.field] = value;
                break;
              default:
                break;
            }
          }
          else {
            cell[column.field] = x[column.field];
          }
          return cell;
        }, {}),
        data: x
      };
      return row;
    });
  }, [rowData, columns, page]);

  const [filter, setFilter] = useState('');
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageCount, setPageCount] = useState<number>(1);
  const [pagedRows, setPagedRows] = useState<any>([]);
  const [pageSize, setPageSize] = useState(20);
  let [filteredRows, dispatch] = useReducer(
    () =>
      rows.filter((row) => {
        const matchesFilter = Object.values(row.cells).some((cell: any) => {
          if (typeof cell === "string") {
            return cell.toLowerCase().includes(filter.toLowerCase());
          }
          return false;
        });
        return matchesFilter;
      }),
    rows
  );

  const pageArray = () => {
    const pagedRows = filteredRows.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
    const pageCount = Math.ceil(filteredRows.length / pageSize);
    setPagedRows(pagedRows);
    setPageCount(pageCount);
  };

  function handleSort(e: CustomEvent<{ sortBy: string, isAscending: boolean }>) {
    setPageNumber(1);
    setSortBy(e.detail.sortBy);
    setSortDirection(e.detail.isAscending);
    rows.sort((a, b) => {
      const fieldA = a.cells[e.detail.sortBy];
      const fieldB = b.cells[e.detail.sortBy];
      const isAscending = e.detail.isAscending;
  
      if (e.detail.sortBy.toLowerCase() === 'updateddate' || e.detail.sortBy.toLowerCase() === 'dateupdated' || e.detail.sortBy.toLowerCase() === 'uploadedon' || e.detail.sortBy.toLowerCase() === 'modifieddate') {
        const dateA = Date.parse(fieldA);
        const dateB = Date.parse(fieldB);
        if (dateA > dateB) {
          return isAscending ? 1 : -1;
        }
        if (dateA < dateB) {
          return isAscending ? -1 : 1;
        }
        return 0;
      } else {
        if (typeof fieldA === 'string' && typeof fieldB === 'string') {
          if (fieldA.toLowerCase() > fieldB.toLowerCase()) {
            return isAscending ? 1 : -1;
          }
          if (fieldA.toLowerCase() < fieldB.toLowerCase()) {
            return isAscending ? -1 : 1;
          }
          return 0;
        } else {
          const numA = Number(fieldA);
          const numB = Number(fieldB);
          if (numA > numB) {
            return isAscending ? 1 : -1;
          }
          if (numA < numB) {
            return isAscending ? -1 : 1;
          }
          return 0;
        }
      }
    });
    dispatch();
  }



  const handleClick = async () => {
    uploadToExcel && await uploadToExcel();
  };

  useEffect(pageArray, [filter, pageNumber, pageSize, filteredRows]);
  return (

    <DataGridView rows={pagedRows} columns={columns} onHeSort={handleSort} sortBy={sortBy} sortAscending={sortDirection}>
      {pagedRows.map((row:any) => (
        <>
          <span slot={`edit-${row.id}`} key={`edit-${row.id}`} >
            <Button appearance="secondary" onClick={() => { handleEditButtonClick && handleEditButtonClick(row) }} ><Icon name="edit" aria-label='edit-button' role='button' /></Button>
            <br />
          </span>
          <span slot={`delete-${row.id}`} key={`delete-${row.id}`}>
            <Button appearance="secondary" onClick={() => { handleDeleteButtonClick && handleDeleteButtonClick(row) }}><Icon name="delete" aria-label='delete-button' role='button' /></Button>
            <br />
          </span>
          <span slot={`download-${row.id}`} key={`download-${row.id}`}>
            <a role="button" href="#" onClick={() => handleDownload && handleDownload(row.id, "inputfile", row.cells.download)} aria-label={(row.cells.Status === "Completed" || row.cells.status === "Completed") ? "Download file" : "File not ready for download"}>{(row.cells.Status === "Completed" || row.cells.status === "Completed") ? "Download" : ""} </a>
          </span>
          <span slot={`download1-${row.id}`} key={`download1-${row.id}`}>
            <a role="button" href="#" onClick={() => handleDownload && handleDownload(row.id, "processedfile", row.cells.download1)} aria-label={(row.cells.Status === "Completed" || row.cells.status === "Completed") ? "Download file" : "File not ready for download"}>{(row.cells.Status === "Completed" || row.cells.status === "Completed") ? "Download" : ""} </a>
          </span>
          <span slot={`inputfile-${row.id}`} key={`inputfile-${row.id}`}>
            <a role="button" href="#" onClick={() => handleDownload && handleDownload(row.id, "inputfile", row.cells.inputfile)} aria-label={(row.cells.Status === "Completed" || row.cells.status === "Completed") ? "Download file" : "File not ready for download"}>{(row.cells.Status === "Complete" || row.cells.Status === "Completed") ? "Download" : ""} </a>
          </span>
          <span slot={`processedfile-${row.id}`} key={`processedfile-${row.id}`}>
            <a role="button" href="#" onClick={() => handleDownload && handleDownload(row.id, "processedfile", row.cells.processedfile)} aria-label={(row.cells.Status === "Completed" || row.cells.status === "Completed") ? "Download file" : "File not ready for download"}>{(row.cells.Status === "Complete" || row.cells.Status === "Completed") ? "Download" : ""} </a>
          </span>
        </>
      ))}

      <div slot="data-grid-controls" className={isNavPanelOpen?'datagrid-bar-collapsed':'datagrid-bar'} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
        <div style={{ display: 'flex', alignItems: 'center', flex: 1 }}>

          <SearchBox
            style={{ maxWidth: '500px' }}
            value={filter}
            onInput={(e) => {
              setPageNumber(1);
              setFilter(e.currentTarget.value !== undefined ? e.currentTarget.value.toString() : '');
              dispatch();
            }}
            placeholder="Search"
            onHeClear={() => { setFilter(""); dispatch() }}
          ></SearchBox>
        </div>
        {page === "leadEnrichment" && <div><CommandBar style={{ marginLeft: 'auto', flex: 0 }} >
          <Button appearance='primary' onClick={() => { onRefresh && onRefresh() }} >
            Refresh
          </Button>
        </CommandBar></div>}
        {
          isExcelUpload && <a style={{ color: '#006cc2' }} href="#" id='ExportToExcel' onClick={handleClick}><Icon name='excellogo' />Export To Excel</a>
        }

      </div>
      <Pagination
        slot="pagination"
        value={pageNumber}
        page-count={pageCount}
        onHePageChange={(e) => {
          setPageNumber(e.detail);
        }}
        overflowPosition='top'
        overflow-dropdown={true}
      ></Pagination>

      {pagedRows.length > 0 ? <span slot="no-records">Please Wait, Loading...</span> : <span slot="no-records">No search results found.</span>}
    </DataGridView>
  );
};
