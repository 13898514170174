import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import config from '../../config/config-dev';
import { ClientConfigurationError } from '@azure/msal-browser';


export const postRequest = async<T, D>(apiPath: string, payload: D, correlationId: any) => {
  const token = window.localStorage.getItem('api_token');
  try {
    const response = await axios.post<T>(`${process.env.REACT_APP_CDES_BASE_URL}/${apiPath}`, payload, {
      headers: {
        'Authorization': `Bearer ${token}`,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "x-ms-correlation-id": correlationId,

      },
    }

    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const postRequestForExcel = async<T, D>(apiPath: string, payload: D, correlationId: any) => {
  const token = window.localStorage.getItem('api_token');
  try {
    const response = await axios.post<T>(`${process.env.REACT_APP_CDES_BASE_URL}/${apiPath}`, payload, {
      headers: {
        'Authorization': `Bearer ${token}`,
        "Access-Control-Allow-Origin": "*",
        "x-ms-correlation-id": correlationId,
      },
    }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const patchRequest = async<T, D>(apiPath: string, payload: D) => {
  const token = window.localStorage.getItem('api_token');
  try {
    const response = await axios.patch<T>(`${process.env.REACT_APP_CDES_BASE_URL}/${apiPath}`, payload, {
      headers: {
        'Authorization': `Bearer ${token}`,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "x-ms-correlation-id": uuidv4()
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};