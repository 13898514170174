import { createAsyncThunk } from "@reduxjs/toolkit"
import { postRequestForExcel } from "../utils/PostRequest"
import { appInsights } from "../../config/appInsights";
import { v4 as uuidv4 } from 'uuid';

export const uploadFileForSuppression = createAsyncThunk<any, any>('IspDomainMapping/SubmitIspDomainMappingBulkFile', async (payload) => {
    var correlationId = uuidv4();
    appInsights.trackEvent({ name: "Sending request to IspDomainMapping/SubmitIspDomainMappingBulkFile data", properties: { correlationId: correlationId, page: "Domain Suppression" } });
    const response = await postRequestForExcel<any, any>(`IspDomainMapping/SubmitIspDomainMappingBulkFile?country=${payload.country}`, payload.fileData, correlationId)
    response.data === null ? appInsights.trackEvent({ name: "Data not fetched from IspDomainMapping/SubmitIspDomainMappingBulkFil api", properties: { correlationId: correlationId } }) : appInsights.trackEvent({ name: "Data fetched from IspDomainMapping/SubmitIspDomainMappingBulkFil api ", properties: { data: JSON.stringify(response.data), correlationId: correlationId } });
    return response.data
});