import { createSlice } from '@reduxjs/toolkit';
import { RequestStatus } from '../../model/ServiceRequestStatus.model';
import { authLanguage } from '../../feature-components/job-title-mapping/JobTitleMappingAttributes';
import { authCountry } from '../../feature-components/domain-suppression/DomainSuppressionAttributes';

const initialState = {
    authorizedLanguagesForUser: [] as authLanguage[],
    authorizedCountriesForUser: [] as authCountry[],
    status: RequestStatus.idle,

}

const userLanguageCountryDataSlice = createSlice({
    name: 'userLanguageCountryData',
    initialState,
    reducers: {
        setAuthorizedLanguagesForUser: (state, action) => {
            state.authorizedLanguagesForUser = action.payload;
        },
        setAuthorizedCountriesForUser: (state, action) => {
            state.authorizedCountriesForUser = action.payload;
        }
    }
});

export const userLanguageCountryDataReducer = userLanguageCountryDataSlice.reducer;
export const { setAuthorizedLanguagesForUser, setAuthorizedCountriesForUser } = userLanguageCountryDataSlice.actions;
