import { createSlice } from "@reduxjs/toolkit";

import { RequestStatus } from '../../../model/ServiceRequestStatus.model';
import { getAuthorizedCountryData } from "../../../services/CommonApi/GetAuthorizedCountryData.api";
import { getMappingDataByCountry } from "../../../services/CompanyMapping/GetMappingDataByCountry.api";
import { GetCompanyMappingRequest } from "../../../services/CompanyMapping/GetCompanyMappingRequest.api";
import { uploadCompanyMappingBulkFile } from "../../../services/CompanyMapping/PostUploadFileForCompanyMapping.api";
import { saveData } from "../../../services/CompanyMapping/PostSaveData.api";
import { saveUnmatchedData } from "../../../services/CompanyMapping/PostSaveUnmatchedCompanyData.api";
import { deleteMapping } from "../../../services/CompanyMapping/Delete.api";
import { deleteUnmatchedCompanyMapping } from "../../../services/CompanyMapping/DeleteUnmatchedCompany.api";
import { getCompanies } from "../../../services/CompanyMapping/GetCompanies.api";
import { postExportToExcelMapping } from "../../../services/CompanyMapping/ExportToExcelMapping.api";
import { postExportToExcelUnmatched } from "../../../services/CompanyMapping/ExportToExcelMappingUnmatched.api";
import { getCompanyMappingInputFile } from "../../../services/CompanyMapping/GetCompanyMappingInputFile.api";
import { getCompanyMappingProcessedFile } from "../../../services/CompanyMapping/GetCompanyMappingProcessedFile.api";
import { getExcelFile } from "../../../services/CompanyMapping/GetExcelFile.api";

import { authCountry, companyMappingColObject, companyMappingRowObject, bulkUploadColObject, bulkUploadRowObject } from "../CompanyMappingAttributes";

import { convert_UTC_to_localdate } from "../../../utils/DateConversionUtil";

const bulkUploadColumns: bulkUploadColObject[] = [
    { field: "FileName", content: "File Name", sortable: true },
    { field: "UploadedBy", content: "Uploaded By", sortable: true },
    { field: "UploadedOn", content: "Uploaded On", sortable: true },
    { field: "Status", content: "Status", sortable: true },
    { field: "inputfile", content: "Input File", sortable: false },
    { field: "processedfile", content: "Processed File", sortable: false },
];

const companyMappingColumns: companyMappingColObject[] = [

    { field: "InputCompanyName", content: "Input Company Name", sortable: true, display: { width: "250px", noWrap: false } },
    { field: "MatchCompanyName", content: "Match Company Name", sortable: true, display: { width: "250px", noWrap: false } },
    { field: "CRMAccountId", content: "CRM Account Id", sortable: false, display: {} },
    { field: "DateUpdated", content: "Date Updated", sortable: true, display: {} },
    { field: "edit", content: "Edit", sortable: false, display: {} },
    { field: "delete", content: "Delete", sortable: false, display: {} },
];
const initialState = {
    companyMappingRowData: <any>[],
    companyMappingColumnData: <any>[],
    bulkCompanyMappingRowData: <any>[],
    bulkCompanyMappingColumnData: <any>[],
    authorizedCountry: [] as authCountry[],
    selectedCountry: { DisplayName: "", Value: "", IsEnabled: true } as authCountry,
    error: undefined as string | undefined,
    validationStatus: "Not validated",
    unmatchedOnly: false,
    isOpenMsgBar: false,
    mappedCompanyName: "",
    mappedCompany: "",
    inputCompany: "",
    mappedCompanyList: <any>[],
    crmAccountId: "",
    status: RequestStatus.idle,
    editData: false,
    response: "",
    resStatus: "",
    errorMessage: "",
    errorFetch: undefined as string | undefined,
    errorUpload: undefined as string | undefined,
    successMsg: undefined as string | undefined,
    editDeleteRow: <any>[],
    nothingToShow: undefined as string | undefined,
    isDataToBeEditedFound: false,
    idForEditDelete: "",
    
    getAuthorizedCountryDataStatus: RequestStatus.idle,
    getMappingDataByCountryStatus: RequestStatus.idle,
    getCompanyMappingRequestStatus: RequestStatus.idle,
    getCompaniesStatus: RequestStatus.idle,
    saveDataStatus: RequestStatus.idle,
    saveUnmatchedDataStatus: RequestStatus.idle,
    deleteMappingStatus: RequestStatus.idle,
    deleteUnmatchedCompanyMappingStatus: RequestStatus.idle,
    uploadCompanyMappingBulkFileStatus: RequestStatus.idle,
    exportToExcelMappingStatus: RequestStatus.idle,
    exportToExcelUnmatchedStatus: RequestStatus.idle,
    getCompanyMappingInputFileStatus: RequestStatus.idle,
    getCompanyMappingProcessedFileStatus: RequestStatus.idle,
    getExcelFileStatus: RequestStatus.idle
}

export const RESET_STATE = 'companyMapping/resetState';


export const resetState = () => ({
    type: RESET_STATE,
});


const companyMappingSlice = createSlice({
    name: 'companyMapping',
    initialState,
    reducers: {
        setCompanyMappingRowData: (state, action) => {
            state.companyMappingRowData = [];
            state.companyMappingRowData = action.payload;
        },
        setCompanyMappingColumnData: (state, action) => {
            state.companyMappingColumnData = [];
            state.companyMappingColumnData = action.payload;
        },
        setBulkCompanyMappingRowData: (state, action) => {
            state.bulkCompanyMappingRowData = [];
            state.bulkCompanyMappingRowData = action.payload;
        },
        setBulkCompanyMappingColumnData: (state, action) => {
            state.bulkCompanyMappingColumnData = [];
            state.bulkCompanyMappingColumnData = action.payload;
        },
        setAuthorizedCountry: (state, action) => {
            state.authorizedCountry = action.payload;
        },
        setSelectedCountry: (state, action) => {
            state.selectedCountry = action.payload;
        },
        setUnmatchedOnly: (state, action) => {
            state.unmatchedOnly = action.payload;
        },
        setValidationStatus: (state, action) => {
            state.validationStatus = action.payload;
        },
        setMappedCompanyList: (state, action) => {
            state.mappedCompanyList.push(action.payload);
        },
        setMappedCompanyName: (state, action) => {
            state.mappedCompanyName = action.payload;
        },
        setMappedCompany: (state, action) => {
            state.mappedCompany = action.payload;
        },
        setInputCompany: (state, action) => {
            state.inputCompany = action.payload;
        },
        setCrmAccountId: (state, action) => {
            state.crmAccountId = action.payload;
        },
        setIdForEditDelete: (state, action) => {
            state.idForEditDelete = action.payload;
        },
        clearCompanyList: (state) => {
            state.mappedCompanyList = [];
        },
        setEditData: (state, action) => {
            state.editData = action.payload;
        },
        setIsOpenMsgBar: (state, action) => {
            state.isOpenMsgBar = action.payload;
        },
        setResponse: (state, action) => {
            state.response = action.payload;
        },
        setResStatus: (state, action) => {
            state.resStatus = action.payload;
        },
        clearNothingToShow: (state) => {
            state.nothingToShow = undefined;
        },
        setIsDataToBeEditedFound: (state, action) => {
            state.isDataToBeEditedFound = action.payload;
        },
        setEditDeleteRow: (state, action) => {
            state.editDeleteRow = action.payload;
        },
        setErrorMessage: (state, action) => {
            state.errorMessage = action.payload;
        },
        clearMappedCompany: (state) => {
            state.mappedCompany = "";
        },
        setErrorFetchMessage: (state, action) => {
            state.errorFetch = action.payload;
        },
        setErrorUploadMessage: (state, action) => {
            state.errorUpload = action.payload;
        },
        clearErrorFetchMessage: (state) => {
            state.errorFetch = undefined;
        },
        clearErrorUploadMessage: (state) => {
            state.errorUpload = undefined;
        },
        clearSuccessMsg: (state) => {
            state.successMsg = undefined;
        },
        clearError: (state) => {
            state.error = undefined;
        },
    },
    extraReducers(builder) {
        builder
            .addCase(RESET_STATE, (state, action) => {
                return initialState;
            })
            .addCase(getAuthorizedCountryData.pending, (state, action) => {
                state.getAuthorizedCountryDataStatus = RequestStatus.loading;
                state.errorFetch = undefined;
            })
            .addCase(getAuthorizedCountryData.fulfilled, (state, action) => {
                state.getAuthorizedCountryDataStatus = RequestStatus.succeeded;
                if (action.payload.Error === "Error Response") {
                    state.errorFetch = action.payload.Response;
                }
                else {
                    state.errorFetch = undefined;
                }

            })
            .addCase(getAuthorizedCountryData.rejected, (state, action) => {
                state.getAuthorizedCountryDataStatus = RequestStatus.failed;
                state.errorFetch = action.error.message;
            })
            .addCase(getMappingDataByCountry.pending, (state, action) => {
                state.getMappingDataByCountryStatus = RequestStatus.loading;
                state.companyMappingRowData = [];
                state.errorFetch = undefined;
                state.nothingToShow = undefined;
            })
            .addCase(getMappingDataByCountry.fulfilled, (state, action) => {
                state.getMappingDataByCountryStatus = RequestStatus.succeeded;
                if (action.payload.Error === "Error Response") {
                    state.errorFetch = action.payload.Response;
                }
                else {
                    let companyMappingRowData: companyMappingRowObject[] = [];

                    for (let i = 0; i < action.payload.length; i++) {
                        let newObject: companyMappingRowObject = { id: action.payload[i].Id, CRMAccountId: action.payload[i].CRMAccountId, InputCompanyName: action.payload[i].InputCompanyName, MatchCompanyName: action.payload[i].MatchCompanyName, DateUpdated: convert_UTC_to_localdate(action.payload[i].DateUpdated) };
                        companyMappingRowData.push(newObject);
                    }
                    companyMappingRowData.sort((a, b) => (Date.parse(a.DateUpdated) > Date.parse(b.DateUpdated)) ? -1 : 1);
                    state.companyMappingRowData = companyMappingRowData;
                    state.companyMappingColumnData = companyMappingColumns;
                    if (state.companyMappingRowData.length === 0) {
                        state.nothingToShow = "No company mappings to show.";
                    }
                    else {
                        state.nothingToShow = undefined;
                    }
                    state.errorFetch = undefined;
                }
            })
            .addCase(getMappingDataByCountry.rejected, (state, action) => {
                state.getMappingDataByCountryStatus = RequestStatus.failed;
                state.errorFetch = action.error.message;
            })
            .addCase(GetCompanyMappingRequest.pending, (state, action) => {
                state.getCompanyMappingRequestStatus = RequestStatus.loading;
                state.errorFetch = undefined;
                state.nothingToShow = undefined;
                state.bulkCompanyMappingRowData = [];
            })
            .addCase(GetCompanyMappingRequest.fulfilled, (state, action) => {
                state.getCompanyMappingRequestStatus = RequestStatus.succeeded;
                const data = action.payload.data;
                let bulkUploadRowData: bulkUploadRowObject[] = [];
                for (let i = 0; i < data.length; i++) {

                    let newObject: bulkUploadRowObject = { id: i + 1, RowKey: data[i].RowKey, FileName: data[i].FileName, InputFile: data[i].InputFile, ProcessedFile: data[i].ProcessedFile, UploadedBy: data[i].UploadedBy, UploadedOn: convert_UTC_to_localdate(data[i].UploadedOn), Status: data[i].Status };
                    bulkUploadRowData.push(newObject);
                }
                if (bulkUploadRowData.length === 0) {
                    state.nothingToShow = "No bulk upload requests to show.";
                }
                else {
                    state.nothingToShow = undefined;
                }
                state.bulkCompanyMappingRowData = bulkUploadRowData;
                state.bulkCompanyMappingColumnData = bulkUploadColumns;
                state.errorFetch = undefined;
            })
            .addCase(GetCompanyMappingRequest.rejected, (state, action) => {
                state.getCompanyMappingRequestStatus = RequestStatus.failed;
                state.errorFetch = action.error.message;
            })
            .addCase(saveData.pending, (state, action) => {
                state.saveDataStatus = RequestStatus.loading;
                state.errorUpload = undefined;
            })
            .addCase(saveData.fulfilled, (state, action) => {
                state.saveDataStatus = RequestStatus.succeeded;
                if (action.payload.Error === "Error Response") {
                    state.errorUpload = action.payload.Response;
                    state.successMsg = undefined;
                }
                else {
                    state.successMsg = "Company Mapping saved successfully.";
                    state.errorUpload = undefined;
                }
            })
            .addCase(saveData.rejected, (state, action) => {
                state.saveDataStatus = RequestStatus.failed;
                state.errorUpload = action.error.message;
            })
            .addCase(saveUnmatchedData.pending, (state, action) => {
                state.saveUnmatchedDataStatus = RequestStatus.loading;
                state.errorUpload = undefined;
            })
            .addCase(saveUnmatchedData.fulfilled, (state, action) => {
                state.saveUnmatchedDataStatus = RequestStatus.succeeded;
                if (action.payload.Error === "Error Response") {
                    state.errorUpload = action.payload.Response;
                    state.successMsg = undefined;
                }
                else {
                    state.successMsg = "Company mapping saved successfully.";
                    state.errorUpload = undefined;
                }
            })
            .addCase(saveUnmatchedData.rejected, (state, action) => {
                state.saveUnmatchedDataStatus = RequestStatus.failed;
                state.errorUpload = action.error.message;
            })
            .addCase(deleteMapping.pending, (state, action) => {
                state.deleteMappingStatus = RequestStatus.loading;
                state.errorUpload = undefined;
            })
            .addCase(deleteMapping.fulfilled, (state, action) => {
                state.deleteMappingStatus = RequestStatus.succeeded;
                if (action.payload.Error === "Error Response") {
                    state.errorUpload = action.payload.Response;
                    state.successMsg = undefined;
                }
                else {
                    state.errorUpload = undefined;
                    if (action.payload.Response === "Success") {
                        state.successMsg = "Company Mapping deleted successfully.";
                    }
                }
            })
            .addCase(deleteMapping.rejected, (state, action) => {
                state.deleteMappingStatus = RequestStatus.failed;
                state.errorUpload = action.error.message;
                state.successMsg = undefined;
            })
            .addCase(deleteUnmatchedCompanyMapping.pending, (state, action) => {
                state.deleteUnmatchedCompanyMappingStatus = RequestStatus.loading;
                state.errorUpload = undefined;
            })
            .addCase(deleteUnmatchedCompanyMapping.fulfilled, (state, action) => {
                state.deleteUnmatchedCompanyMappingStatus = RequestStatus.succeeded;
                if (action.payload.Error === "Error Response") {
                    state.errorUpload = action.payload.Response;
                    state.successMsg = undefined;
                }
                else {
                    state.errorUpload = undefined;
                    if (action.payload.Response === "Success") {
                        state.successMsg = "Unmatched company mapping deleted successfully.";
                    }
                }
            })
            .addCase(deleteUnmatchedCompanyMapping.rejected, (state, action) => {
                state.deleteUnmatchedCompanyMappingStatus = RequestStatus.failed;
                state.errorUpload = action.error.message;
                state.successMsg = undefined;
            })
            .addCase(uploadCompanyMappingBulkFile.pending, (state, action) => {
                state.uploadCompanyMappingBulkFileStatus = RequestStatus.loading;
                state.errorUpload = undefined;
            })
            .addCase(uploadCompanyMappingBulkFile.fulfilled, (state, action) => {
                state.uploadCompanyMappingBulkFileStatus = RequestStatus.succeeded;
                if (action.payload.errorMessage?.length > 0) {
                    state.errorUpload = action.payload.errorMessage;
                    state.successMsg = undefined;
                }
                else {
                    state.errorUpload = undefined;
                    state.successMsg = "File submitted successfully.";
                }
            })
            .addCase(uploadCompanyMappingBulkFile.rejected, (state, action) => {
                state.uploadCompanyMappingBulkFileStatus = RequestStatus.failed;
                state.errorUpload = undefined;
                state.successMsg = undefined;
            })
            .addCase(getCompanies.pending, (state, _) => {
                state.getCompaniesStatus = RequestStatus.loading;
                state.errorFetch = undefined;
                state.mappedCompanyList = [];
            })
            .addCase(getCompanies.fulfilled, (state, action) => {
                state.getCompaniesStatus = RequestStatus.succeeded;
                if (action.payload.Error === "Error Response") {
                    state.error = action.payload.Response;
                    state.mappedCompanyList = [];
                }
                else {
                    state.mappedCompanyList = [];
                    for (let i = 0; i < action.payload.length; i++) {
                        let newObject: any = action.payload;
                        let companyData = newObject[i];
                        state.mappedCompanyList.push(companyData);
                    }
                    state.error = undefined
                }

            })
            .addCase(getCompanies.rejected, (state, action) => {
                state.getCompaniesStatus = RequestStatus.failed;
                state.errorFetch = action.error.message;
            })
            .addCase(postExportToExcelMapping.pending, (state, _) => {
                state.exportToExcelMappingStatus = RequestStatus.loading;
                state.errorFetch = undefined;
            })
            .addCase(postExportToExcelMapping.fulfilled, (state, action) => {
                state.exportToExcelMappingStatus = RequestStatus.succeeded;
                if (action.payload.errorMessage?.length > 0) {
                    state.errorFetch = action.payload.errorMessage;
                }
                else {
                    state.errorFetch = undefined;
                }
            })
            .addCase(postExportToExcelMapping.rejected, (state, action) => {
                state.exportToExcelMappingStatus = RequestStatus.failed;
                state.errorFetch = action.error.message;
            })
            .addCase(postExportToExcelUnmatched.pending, (state, _) => {
                state.exportToExcelUnmatchedStatus = RequestStatus.loading;
                state.errorFetch = undefined;
            })
            .addCase(postExportToExcelUnmatched.fulfilled, (state, action) => {
                state.exportToExcelUnmatchedStatus = RequestStatus.succeeded;
                if (action.payload.errorMessage?.length > 0) {
                    state.errorFetch = action.payload.errorMessage;
                }
                else {
                    state.errorFetch = undefined;
                }
            })
            .addCase(postExportToExcelUnmatched.rejected, (state, action) => {
                state.exportToExcelUnmatchedStatus = RequestStatus.failed;
                state.errorFetch = action.error.message;
            })
            .addCase(getCompanyMappingInputFile.pending, (state, _) => {
                state.getCompanyMappingInputFileStatus = RequestStatus.loading;
                state.errorFetch = undefined;
            })
            .addCase(getCompanyMappingInputFile.fulfilled, (state, action) => {
                state.getCompanyMappingInputFileStatus = RequestStatus.succeeded;
                if (action.payload.Error === "Error Response") {
                    state.errorFetch = action.payload.Response;
                }
                else {
                    state.errorFetch = undefined;
                }
            })
            .addCase(getCompanyMappingInputFile.rejected, (state, action) => {
                state.getCompanyMappingInputFileStatus = RequestStatus.failed;
                state.errorFetch = action.error.message;
            })
            .addCase(getCompanyMappingProcessedFile.pending, (state, _) => {
                state.getCompanyMappingProcessedFileStatus = RequestStatus.loading;
                state.errorFetch = undefined;
            })
            .addCase(getCompanyMappingProcessedFile.fulfilled, (state, action) => {
                state.getCompanyMappingProcessedFileStatus = RequestStatus.succeeded;
                if (action.payload.Error === "Error Response") {
                    state.errorFetch = action.payload.Response;
                }
                else {
                    state.errorFetch = undefined;
                }
            })
            .addCase(getCompanyMappingProcessedFile.rejected, (state, action) => {
                state.getCompanyMappingProcessedFileStatus = RequestStatus.failed;
                state.errorFetch = action.error.message;
            })
            .addCase(getExcelFile.pending, (state, _) => {
                state.getExcelFileStatus = RequestStatus.loading;
                state.errorFetch = undefined;
            })
            .addCase(getExcelFile.fulfilled, (state, action) => {
                state.getExcelFileStatus = RequestStatus.succeeded;
                if (action.payload.Error === "Error Response") {
                    state.errorFetch = action.payload.Response;
                }
                else {
                    state.errorFetch = undefined;
                }
            })
            .addCase(getExcelFile.rejected, (state, action) => {
                state.getExcelFileStatus = RequestStatus.failed;
                state.errorFetch = action.error.message;
            })


    }
})

export const {
    setCompanyMappingColumnData,
    setCompanyMappingRowData,
    setBulkCompanyMappingColumnData,
    setBulkCompanyMappingRowData,
    setSelectedCountry,
    setUnmatchedOnly,
    clearCompanyList,
    setMappedCompanyList,
    setMappedCompanyName,
    setEditDeleteRow,
    setValidationStatus,
    setEditData,
    setIsOpenMsgBar,
    setResponse,
    setResStatus,
    setIdForEditDelete,
    setIsDataToBeEditedFound,
    clearNothingToShow,
    setMappedCompany,
    setInputCompany,
    setErrorMessage,
    clearMappedCompany,
    setErrorFetchMessage,
    setErrorUploadMessage,
    clearErrorFetchMessage,
    clearErrorUploadMessage,
    clearSuccessMsg,
    clearError,
    setCrmAccountId
} = companyMappingSlice.actions;
export default companyMappingSlice.reducer;