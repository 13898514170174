import { createSlice } from '@reduxjs/toolkit'
import { RequestStatus } from '../../model/ServiceRequestStatus.model'
import { getUserGroups } from '../../services/GetUserGroups.api'

interface UserGroupState {
  list: string[],
  status: RequestStatus,
  error: string | undefined
}

const initialState: UserGroupState = {
  list: [],
  status: RequestStatus.idle,
  error: ''
}

const userGroupSlice = createSlice({
  name: 'userGroup',
  initialState,
  reducers: {
    addUserGroup: (state) => {
      state.list = []
    }
  },
  extraReducers(builder) {
    builder
      .addCase(getUserGroups.pending, (state, _) => {
        state.status = RequestStatus.loading
      })
      .addCase(getUserGroups.fulfilled, (state, action) => {
        state.status = RequestStatus.succeeded
        const stringArray: string[] = action.payload;
        const anyArray: any = stringArray;
        state.list = anyArray["Value"];
        if (anyArray["Value"] !== undefined) {
          window.localStorage.setItem("user_groups", JSON.stringify(anyArray["Value"]));
        }
        state.error = undefined
      })
      .addCase(getUserGroups.rejected, (state, action) => {
        state.status = RequestStatus.failed
        state.error = action.error.message
      })
  }
})

export const userGroupsReducer = userGroupSlice.reducer