import { createAsyncThunk } from "@reduxjs/toolkit"
import { postRequest, postRequestForExcel } from "../utils/PostRequest"
import { appInsights } from "../../config/appInsights";
import { v4 as uuidv4 } from 'uuid';


export const postExportToExcelMapping = createAsyncThunk<any, any>('CompanyMapping/ExportToExcelMapping', async (payload) => {
    var correlationId = uuidv4();
    appInsights.trackEvent({ name: "Sending request to CompanyMapping/ExportToExcelMapping data", properties: { payload: JSON.stringify(payload), correlationId: correlationId, page: "Company Mapping" } });
    const response = await postRequest(`CompanyMapping/ExportToExcelMapping/${payload.country}`, payload, correlationId)
    response.data === null ? appInsights.trackEvent({ name: "Data not fetched from CompanyMapping/ExportToExcelMapping api", properties: { data: JSON.stringify(response.data), correlationId: correlationId } }) : appInsights.trackEvent({ name: "Data fetched from CompanyMapping/ExportToExcelMapping api", properties: {correlationId: correlationId } });
    return response.data
});