import { BrowserRouter } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux";
import { useAccount, useMsal } from "@azure/msal-react";
import { AppDispatch, RootState } from "../../root-redux/RootState";
import { useEffect, useState } from "react";
import { setUserDetails } from "../../shared-components/user-details/UserDetails.actions.redux";
import { UserDetails } from "../../model/user-details/UserDetails.model";
import { getUserGroups } from "../../services/GetUserGroups.api";
import { AccountInfo } from "@azure/msal-browser";
import { AppBody } from "../app-body/AppBody";
import { AppHeader } from "../app-header/AppHeader";
import { appInsights } from "../../config/appInsights";
import { v4 as uuidv4 } from 'uuid';
import { Loader } from "../../shared-components/loader/Loader";

export const AuthorizedApp: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { accounts, instance } = useMsal();
  const account = useAccount(accounts[0]);
  const scope = process.env.REACT_APP_SCOPE!;
  const [isLoading, setIsLoading] = useState(true);

  const stateProps = useSelector((state: RootState) => ({
    UserGroups: state.userGroups.list,
    userGroupsStatus: state.userGroups.status
  }
  ));

  // capturing the logged in user's details in the redux store
  useEffect(() => {
    const userDetailsData: UserDetails = {
      name: account?.name!,
      username: account?.username!,
      localAccountId: account?.localAccountId!
    }
    dispatch(setUserDetails(userDetailsData));
  }, [account]);

  // dispatching app personas at the start of the application as RBAC needs to be implemented
  useEffect(() => {
    let graphToken: any;
    const fetchData = async () => {
      var correlationId = uuidv4();
      try {
        if (!graphToken) {
          graphToken = await instance.acquireTokenSilent({
            scopes: [scope],
            account: account as AccountInfo,
            forceRefresh: true
          });
          window.localStorage.setItem("api_token", graphToken.accessToken);

        }
        // Get the expiry time of the access token
        const expiryTime = graphToken.expiresOn ? new Date(graphToken.expiresOn).getTime() : 0;
        // Get the current time
        const currentTime = new Date().getTime();
        // Calculate the time difference between the expiry time and the current time
        const timeDifference = expiryTime - currentTime;
        // If the time difference is less than 5 minutes, get a new access token
        if (timeDifference <= 5 * 60 * 1000) {
          graphToken = await instance.acquireTokenSilent({
            scopes: [scope],
            account: account as AccountInfo,
            forceRefresh: true
          });
          window.localStorage.setItem("api_token", graphToken.accessToken);
        }
      } catch (error) {
        appInsights.trackException({ properties: { name: "Graph API exception.", error: JSON.stringify(error), correlationId: correlationId } });
      }
    };
    const getUserGroupsData = async () => {
      if (userEmailId) {
        var response = await dispatch(getUserGroups(userEmailId));
      }
    };
    const userEmailId = account?.username
    window.localStorage.setItem("user_email", userEmailId!);
    const fetchDataAndUserGroups = async () => {
      await fetchData();
      await getUserGroupsData();
      setIsLoading(false);
    };
    fetchDataAndUserGroups();
    const intervalId = setInterval(fetchData, 5 * 60 * 1000); // Call fetchData every 5 minutes
    return () => clearInterval(intervalId); // Clear the interval when the component unmounts
  }, []);
  
  if (isLoading) {
    return <Loader shape='rect' label='Please Wait, Loading...' fullPage />
  }

  return (
    <BrowserRouter >
      <AppHeader />
      <AppBody />
    </BrowserRouter>
  )
}
