import { createAsyncThunk } from "@reduxjs/toolkit"
import { appInsights } from "../../config/appInsights";
import { postRequest } from "../utils/PostRequest";
import { v4 as uuidv4 } from 'uuid';

export const getUnmappedJobTitles = createAsyncThunk<any, any>('JobTitleMapping/RetreiveUnmappedJobTitles', async (language: string) => {

  var correlationId = uuidv4();
  const payload = {
    "language": language.toString(),
    "correlationId": correlationId
  }
  appInsights.trackEvent({ name: "Sending request to JobTitleMapping/RetreiveUnmappedJobTitles data", properties: { payload: JSON.stringify(payload), correlationId: correlationId, page: "Job Title Mapping" } });
  const response = await postRequest<any, any>('JobTitleMapping/RetreiveUnmappedJobTitles', payload, correlationId);
  response.data === null ? appInsights.trackEvent({ name: "Data not fetched from JobTitleMapping/RetreiveUnmappedJobTitles api", properties: { data: JSON.stringify(response.data), correlationId: correlationId } }) : appInsights.trackEvent({ name: "Data fetched from JobTitleMapping/RetreiveUnmappedJobTitles api", properties: { correlationId: correlationId } });
  return response.data;
})
