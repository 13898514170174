import React, { useState, useEffect, useReducer, useMemo } from 'react';
import { RootState } from '../../root-redux/RootState';
import { useSelector, useDispatch } from 'react-redux';
import '../grid/grid.css';
import {
  DataGridView,
  Button,
  SearchBox,
  Pagination,
  Icon,
  Select,
  Option
} from '../../common/HarmonyEnablers';
import { setCompanyStatusFilter, setCertifiedFilter, setServerSidePaginationCurrentOffset, setServerSidePaginationMaxPage, setServerSidePaginationMinPage, setServerSidePaginationCurrentPage, setServerSidePaginationSearchTerm, setServerSidePaginationSortType, setServerSidePaginationSortOrder, setServerSidePaginationIsAscending } from '../domain-mapping/domain-mapping-redux/DomainMapping.redux';
import { CrmStatusFilter, CertifiedStatusFilter } from './FilterOptions';
type GridProps = {
  columnData: any[];
  rowData: any[];
  handleCertifiedButtonClick?: (row: any) => void;
  handleEditButtonClick?: (row: any) => void;
  handleDeleteButtonClick?: (row: any) => void;
  handleDownload?: (row: any, type: string, fileName: string) => void;
  page: string;
  isExcelUpload?: boolean;
  uploadToExcel?: () => any;
  onRefresh?: () => void;
  totalPages: number;
  GetPagedDomainMappingsByCountry: (offsetRowsCount: number, searchTerm: string, sortType: string, sortOrder: string, companyStatusFilter: string, certifiedStatusFilter: string) => void;
  getLatestData: (searchTerm: string, sortType: string, sortOrder: string, companyStatusFilter: string, certifiedStatusFilter: string) => void;
}

export const ServerGrid: React.FC<GridProps> = ({ columnData, rowData, handleCertifiedButtonClick, handleDeleteButtonClick, handleEditButtonClick, handleDownload, page, isExcelUpload, uploadToExcel, onRefresh, totalPages, GetPagedDomainMappingsByCountry, getLatestData }) => {

  const dispatchFn = useDispatch();
  const { serverSidePagination, isCertifiedUser, companyStatusFilter, certifiedFilter } = useSelector((state: RootState) => state.domainMapping);
  const { isNavPanelOpen } = useSelector((state: RootState) => state.nav);
  const columns = useMemo(() => {
    return columnData.map((x: any) => {
      return {
        field: x.field,
        content: x.content,
        sortable: x.sortable,
      };
    });
  }, [columnData]);

  const rows: { id: any; cells: Record<string, any> }[] = useMemo(() => {
    let value: string;
    return rowData.map((x: any) => {
      const row = {
        id: x.MappingId,
        cells: columns.reduce((cell: Record<string, any>, column: any) => {
          if (column.field === "certified") {
            switch (page) {
              case "domainMappingManual":
                cell[column.field] = x["Certified"];
                break;

              default:
                break;
            }
          }
          else {
            cell[column.field] = x[column.field];
          }
          return cell;
        }, {}),
        data: x
      };
      return row;
    });
  }, [rowData, columns, page]);

  const [filter, setFilter] = useState('');
  const [pageNumber, setPageNumber] = useState<number>(serverSidePagination.currentPage);
  const [pagedRows, setPagedRows] = useState<any>([]);
  const [pageSize, setPageSize] = useState(20);
  let [filteredRows, dispatch] = useReducer(
    () =>
      rows.filter((row) => {
        const matchesFilter = Object.values(row.cells).some((cell: any) => {
          if (typeof cell === "string") {
            return cell.includes(filter);
          }
          return false;
        });
        if (page === "domainMappingManual") {
          const matchesStatus = companyStatusFilter.length === 0 || row.cells.CompanyStatus === companyStatusFilter;
          const matchesCertified = certifiedFilter.length === 0 || row.cells.Certified === certifiedFilter;
          return matchesFilter && matchesStatus && matchesCertified;
        }
        return matchesFilter;
      }),
    rows
  );

  const handleCompanyStatusFilterChange = (filterValue: any) => {
    setPageNumber(1);

    dispatchFn(setServerSidePaginationCurrentPage(1));
    dispatchFn(setCompanyStatusFilter(filterValue.field));
    getLatestData(serverSidePagination.searchTerm, serverSidePagination.sortType, serverSidePagination.sortOrder, filterValue.field, serverSidePagination.certifiedStatusFilter);
    dispatch();
  }
  const handleCertifiedStatusFilterChange = (filterValue: any) => {
    setPageNumber(1);

    dispatchFn(setServerSidePaginationCurrentPage(1));
    dispatchFn(setCertifiedFilter(filterValue.field));
    getLatestData(serverSidePagination.searchTerm, serverSidePagination.sortType, serverSidePagination.sortOrder, serverSidePagination.companyStatusFilter, filterValue.field);
    dispatch();
  }

  const pageArray = () => {
    const pagedRows = filteredRows.slice((pageNumber - serverSidePagination.currentPage) * pageSize, (pageNumber - serverSidePagination.currentPage + 1) * pageSize);
    const pageCount = Math.ceil(filteredRows.length / pageSize);
    setPagedRows(pagedRows);
  };

  const handleSort = async (e: CustomEvent<{ sortBy: string, isAscending: boolean }>) => {

    const newSortBy = e.detail.sortBy;
    const newIsAscending = e.detail.isAscending;

    if (newSortBy === serverSidePagination.sortType && newIsAscending === true && serverSidePagination.sortOrder === "ASC") {
      e.detail.isAscending = !e.detail.isAscending;
    }
    const newIsAscending1 = e.detail.isAscending;
    let sortOrder: string;
    let sortType: string;

    if (e.detail.isAscending === true) {
      sortOrder = "ASC";
      dispatchFn(setServerSidePaginationSortOrder("ASC"));

    } else {
      sortOrder = "DESC";
      dispatchFn(setServerSidePaginationSortOrder("DESC"));
    }

    let sortBy = e.detail.sortBy;
    dispatchFn(setServerSidePaginationSortType(e.detail.sortBy));
    setPageNumber(1);
    dispatchFn(setServerSidePaginationCurrentPage(1));
    GetPagedDomainMappingsByCountry(0, serverSidePagination.searchTerm, sortBy, sortOrder, serverSidePagination.companyStatusFilter, serverSidePagination.certifiedStatusFilter);
    e.detail.isAscending = newIsAscending1;

  }

  const submitSearch = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setPageNumber(1);

    dispatchFn(setServerSidePaginationCurrentPage(1));
    getLatestData(serverSidePagination.searchTerm, serverSidePagination.sortType, serverSidePagination.sortOrder, serverSidePagination.companyStatusFilter, serverSidePagination.certifiedStatusFilter);
  }

  const clearSearch = () => {
    setPageNumber(1);

    dispatchFn(setServerSidePaginationCurrentPage(1));
    getLatestData("", serverSidePagination.sortType, serverSidePagination.sortOrder, serverSidePagination.companyStatusFilter, serverSidePagination.certifiedStatusFilter);
  }



  const handleClick = async () => {
    uploadToExcel && await uploadToExcel();
  };

  useEffect(pageArray, [filter, pageNumber, pageSize, filteredRows]);
  return (
    <>
      <DataGridView rows={pagedRows} columns={columns} sortBy={serverSidePagination.sortType} sortAscending={serverSidePagination.sortOrder === 'ASC'} onHeSort={handleSort}>
        {pagedRows.map((row:any) => (
          <>
            <span slot={`certified-${row.id}`} key={`certified-${row.id}`}>
              <input type="checkbox" id={`certified-${row.id}`} disabled={!isCertifiedUser} onClick={() => { handleCertifiedButtonClick && handleCertifiedButtonClick(row) }} onKeyDown={(e)=>{if(e.key==='Enter'){handleCertifiedButtonClick && handleCertifiedButtonClick(row)}}} name="iscertified" checked={row.cells.certified} aria-label='Certified' />

              <br />
            </span>
            <span slot={`edit-${row.id}`} key={`edit-${row.id}`}>
              <Button appearance="secondary" disabled={!isCertifiedUser && row.cells.certified} onClick={() => { handleEditButtonClick && handleEditButtonClick(row) }}><Icon name="edit" aria-label='edit-button' role='button' /></Button>
              <br />
            </span>
            <span slot={`delete-${row.id}`} key={`delete-${row.id}`}>
              <Button appearance="secondary" disabled={!isCertifiedUser && row.cells.certified} onClick={() => { handleDeleteButtonClick && handleDeleteButtonClick(row) }}><Icon name="delete" aria-label='delete-button' role='button' /></Button>
              <br />
            </span>
            <span slot={`download-${row.id}`} key={`download-${row.id}`}>
              {(row.cells.Status === "Completed" || row.cells.status === "Completed") ? <a role="button" href="#" onClick={() => handleDownload && handleDownload(row.id, "inputfile", row.cells.download)}>Download</a> : " "}
            </span>
            <span slot={`download1-${row.id}`} key={`download1-${row.id}`}>
              <a role="button" href="#" onClick={() => handleDownload && handleDownload(row.id, "processedfile", row.cells.download1)}>{(row.cells.Status === "Completed" || row.cells.status === "Completed") ? "Download" : ""}</a>
            </span>
            <span slot={`inputfile-${row.id}`} key={`inputfile-${row.id}`}>
              <a role="button" href="#" onClick={() => handleDownload && handleDownload(row.id, "inputfile", row.cells.inputfile)}>{(row.cells.Status === "Complete") ? "Download" : ""}</a>
            </span>
            <span slot={`processedfile-${row.id}`} key={`processedfile-${row.id}`}>
              <a role="button" href="#" onClick={() => handleDownload && handleDownload(row.id, "processedfile", row.cells.processedfile)}>{(row.cells.Status === "Complete") ? "Download" : ""}</a>
            </span>

          </>
        ))}

        <div className={isNavPanelOpen? "datagrid-filter-bar-collapsed":"datagrid-filter-bar"} slot="data-grid-controls" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
          <div style={{ display: 'flex', alignItems: 'center', flex: 1 }}>
            <form onSubmit={(e) => submitSearch(e)}>
              <SearchBox className='datagrid-search-box'
                style={{ maxWidth: '250px' }}
                value={serverSidePagination.searchTerm}

                onInput={(e) => { setFilter(e.currentTarget.value !== undefined ? e.currentTarget.value.toString() : ''); dispatchFn(setServerSidePaginationSearchTerm(e.currentTarget.value)) }}

                placeholder="Search"
                onHeClear={() => { setFilter(""); dispatch(); dispatchFn(setServerSidePaginationSearchTerm("")); clearSearch(); }}
              ></SearchBox>
            </form>
          </div>
          {page === "domainMappingManual" && <div>
                      {/*  <Select className={isNavPanelOpen?"crmStatus-collapsed":"crmStatus"} label="Company Status" style={{ maxWidth: '200px', marginBottom: 'var(--he-spacing-large)' }} value={serverSidePagination.companyStatusFilter.length > 0 ? serverSidePagination.companyStatusFilter : "Select a company status"}
              placeholder='Select a company status'>
              {CrmStatusFilter.map((crmStatus) => (
                  <Option key={crmStatus.field} role="menuitemcheckbox"
                  value={crmStatus.content}
                  onHeSelected={(e: any) => {
                    handleCompanyStatusFilterChange(crmStatus)
                  }}
                >{crmStatus.content}
                </Option>
              ))
              }
        </Select>
        */}
            <Select className={isNavPanelOpen?"crmStatus-collapsed":"crmStatus"}label="Company Status" style={{ maxWidth: '200px', marginBottom: 'var(--he-spacing-large)' }} value={serverSidePagination.companyStatusFilter.length > 0 ? serverSidePagination.companyStatusFilter : "Select a company status"}
              placeholder='Select a company status'
              role="combobox"
              aria-expanded={isNavPanelOpen ? 'true' : 'false'} // Add aria-expanded attribute
              aria-controls="crm-status-options" // Add aria-controls attribute pointing to the ID of the options
              >
            <div id="crm-status-options" role="listbox"> {/* Add a listbox wrapper for the options */}
              {CrmStatusFilter.map((crmStatus) => (
                  <Option key={crmStatus.field} role="option" // Set role for options
                    aria-selected={serverSidePagination.companyStatusFilter === crmStatus.content} // Add aria-selected attribute for current selection
                    value={crmStatus.content}
                    onHeSelected={(e) => handleCompanyStatusFilterChange(crmStatus)}
                  >{crmStatus.content}
                  </Option>
              ))
              }
            </div>
              </Select>

                  </div>}

          {page === "domainMappingManual" && <div>
                      {/*    <Select className={isNavPanelOpen?"certifiedStatus-collapsed":"certifiedStatus"} label="Certified" style={{ maxWidth: '200px', marginBottom: 'var(--he-spacing-large)' }}
              value={CertifiedStatusFilter.find(item => item.field === serverSidePagination.certifiedStatusFilter).content}
              placeholder='Select a certified category'>
              {CertifiedStatusFilter.map((certified) => (
                  <Option key={certified.field} role="menuitemcheckbox"
                  value={certified.content}
                  onHeSelected={(e: any) => {
                    handleCertifiedStatusFilterChange(certified)
                  }}
                >{certified.content}
                </Option>
              ))
              }
                      </Select>
                      */}
                      <Select
                          className={isNavPanelOpen ? "certifiedStatus-collapsed" : "certifiedStatus"}
                          label="Certified"
                          style={{ maxWidth: '200px', marginBottom: 'var(--he-spacing-large)' }}
                          value={CertifiedStatusFilter.find(item => item.field === serverSidePagination.certifiedStatusFilter)?.content || "Select a certified category"}
                          placeholder='Select a certified category'
                          role="combobox" // Set role as combobox
                          aria-expanded={isNavPanelOpen ? 'true' : 'false'} // Add aria-expanded attribute
                          aria-controls="certified-status-options" // Add aria-controls attribute
                          aria-haspopup="listbox" // Indicates that the combobox has a popup list
                      >
                          <div id="certified-status-options" role="listbox"> {/* Add a listbox wrapper for the options */}
                              {CertifiedStatusFilter.map((certified) => (
                                  <Option
                                      key={certified.field}
                                      role="option" // Role for individual options
                                      aria-selected={serverSidePagination.certifiedStatusFilter === certified.field} // Add aria-selected attribute
                                      value={certified.content}
                                      onHeSelected={(e) => {
                                          handleCertifiedStatusFilterChange(certified);
                                      }}
                                  >
                                      {certified.content}
                                  </Option>
                              ))}
                          </div>
                      </Select>


          </div>}

          {
            isExcelUpload && <a style={{ color: '#006cc2' }} href="#" id='ExportToExcel' onClick={handleClick}><Icon name='excellogo' />Export To Excel</a>
          }

        </div>

        <Pagination
          slot="pagination"
          value={pageNumber}
          page-count={totalPages}
          onHePageChange={(e) => {
            setPageNumber(e.detail);
            if (e.detail > serverSidePagination.maxPage || e.detail < serverSidePagination.minPage) {
              dispatchFn(setServerSidePaginationCurrentPage(e.detail));
              dispatchFn(setServerSidePaginationCurrentOffset((e.detail - 1) * pageSize));
              dispatchFn(setServerSidePaginationMinPage(e.detail));
              dispatchFn(setServerSidePaginationMaxPage(e.detail - 1 + Math.min(serverSidePagination.pageSize / pageSize, Math.ceil((serverSidePagination.totalRecordsCount - serverSidePagination.currentOffset) / pageSize))));
              GetPagedDomainMappingsByCountry((e.detail - 1) * pageSize, serverSidePagination.searchTerm, serverSidePagination.sortType, serverSidePagination.sortOrder, serverSidePagination.companyStatusFilter, serverSidePagination.certifiedStatusFilter);
            }
          }}
          overflowPosition='top'
          overflow-dropdown={true}
        ></Pagination>

        {rowData.length > 0 ? <span slot="no-records">Please Wait, Loading...</span> : <span slot="no-records">No search results found.</span>}
      </DataGridView>
    </>



  );
};
