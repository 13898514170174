// config.js
const config = {
    redirect_url: process.env.REACT_APP_REDIRECT_URL || "http://localhost:3000",
    authority: process.env.REACT_APP_AUTHORITY || "https://login.microsoftonline.com/72f988bf-86f1-41af-91ab-2d7cd011db47",
    client_id: process.env.REACT_APP_CLIENT_ID || "6edfbb29-f867-41b5-a00e-9d7d634e8b8e",
    scope: process.env.REACT_APP_SCOPE || "api://e8d5cab8-ebfd-4501-b834-6f676614b731/default",
    graphAPIToken: process.env.REACT_GRAPHAPI_TOKEN || "",
    oauth2TokenUrl: process.env.REACT_APP_OAUTH2_TOKEN_URL || "https://login.microsoftonline.com/72f988bf-86f1-41af-91ab-2d7cd011db47/oauth2/v2.0/token",
    target: process.env.REACT_APP_TARGET || "https://management.core.windows.net//user_impersonation",
    serviceURL : process.env.REACT_APP_CDES_BASE_URL || "https://localhost:44302",
    GDCAccountMatchingReportURL : "https://msit.powerbi.com/reportEmbed?reportId=a71c1045-4655-4d69-b1be-f693a8c03ca5&autoAuth=true&ctid=72f988bf-86f1-41af-91ab-2d7cd011db47&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9kZi1tc2l0LXNjdXMtcmVkaXJlY3QuYW5hbHlzaXMud2luZG93cy5uZXQvIn0%3D",
    microsoftDataPrivacyURL : "https://go.microsoft.com/fwlink/?LinkId=518021",
};

export default config;
