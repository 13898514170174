import React from 'react';
import { Button, Icon, Menu, MenuItem } from '../../../common/HarmonyEnablers';
import { useSelector, useDispatch } from 'react-redux';
import { AppDispatch, RootState } from "../../../root-redux/RootState";
import { saveData } from '../../../services/CompanyMapping/PostSaveData.api';
import { getMappingDataByCountry } from '../../../services/CompanyMapping/GetMappingDataByCountry.api';
import { getCompanies } from '../../../services/CompanyMapping/GetCompanies.api';
import { saveUnmatchedData } from '../../../services/CompanyMapping/PostSaveUnmatchedCompanyData.api';
import { companyMappingRowObject } from '../CompanyMappingAttributes';
import { clearCompanyList,setCrmAccountId, setIsOpenMsgBar, setMappedCompany, setMappedCompanyName, setResponse, setResStatus, clearMappedCompany, setInputCompany, clearSuccessMsg } from '../company-mapping-redux/CompanyMapping.redux';
import { Spinner } from '../../../shared-components/loader/Spinner';
import { RequestStatus } from '../../../model/ServiceRequestStatus.model';

type InputFieldsProps = {
    createNewMapping: boolean;
    editBtnClicked: boolean;
    setCreateMapping: React.Dispatch<React.SetStateAction<boolean>>;
    setEditBtnClicked: React.Dispatch<React.SetStateAction<boolean>>;
    companyMappingRowData?: companyMappingRowObject[];
    toEditRow?: any;
    editData: boolean;
    setEditData: React.Dispatch<React.SetStateAction<boolean>>;
    setEditMapping: React.Dispatch<React.SetStateAction<boolean>>;
}

export const InputFields: React.FC<InputFieldsProps> = ({ setCreateMapping, setEditMapping, editData, setEditData }) => {
    const { selectedCountry,crmAccountId, error, getCompaniesStatus, unmatchedOnly, mappedCompanyList, editDeleteRow, mappedCompany, inputCompany } = useSelector((state: RootState) => state.companyMapping);
    const {isNavPanelOpen} = useSelector((state: RootState) => state.nav);
    const dispatch = useDispatch();
    const dispatchApp = useDispatch<AppDispatch>();
    const handleSelectedMappedCompany = async (company: any) => {
        dispatch(setCrmAccountId(company.AccountId));
        dispatch(setMappedCompanyName(company.Company_Name));
    }
    const handleMappedCompanyName = async (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(clearCompanyList());
        dispatch(setMappedCompany(event.target.value));
        if (event.target.value.length > 2) {
            const payload = {
                currentCompanyName: event.target.value.toString(),
                selectedCountry: selectedCountry.DisplayName
            }
            dispatchApp(getCompanies(payload));
        }
    }

    const handleInputCompanyName = async (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(setInputCompany(event.target.value));
    }

    const resetMessagesAfterFiveSeconds = (): void => {
        setTimeout(() => {
            dispatch(clearSuccessMsg());
          }, 5000);
    };
    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        var crmAccountId = document.getElementById("crm-account-id") as HTMLInputElement;
        var mappedCompanyvalue = document.getElementById("mapped-company-name") as HTMLInputElement;
        if (mappedCompanyvalue.value === "") {
            setIsOpenMsgBar(true);
            dispatch(setResponse("error"));
            dispatch(setResStatus("Please fill all the fields"));
            return;
        }
        var inputCompanyName = document.getElementById("inputCompanyName") as HTMLInputElement;
        var includeCrmAccountId = document.getElementById("includecrmaccount") as HTMLInputElement;

        var payload = {};
        if (editDeleteRow.id > 0) {
            payload = { "Id": editDeleteRow.id, "Country": selectedCountry.DisplayName, "InputCompanyName": inputCompanyName.value, "MatchCompanyName": mappedCompany, "CRMAccountId": crmAccountId.value, CRMAccountIdCheck: includeCrmAccountId.checked };

        } else {
            payload = { "Id": 0, "Country": selectedCountry.DisplayName, "InputCompanyName": inputCompanyName.value, "MatchCompanyName": mappedCompany, "CRMAccountId": crmAccountId.value, CRMAccountIdCheck: includeCrmAccountId.checked };
        }
        unmatchedOnly ? await dispatchApp(saveUnmatchedData(payload)) : await dispatchApp(saveData(payload));
        await dispatchApp(getMappingDataByCountry({ Country: selectedCountry.DisplayName, loadedRows: 0, rows: 10, unmatchedOnly: unmatchedOnly }));

        resetMessagesAfterFiveSeconds();
        setCreateMapping(false);
        setEditMapping(false);
        dispatch(clearMappedCompany());
    }

    return (
        <form className={isNavPanelOpen?'company-mapping-form-collapsed':'company-mapping-form'} onSubmit={handleSubmit}>
            <div className={isNavPanelOpen?"company-mapping-input-fields-collapsed":"company-mapping-input-fields"}>
                <div>
                    <label htmlFor="inputCompanyName">Input Company Name</label>
                    {editData && <input type="text" id="inputCompanyName" onChange={(event) => { handleInputCompanyName(event) }} value={inputCompany} autoComplete='off' />}
                    {!editData && <input type="text" id="inputCompanyName" autoComplete='off' required />}
                </div>
                <div>
                    <label htmlFor="mapped-company-name">Mapped Company Name</label>
                    <div><input type="text" id="mapped-company-name" onChange={(event) => { handleMappedCompanyName(event) }} value={mappedCompany} autoComplete='off' required />
                        {mappedCompany?.length > 2 && <Menu className='menu'>

                            {
                                mappedCompanyList.map((company: any) => (<MenuItem className="menu-item" onClick={() => { dispatch(setMappedCompany(company.Company_Name)); handleSelectedMappedCompany(company); dispatch(clearCompanyList()); }}>{company.Suggestion})</MenuItem>))
                            }
                        </Menu>}
                        {getCompaniesStatus === RequestStatus.loading && <div style={{ "display": 'flex', "justifyContent": 'center' }}>
                            <div style={{ "width": '20px' }}>
                                <Spinner />
                            </div>
                        </div>}
                        {
                                ((getCompaniesStatus === 2) && error!==undefined) && <div style={{ "color":"#d90000","display": 'flex' }}>{error}</div>
                            }
                    </div>
                </div>
                <div>
                    <label htmlFor="crm-account-id">CRM Account ID</label>
                    <input type="text" id="crm-account-id" value={crmAccountId} disabled />
                </div>

            </div>
            <div className={isNavPanelOpen? 'includecrmid-checkbox-collapsed':'includecrmid-checkbox'}>
                <input type="checkbox" id="includecrmaccount" name="includecrmaccount" value="includecrmaccount" defaultChecked={false} onKeyDown={(e) => { if (e.key === 'Enter') { e.currentTarget.checked = !e.currentTarget.checked } }} />
                <label htmlFor="includecrmaccount">Include CRM Account ID</label>
            </div>


            <div className={isNavPanelOpen?"company-mapping-btn-footer-collapsed":"company-mapping-btn-footer"}>
                <Button appearance="primary" type="submit" className={isNavPanelOpen?'company-mapping-func_btn-collapsed':'company-mapping-func_btn'}><Icon name="save" slot="start" />Save Changes</Button>
                <Button appearance="secondary" className={isNavPanelOpen?'company-mapping-func_btn-collapsed':'company-mapping-func_btn'} onClick={() => { setCreateMapping(false); setEditMapping(false); dispatch(setMappedCompany("")); dispatch(setCrmAccountId("")); dispatch(clearCompanyList()); setEditData(false); }}><Icon name="clear" slot="start" />Cancel</Button>
            </div>
        </form>
    )
}