import { createSlice } from "@reduxjs/toolkit";
import { getUnmappedJobTitles } from '../../../services/JobTitleMappingV2/GetUnmappedJobTitles.api'
import { RequestStatus } from '../../../model/ServiceRequestStatus.model'

import { getAuthorizedLanguageData } from '../../../services/JobTitleMappingV2/GetAuthorizedLanguageData.api'
import { getJobRoleData } from '../../../services/JobTitleMappingV2/GetJobRoleData.api'
import { getJobTitleMappingData } from '../../../services/JobTitleMappingV2/GetJobTitleMappingData.api'
import { createJobTitleMappingSingle } from '../../../services/JobTitleMappingV2/PostCreateJobTitleMappingSingle.api'
import { deleteJobTitleMappingSingle } from '../../../services/JobTitleMappingV2/PostDeleteJobTitleMapping.api'
import { updateJobTitleMappingSingle } from '../../../services/JobTitleMappingV2/PostUpdateJobTitleMappingSingle.api'
import { exportAllUnmappedJobTitleToExcel } from '../../../services/JobTitleMappingV2/ExportAllUnmappedJobTitleMappingToExcel.api'
import { getExcelFile } from '../../../services/JobTitleMappingV2/GetExcelFile.api'

import { authLanguage, mappedJobColObject, mappedJobRowObject, unmappedJobColObject, unmappedJobRowObject } from "../JobTitleMappingAttributes";

import { convert_milliseconds_to_date, formatDate, convert_seconds_to_date } from "../../../utils/DateConversionUtil";

const mappedColumns: mappedJobColObject[] = [
  { field: "JobTitle", content: "Job Title", sortable: true },
  { field: "StandardJobRoleName", content: "Job Role", sortable: true },
  { field: "MarketingAudience", content: "Marketing Audience", sortable: true },
  { field: "Function", content: "Function", sortable: true},
  { field: "Seniority", content: "Seniority", sortable: true},
  { field: "ModifiedDate", content: "Modified Date", sortable: true},
  { field: "edit", content: "Edit", sortable: false },
  { field: "delete", content: "Delete", sortable: false }
];

const initialState = {
  mappedJobList: <any>[],
  unmappedJobRowData: <any>[],
  unmappedJobColumnData: <any>[],
  mappedJobRowData: <any>[],
  mappedJobColumnData: <any>[],
  editDataJobTitle: "",
  createDataJobTitle: "",
  isUnmapped: false,
  editData: false,
  selectedMappedJob: <any>{},
  status: RequestStatus.idle,
  getUnmappedJobTitlesStatus: RequestStatus.idle,
  getAuthorizedLanguageDataStatus: RequestStatus.idle,
  getJobRoleDataStatus: RequestStatus.idle,
  getJobTitleMappingDataStatus: RequestStatus.idle,
  createJobTitleMappingSingleStatus: RequestStatus.idle,
  deleteJobTitleMappingSingleStatus: RequestStatus.idle,
  updateJobTitleMappingSingleStatus: RequestStatus.idle,
  exportAllUnmappedJobTitleToExcelStatus: RequestStatus.idle,
  getExcelFileStatus: RequestStatus.idle,
  error: undefined as string | undefined,
  list: <any>[],
  authorizedLanguage: [] as authLanguage[],
  //jobTitleMappingData: [] as jobTitleMapping[],
  editMapping: false,
  createMapping: false,
  isDataToBeEditedFound: false,
  selectedLanguage: { "Language": "", "LanguageCode": "" } as authLanguage,
  response: "",
  nothingToShow: undefined as string | undefined,
  errorMessage: null as string | null,
  errorFetch: undefined as string | undefined,
  errorUpload: undefined as string | undefined,
  successMessage: undefined as string | undefined,

  jobRoleData: <any> {},
  newJobRoleData: <any> {}
}

const unmappedColumns: unmappedJobColObject[] = [
  { field: "inputJobTitle", content: "Input Job Title", sortable: true },
  { field: "unmatchCount", content: "Unmatch Count", sortable: true },
  { field: "updatedDate", content: "Updated Date", sortable: true },
  { field: "edit", content: "Edit", sortable: false },
];

export const RESET_STATE = 'jobTitleMapping/resetState';

export const resetState = () => ({
  type: RESET_STATE,
});

const jobTitleMappingSlice = createSlice({
  name: 'jobTitleMapping',
  initialState,
  reducers: {
    setIsUnmapped: (state, action) => {
      state.isUnmapped = action.payload;
    },
    setSelectedLanguage: (state, action) => {
      state.selectedLanguage = action.payload;
    },
    setEditData: (state, action) => {
      state.editData = action.payload;
    },
    setMappedJobList: (state, action) => {
      state.mappedJobList.push(action.payload.Data);
    },
    clearJobList: (state) => {
      state.mappedJobList = [];
    },

    setIsDataToBeEditedFound: (state, action) => {
      state.isDataToBeEditedFound = action.payload;
    },
    setUnmappedJobRowData: (state, action) => {
      state.unmappedJobRowData = [];
      state.unmappedJobRowData = action.payload;
    },
    clearUnmappedJobRowData: (state) => {
      state.unmappedJobRowData = [];
    },
    setUnmappedJobColumnData: (state, action) => {
      state.unmappedJobColumnData = [];
      state.unmappedJobColumnData = action.payload;
    },
    setMappedJobRowData: (state, action) => {
      state.mappedJobRowData = [];
      state.mappedJobRowData = action.payload;
    },
    clearMappedJobRowData: (state) => {
      state.mappedJobRowData = [];
    },
    setMappedJobColumnData: (state, action) => {
      state.mappedJobColumnData = [];
      state.mappedJobColumnData = action.payload;
    },
    setEditDataJobTitle: (state, action) => {
      state.editDataJobTitle = action.payload;
    },
    clearEditDataJobTitle: (state) => {
      state.editDataJobTitle = "";
    },
    setCreateDataJobTitle: (state, action) => {
      state.createDataJobTitle = action.payload;
    },
    clearCreateDataJobTitle: (state) => {
      state.createDataJobTitle = "";
    },
    setAuthorizedLanguage: (state, action) => {
      state.authorizedLanguage = action.payload;
    },
    clearNothingToShow: (state) => {
      state.nothingToShow = undefined;
    },
    setResponse: (state, action) => {
      state.response = action.payload;
    },
    setErrorMessage: (state, action) => {
      state.errorMessage = action.payload;
    },
    setErrorFetchMessage: (state, action) => {
      state.errorFetch = action.payload;
    },
    setErrorUploadMessage: (state, action) => {
      state.errorUpload = action.payload;
    },
    clearErrorFetchMessage: (state) => {
      state.errorFetch = undefined;
    },
    clearErrorUploadMessage: (state) => {
      state.errorUpload = undefined;
    },
    setSuccessMessage: (state, action) => {
      state.successMessage = action.payload;
    },
    clearSuccessMessage: (state) => {
      state.successMessage = undefined;
    },
    clearError: (state) => {
      state.error = undefined;
    },

    setJobRoleData: (state, action) => {
      state.jobRoleData = action.payload;
    },
    clearJobRoleData: (state) => {
      state.jobRoleData = {}
    },
    setNewJobRoleData: (state, action) => {
      state.newJobRoleData = action.payload;
    },
    clearNewJobRoleData: (state) => {
      state.newJobRoleData = {}
    }
  },
  extraReducers(builder) {
    builder
      .addCase(RESET_STATE, (state, action) => {
        return initialState;
      })
      .addCase(getUnmappedJobTitles.pending, (state, _) => {
        state.getUnmappedJobTitlesStatus = RequestStatus.loading;
        state.unmappedJobRowData = [];
      })
      .addCase(getUnmappedJobTitles.fulfilled, (state, action) => {
        state.getUnmappedJobTitlesStatus = RequestStatus.succeeded
        if (action.payload.Response && action.payload.Response === "error") {
          state.errorFetch = action.payload.Message;
        }
        else {
          let unmappedJobRowData: unmappedJobRowObject[] = action.payload.Data.map((row: any, index: number) => ({
            id: index + 1,
            inputJobTitle: row.jobTitle,
            unmatchCount: row.hitCount,
            updatedDate: formatDate(row.lastUpdatedDate)
          }));
          state.unmappedJobRowData = unmappedJobRowData;
          state.unmappedJobColumnData = unmappedColumns;
          if (state.unmappedJobRowData.length === 0) {
            state.nothingToShow = action.payload.Message;
          }
          else {
            state.nothingToShow = undefined;
          }
          state.errorFetch = undefined;
        }
      })
      .addCase(getUnmappedJobTitles.rejected, (state, action) => {
        state.getUnmappedJobTitlesStatus = RequestStatus.failed;
        state.errorFetch = action.error.message;
      })
      .addCase(getAuthorizedLanguageData.pending, (state, _) => {
        state.getAuthorizedLanguageDataStatus = RequestStatus.loading;
      })
      .addCase(getAuthorizedLanguageData.fulfilled, (state, action) => {
        state.getAuthorizedLanguageDataStatus = RequestStatus.succeeded;
        if (action.payload.Response && action.payload.Response === "error") {
          state.errorFetch = action.payload.Message;
        }
        else {
          state.errorFetch = undefined;
        }
      })
      .addCase(getAuthorizedLanguageData.rejected, (state, action) => {
        state.getAuthorizedLanguageDataStatus = RequestStatus.failed;
        state.errorFetch = action.error.message;
      })
      .addCase(getJobRoleData.pending, (state, _) => {
        state.getJobRoleDataStatus = RequestStatus.loading;
        state.mappedJobList = [];
      })
      .addCase(getJobRoleData.fulfilled, (state, action) => {
        state.getJobRoleDataStatus = RequestStatus.succeeded
        if (action.payload.Response && action.payload.Response === "error") {
          state.error = action.payload.Message;
          state.mappedJobList = [];
        }
        else {
          state.mappedJobList = [];
          for (let i = 0; i < action.payload.Data.length; i++) {
            let newObject: any = action.payload.Data;
            let JobRoleData = newObject[i];
            state.mappedJobList.push(JobRoleData);
          }
          state.error = undefined;
        }

      })
      .addCase(getJobRoleData.rejected, (state, action) => {
        state.getJobRoleDataStatus = RequestStatus.failed
        state.errorFetch = action.error.message
      })
      .addCase(getJobTitleMappingData.pending, (state, _) => {
        state.getJobTitleMappingDataStatus = RequestStatus.loading
      })
      .addCase(getJobTitleMappingData.fulfilled, (state, action) => {
        state.getJobTitleMappingDataStatus = RequestStatus.succeeded;
        if (action.payload.Response && action.payload.Response === "error") {
          state.errorFetch = action.payload.Message;
        }
        else {
          //state.jobTitleMappingData = action.payload.Data;
          var jobTitleMappingData = action.payload.Data;
          let mappedJobRowData: mappedJobRowObject[] = [];
          if(jobTitleMappingData != null){
            let newObj: mappedJobRowObject = { id: 1, JobTitle: jobTitleMappingData.inputJobTitle, StandardJobRoleName: jobTitleMappingData.standardJobRoleName, StandardJobRoleId: jobTitleMappingData.standardJobRoleId, MarketingAudience: jobTitleMappingData.marketingAudienceName, Seniority: jobTitleMappingData.seniorityName, Function: jobTitleMappingData.functionName,  ModifiedDate: convert_seconds_to_date(jobTitleMappingData._ts) };
            mappedJobRowData.push(newObj);
          }
          state.mappedJobRowData = mappedJobRowData;
          state.mappedJobColumnData = mappedColumns;

          if (mappedJobRowData.length === 0) {
            state.nothingToShow = action.payload.Message;
          }
          else {
            state.nothingToShow = undefined;
          }
          state.errorFetch = undefined;
        }
        state.editMapping = false;
        state.createMapping = false;
      })
      .addCase(getJobTitleMappingData.rejected, (state, action) => {
        state.getJobTitleMappingDataStatus = RequestStatus.failed
        state.errorFetch = action.error.message;
      })
      .addCase(createJobTitleMappingSingle.pending, (state, _) => {
        state.createJobTitleMappingSingleStatus = RequestStatus.loading
        state.nothingToShow = undefined;
      })
      .addCase(createJobTitleMappingSingle.fulfilled, (state, action) => {
        state.createJobTitleMappingSingleStatus = RequestStatus.succeeded;
        state.response = action.payload.Response;
        if (action.payload.Response === "success") {
          state.successMessage = action.payload.Message;
          state.errorUpload = undefined;
        }
        else {
          state.errorUpload = action.payload.Message;
          state.successMessage = undefined;
        }

      })
      .addCase(createJobTitleMappingSingle.rejected, (state, action) => {
        state.createJobTitleMappingSingleStatus = RequestStatus.failed
        state.errorUpload = action.error.message;
        state.successMessage = undefined;
      })
      .addCase(updateJobTitleMappingSingle.pending, (state, _) => {
        state.updateJobTitleMappingSingleStatus = RequestStatus.loading
      })
      .addCase(updateJobTitleMappingSingle.fulfilled, (state, action) => {
        state.updateJobTitleMappingSingleStatus = RequestStatus.succeeded;
        state.response = action.payload.Response;
        if (action.payload.Response === "success") {
          state.successMessage = action.payload.Message;
          state.errorUpload = undefined;
        }
        else {
          state.errorUpload = action.payload.Message;
          state.successMessage = undefined;
        }
        state.editMapping = false;
        state.isDataToBeEditedFound = false;
      })
      .addCase(updateJobTitleMappingSingle.rejected, (state, action) => {
        state.updateJobTitleMappingSingleStatus = RequestStatus.failed
        state.errorUpload = action.error.message;
        state.successMessage = undefined;
      })
      .addCase(deleteJobTitleMappingSingle.pending, (state, _) => {
        state.deleteJobTitleMappingSingleStatus = RequestStatus.loading
      })
      .addCase(deleteJobTitleMappingSingle.fulfilled, (state, action) => {
        state.deleteJobTitleMappingSingleStatus = RequestStatus.succeeded
        state.response = action.payload.Response;
        if (action.payload.Response === "success") {
          //state.jobTitleMappingData.splice(state.jobTitleMappingData.findIndex((x: any) => x.JobTitle === action.payload.JobTitle), 1);
          state.mappedJobRowData.splice(state.mappedJobRowData.findIndex((x: any) => x.JobTitle === action.payload.JobTitle), 1);
          state.successMessage = action.payload.Message;
        }
        else {
          state.errorUpload = action.payload.Message;
          state.successMessage = undefined;
        }

      })
      .addCase(deleteJobTitleMappingSingle.rejected, (state, action) => {
        state.deleteJobTitleMappingSingleStatus = RequestStatus.failed;
        state.errorUpload = action.error.message;
        state.successMessage = undefined;
      })
      .addCase(exportAllUnmappedJobTitleToExcel.pending, (state, action) => {
        state.exportAllUnmappedJobTitleToExcelStatus = RequestStatus.loading;
      })
      .addCase(exportAllUnmappedJobTitleToExcel.fulfilled, (state, action) => {
        state.exportAllUnmappedJobTitleToExcelStatus = RequestStatus.succeeded;
        if (action.payload.Response && action.payload.Response === "error") {
          state.errorFetch = action.payload.Message;
        }
        else {
          state.errorFetch = undefined;
        }
      })
      .addCase(exportAllUnmappedJobTitleToExcel.rejected, (state, action) => {
        state.exportAllUnmappedJobTitleToExcelStatus = RequestStatus.failed;
        state.errorFetch = action.error.message;
      })
      .addCase(getExcelFile.pending, (state, action) => {
        state.getExcelFileStatus = RequestStatus.loading;

      })
      .addCase(getExcelFile.fulfilled, (state, action) => {
        state.getExcelFileStatus = RequestStatus.succeeded;
        if (action.payload.Response && action.payload.Response === "error") {
          state.errorFetch = action.payload.Message;
        }
        else {
          state.errorFetch = undefined;
        }
      })
      .addCase(getExcelFile.rejected, (state, action) => {
        state.getExcelFileStatus = RequestStatus.failed;
        state.errorFetch = undefined;
      })

  }
})

export const { setEditData,
  setIsUnmapped,
  setMappedJobRowData,
  setMappedJobColumnData,
  setUnmappedJobRowData,
  setUnmappedJobColumnData,
  setMappedJobList,
  setEditDataJobTitle,
  setCreateDataJobTitle,
  clearCreateDataJobTitle,
  clearJobList,
  clearMappedJobRowData,
  clearUnmappedJobRowData,
  clearEditDataJobTitle,
  setSelectedLanguage,
  setIsDataToBeEditedFound,
  clearNothingToShow,
  setResponse,
  setErrorMessage,
  setErrorFetchMessage,
  setErrorUploadMessage,
  clearErrorFetchMessage,
  clearErrorUploadMessage,
  setSuccessMessage,
  clearSuccessMessage,
  clearError,
  setJobRoleData,
  setNewJobRoleData,
  clearJobRoleData,
  clearNewJobRoleData
} = jobTitleMappingSlice.actions;

export const JobTitleMappingV2Reducer = jobTitleMappingSlice.reducer

