import { createAsyncThunk } from "@reduxjs/toolkit"
import { getRequestForExcel } from "../utils/GetRequest"
import { DownloadExcelData } from "../utils/ExcelHandler"
import { appInsights } from "../../config/appInsights";
import { v4 as uuidv4 } from 'uuid';
export const getEnrichedFile = createAsyncThunk<any, any>('FileUpload/DownloadFile', async (payload: any) => {
  var correlationId = uuidv4();
  appInsights.trackEvent({ name: "Sending request to FileUpload/DownloadFile data", properties: { correlationId: correlationId, page: "Lead Enrichment" } });
  await getRequestForExcel<string>(`FileUpload/DownloadFile?${payload.fileName}`, correlationId)
    .then(response => {
      DownloadExcelData(response);
      appInsights.trackEvent({ name: "Data fetched from FileUpload/DownloadFile api", properties: { correlationId: correlationId } })
    }).catch(error => {
      appInsights.trackEvent({ name: "Data fetched from FileUpload/DownloadFile api", properties: { error: error, correlationId: correlationId } })
    });
})


