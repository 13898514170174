import { createAsyncThunk } from "@reduxjs/toolkit"
import { getRequest } from "../../services/utils/GetRequest"
import { appInsights } from "../../config/appInsights";
import { v4 as uuidv4 } from 'uuid';

export const getMappingDataByCountry = createAsyncThunk<any, { Country: string, Dummy: string }>('IspDomainMapping/GetMappingDataByCountry', async ({ Country, Dummy }) => {
  var correlationId = uuidv4();
  appInsights.trackEvent({ name: "Sending request to get mapping data by country", properties: { correlationId: correlationId, page: "Domain Suppression" } });
  const response = await getRequest<any>(`IspDomainMapping/GetMappingDataByCountry?Country=${Country}&Dummy=${Dummy}`, correlationId)
  response.data === null ? appInsights.trackEvent({ name: "Data not fetched from IspDomainMapping/GetMappingDataByCountry api", properties: { correlationId: correlationId } }) : appInsights.trackEvent({ name: "Data fetched from IspDomainMapping/GetMappingDataByCountry api ", properties: { data: JSON.stringify(response.data), correlationId: correlationId } });
  return response.data
})
