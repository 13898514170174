import { RootState } from "../../root-redux/RootState";
import { useSelector } from "react-redux";
import { appInsights } from "../../config/appInsights";
import { UserProfileCard } from "../user-profile/UserProfileCard";
import { UserEmailComponent } from "../user-profile/userEmailComponent";
import './app-header-styles.css'
import { Button, Icon } from "../../common/HarmonyEnablers";
import { Dropdown } from "../../shared-components/dropdown-filter/DropdownFilterScope";
import { NavMenuItemType } from '../../model/NavMenu.model';
import { useNavigate } from 'react-router-dom';


export const AppHeader: React.FC = () => {
  const stateProps = useSelector((state: RootState) => ({
    selectedNavItem: state.nav.selectedNavItem    
  }));
  const navigate = useNavigate()

  appInsights.trackEvent({
    name: "Navigation header items set",
    properties: {
      navItem:
        stateProps.selectedNavItem == null
          ? "NavHeaderBar Item not set"
          : stateProps.selectedNavItem
    },
  });

  return (
      <header className="app-header-wrapper" role="banner">
        <div className="app-header">            
            <div className="app-header-title">
                <span style={{fontWeight:"600", color:"white", cursor:"pointer"}} onClick={() => navigate("/"+ NavMenuItemType[NavMenuItemType.Home])}>{'CDES Portal'}</span>                
            </div>
            <div className="app-header-actions">
                {/* <Button
                appearance="stealth"
                >
                <Icon name="gethelpapp" label="gethelpapp" style={{color:"white"}}></Icon>
                </Button>

                <Button
                appearance="stealth"
                >
                <Icon name="addagent" label="addagent" style={{color:"white"}}></Icon>
                </Button> */}

                <Dropdown fixed-placement>
                    <Button slot="trigger" appearance="stealth">
                        <UserEmailComponent />
                    </Button>
                    <UserProfileCard />
                </Dropdown>
            </div>
        </div>
    </header>
  );
};
