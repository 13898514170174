import { createAsyncThunk } from "@reduxjs/toolkit";
import { appInsights } from "../../config/appInsights";
import { postRequest } from "../utils/PostRequest";
import { v4 as uuidv4 } from 'uuid';


export const getDomainMappingCount = createAsyncThunk<any, any>('DomainMapping/RetrieveCountOfDomainMappings', async (payload: any) => {
  var correlationId = uuidv4();
  appInsights.trackEvent({ name: "Sending request to DomainMapping/RetrieveCountOfDomainMappings data", properties: { payload: JSON.stringify(payload), correlationId: correlationId, page: "Domain Mapping" } });
  const response = await postRequest<any, any>('DomainMapping/RetrieveCountOfDomainMappings', payload, correlationId);
  response.data === null ? appInsights.trackEvent({ name: "Data not fetched from DomainMapping/RetrieveCountOfDomainMappings api" }) : appInsights.trackEvent({ name: "Data fetched from DomainMapping/RetrieveCountOfDomainMappings api", properties: { correlationId: correlationId } });
  return response.data;
})

