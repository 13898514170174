import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin, withAITracking } from "@microsoft/applicationinsights-react-js";
import { ComponentType } from "react";

const browserHistory={basename:""};
const reactPlugin = new ReactPlugin();

const ai = new ApplicationInsights({
  config: {
    instrumentationKey: process.env.REACT_APP_APPINSIGHTS_KEY,
    autoTrackPageVisitTime: true, // logged as PageVisitTime:time a user spends on each page is tracked.
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory },
    },
  },
});
ai.loadAppInsights();
 ai.trackPageView();
export default (Component: ComponentType<any>) => withAITracking(reactPlugin, Component)
export const appInsights = ai.appInsights
export { reactPlugin };
