import { useSelector } from "react-redux";
import { RootState } from "../../root-redux/RootState";

export const UserEmailComponent:React.FC<any> = (props: any) => {
    const stateProps = useSelector((state: RootState) => ({
        userDetails: state.userDetails
      }));
    const userName = stateProps.userDetails.name
    const userInitials = (userName?userName:'').split(" ").map((n,i,a)=> i === 0 || i+1 === a.length ? n[0] : null).join("");
  return (
    <div>
        <span style={{fontWeight:"600", fontSize:"14px", backgroundColor:"#575757", color:"white", border:"4px solid #575757", borderRadius:"18px", cursor:"pointer"}}>{userInitials}</span>
    </div>
  );
};