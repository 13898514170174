import { createAsyncThunk } from "@reduxjs/toolkit"
import { getRequest, getRequestForExcel } from "../utils/GetRequest"
import { appInsights } from "../../config/appInsights";
import { DownloadExcelData } from "../utils/ExcelHandler";
import { v4 as uuidv4 } from 'uuid';

export const GetCompanyMappingTemplate = createAsyncThunk<any>('CompanyMapping/DownloadCompanyMappingTemplate', async (payload: any) => {
  var correlationId = uuidv4();
  appInsights.trackEvent({ name: "Sending request to CompanyMapping/DownloadCompanyMappingTemplate data", properties: { correlationId: correlationId, page: "Company Mapping" } });
  await getRequestForExcel<string>(`CompanyMapping/DownloadCompanyMappingTemplate`, correlationId)
    .then(response => {
      DownloadExcelData(response);
      appInsights.trackEvent({ name: "Data fetched from CompanyMapping/DownloadCompanyMappingTemplate api", properties: { correlationId: correlationId } })
    }).catch(error => {
      appInsights.trackEvent({ name: "Data fetched from CompanyMapping/DownloadCompanyMappingTemplate api", properties: { error: error, correlationId: correlationId } })
    });
});

