import { createAsyncThunk } from "@reduxjs/toolkit";
import { appInsights } from "../../config/appInsights";
import { postRequestForExcel } from "../utils/PostRequest";
import { v4 as uuidv4 } from 'uuid';

export const postDomainMappingBulkFile = createAsyncThunk<any, any>('DomainMapping/SubmitDomainMappingBulkFile', async (payload: any) => {
  var correlationId = uuidv4();
  appInsights.trackEvent({ name: "Sending request to DomainMapping/SubmitDomainMappingBulkFile data", properties: { correlationId: correlationId, page: "Domain Mapping" } });
  const response = await postRequestForExcel<any, any>('DomainMapping/SubmitDomainMappingBulkFile', payload, correlationId);
  response.data === null ? appInsights.trackEvent({ name: "Data not fetched from DomainMapping/SubmitDomainMappingBulkFile api", properties: { data: JSON.stringify(response.data), correlationId: correlationId } }) : appInsights.trackEvent({ name: "Data fetched from DomainMapping/SubmitDomainMappingBulkFile api ", properties: {correlationId: correlationId } });
  return response.data;
})
