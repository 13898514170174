import { createAsyncThunk } from "@reduxjs/toolkit"
import { postRequest } from "../utils/PostRequest"
import { appInsights } from "../../config/appInsights";
import { v4 as uuidv4 } from 'uuid';

export const deleteJobTitleMappingSingle = createAsyncThunk<any, any>('JobTitleMappingV2/DeleteJobTitleMappingSingle', async (payload) => {
  var correlationId = uuidv4();
  appInsights.trackEvent({ name: "Sending request to JobTitleMappingV2/DeleteJobTitleMappingSingle data", properties: { payload: JSON.stringify(payload), correlationId: correlationId, page: "Job Title Mapping" } });
  const response = await postRequest<any, any>('JobTitleMappingV2/DeleteJobTitleMappingSingle', payload, correlationId)
  response.data === null ? appInsights.trackEvent({ name: "Data not fetched from JobTitleMappingV2/DeleteJobTitleMappingSingle api", properties: { data: JSON.stringify(response.data), correlationId: correlationId } }) : appInsights.trackEvent({ name: "Data fetched from JobTitleMappingV2/DeleteJobTitleMappingSingle api", properties: { correlationId: correlationId } });
  return response.data
})



