import React from 'react';
import { Button, Icon } from '../../../common/HarmonyEnablers';
import { useSelector, useDispatch } from 'react-redux';
import { AppDispatch, RootState } from "../../../root-redux/RootState";
import { saveData } from '../../../services/DomainSuppression/PostSaveData.api';
import { getMappingDataByCountry } from '../../../services/DomainSuppression/GetMappingDataByCountry.api';
import { domainSuppressionRowObject } from '../DomainSuppressionAttributes';
import { clearSuccessMsg } from '../domain-suppression-redux/DomainSuppression.redux';
import { setEditIspLink, setEditIspName, setEditDomain, setEditSuppressed } from '../domain-suppression-redux/DomainSuppression.redux';

type InputFieldsProps = {
    createNewMapping: boolean;
    editBtnClicked: boolean;
    setCreateNewMapping: React.Dispatch<React.SetStateAction<boolean>>;
    setEditBtnClicked: React.Dispatch<React.SetStateAction<boolean>>;
    domainSuppressionRowData?: domainSuppressionRowObject[];
    toEditRow?: any;
}

export const InputFields: React.FC<InputFieldsProps> = ({ setCreateNewMapping, editBtnClicked, setEditBtnClicked, domainSuppressionRowData, toEditRow }) => {
    const { selectedCountry, editIspName, editIspLink, editDomain, editSuppressed } = useSelector((state: RootState) => state.domainSuppression);
    const { isNavPanelOpen } = useSelector((state: RootState) => state.nav);
    const [ispName, setIspName] = React.useState<string>("");
    const [ispLink, setIspLink] = React.useState<string>("");
    const [domain, setDomain] = React.useState<string>("");
    const [suppressed, setSuppressed] = React.useState<boolean>(true);
    const dispatchApp = useDispatch<AppDispatch>();
    const dispatch = useDispatch();
    const resetMessagesAfterFiveSeconds = (): void => {
        setTimeout(() => {
            dispatch(clearSuccessMsg());
          }, 5000);
    };

    const handleChangeIspName = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if(editBtnClicked){
            dispatch(setEditIspName(event.target.value));
        }
        else{
            setIspName(event.target.value);
        }
    }

    const handleChangeIspLink = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if(editBtnClicked){
            dispatch(setEditIspLink(event.target.value));
        }
        else{
            setIspLink(event.target.value);
        }
    }

    const handleChangeDomain = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if(editBtnClicked){
            dispatch(setEditDomain(event.target.value));
        }
        else{
            setDomain(event.target.value);
        }
    }

    const handleChangeSuppressed = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if(editBtnClicked){
            dispatch(setEditSuppressed(event.target.checked));
        }
        else{
            setSuppressed(event.target.checked);
        }
    }

    const handleCreateDomainSuppression = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        var ispName = document.getElementById("ispName") as HTMLInputElement;
        var ispLink = document.getElementById("ispLink") as HTMLInputElement;
        var domain = document.getElementById("domain") as HTMLInputElement;
        var suppressed = document.getElementById("suppressed") as HTMLInputElement;
        if (domainSuppressionRowData?.find(x => x.Domain === domain.value)) {
            alert("Domain already exists");
            return;
        }
        let payload = {
            Id: 0,
            Country: selectedCountry.DisplayName,
            ProviderName: ispName.value,
            ProviderLink: ispLink.value,
            Domain: domain.value,
            Suppressed: suppressed.checked
        }
        if (toEditRow) {
            payload.Id = toEditRow?.data.id;
        }
        await dispatchApp(saveData(payload));
        await dispatchApp(getMappingDataByCountry({ Country: selectedCountry.DisplayName, Dummy: new Date().getTime().toString() }));
        resetMessagesAfterFiveSeconds();
        setCreateNewMapping(false);
        setEditBtnClicked(false);
    }


    return (
        <form className={isNavPanelOpen?'domain-suppression-form-collapsed':'domain-suppression-form'} onSubmit={handleCreateDomainSuppression}>
            <div className={isNavPanelOpen?"domain-suppression-input-fields-collapsed":"domain-suppression-input-fields"}>
                <div>
                    <label htmlFor="ispName">ISP Name</label>
                    <input type="text" id="ispName" value={editBtnClicked? editIspName : ispName} onChange={(event) => { handleChangeIspName(event) }} autoComplete='off' required />                    
                </div>
                <div>
                    <label htmlFor="ispLink">ISP Link</label>
                    <input type="text" id="ispLink" value={editBtnClicked? editIspLink: ispLink} onChange={(event) => { handleChangeIspLink(event) }} autoComplete='off' required />
                </div>
                <div>
                    <label htmlFor="domain">Domain</label>
                    <input type="text" id="domain" value={editBtnClicked? editDomain : domain} onChange={(event) => { handleChangeDomain(event) }} autoComplete='off' required />
                </div>

            </div>
            <div className={isNavPanelOpen?'domain-suppression-checkbox-collapsed':'domain-suppression-checkbox'}>
                <input type="checkbox" id="suppressed" name="suppressed" value="suppressed" checked={editBtnClicked?editSuppressed:suppressed} onChange={(event) => { handleChangeSuppressed(event) }} />
                <label htmlFor="suppressed">Suppressed</label>
            </div>
            <div className={isNavPanelOpen?"domain-suppression-btn-footer-collapsed":"domain-suppression-btn-footer"}>
                <Button appearance="primary" type="submit" className='domain-suppression-func_btn'><Icon name="save" slot="start" />Save Changes</Button>
                <Button appearance="secondary" className='domain-suppression-func_btn' onClick={() => { setCreateNewMapping(false); setEditBtnClicked(false) }}><Icon name="clear" slot="start" />Cancel</Button>
            </div>
        </form>
    )
}