import { useSelector } from "react-redux";
import { Card } from "../../common/HarmonyEnablers";
import "./UserProfileCard.css"
import { RootState } from "../../root-redux/RootState";
import config from "../../config/config-dev";

export const UserProfileCard: React.FC<any> = (props: any) => {
  const stateProps = useSelector((state: RootState) => ({
    userDetails: state.userDetails,
    userGroups: state.userGroups.list,
    currentEnv: state.env.selectedEnvItem
  }));


  const userName = stateProps.userDetails.name
  const userEmail = stateProps.userDetails.username

  //getting the first and last word's initials out of the logged in user's name to show on anv bar
  const userInitials = (userName ? userName : '').split(" ").map((n, i, a) => i === 0 || i + 1 === a.length ? n[0] : null).join("");

  return (
    <Card
      className="cardcomponent"
      style={{
        boxShadow: "1px 2px 6px grey",
        height: "100%",
        overflow: "auto",
      }}
    >
      <div className="cardTop">
        <span>Microsoft</span>
      </div>
      <div className="cardMid">
        <div className="cardMidLeft">
          <div
            style={{
              fontWeight: "600",
              fontSize: "18px",
              backgroundColor: "#575757",
              color: "white",
              border: "2px solid #575757",
              borderRadius: "50%",
              width: "36px",
              height: "36px",
              lineHeight: "32px",
            }}
          >
            {userInitials}
          </div>
        </div>
        <div className="cardMidRight">
          <h6>{userName}</h6>
          <span>{userEmail}</span>
        </div>
      </div>
      <a style={{fontSize: "12px"}} href= {config.microsoftDataPrivacyURL}>Microsoft Data Privacy Notice</a>
    </Card>
  );
};