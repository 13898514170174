import { createAsyncThunk } from "@reduxjs/toolkit"
import { getRequest } from "../../services/utils/GetRequest"
import { appInsights } from "../../config/appInsights";
import { v4 as uuidv4 } from 'uuid';

export const getIspDomainMappingRequest = createAsyncThunk<any>('IspDomainMapping/GetIspDomainMappingRequest', async () => {
    var correlationId = uuidv4();
    appInsights.trackEvent({ name: "Sending request to IspDomainMapping/GetIspDomainMappingRequest data", properties: { correlationId: correlationId, page: "Domain Suppression" } });
    const response = await getRequest<string>('IspDomainMapping/GetIspDomainMappingRequest', correlationId);
    response.data === null ? appInsights.trackEvent({ name: "Data not fetched from IspDomainMapping/GetIspDomainMappingRequest api", properties: { data: JSON.stringify(response.data), correlationId: correlationId } }) : appInsights.trackEvent({ name: "Data fetched from IspDomainMapping/GetIspDomainMappingRequest api ", properties: {correlationId: correlationId } });
    return response.data;
});