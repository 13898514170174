import { NotAuthorizedPage } from "../no-authorization-page/NotAuthorizedPage";
import { Grid } from "../grid/grid";
import { InputFields } from "./input-fields/InputFields";
import { Spinner, SpinnerBetween } from "../../shared-components/loader/Spinner";

import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../root-redux/RootState";
import { Button, Icon, Text, Tab, Tabs, TabPanel, Dialog, MessageBar } from '../../common/HarmonyEnablers';
import { companyMappingRowObject } from "./CompanyMappingAttributes";
import { setIsOpenMsgBar, setCrmAccountId, setMappedCompany, setIdForEditDelete, setEditDeleteRow, clearNothingToShow, setIsDataToBeEditedFound, setSelectedCountry, setUnmatchedOnly, setErrorUploadMessage, clearErrorFetchMessage, clearErrorUploadMessage, clearSuccessMsg, setInputCompany, clearError, resetState, setEditData } from "./company-mapping-redux/CompanyMapping.redux";

import { setAuthorizedCountriesForUser } from "../../shared-components/user-language-country-data/UserLanguageCountryData.redux";
import { getAuthorizedCountryData } from "../../services/CommonApi/GetAuthorizedCountryData.api";
import { getMappingDataByCountry } from "../../services/CompanyMapping/GetMappingDataByCountry.api";
import { deleteMapping } from "../../services/CompanyMapping/Delete.api";
import { postExportToExcelMapping } from "../../services/CompanyMapping/ExportToExcelMapping.api";
import { postExportToExcelUnmatched } from "../../services/CompanyMapping/ExportToExcelMappingUnmatched.api";
import { uploadCompanyMappingBulkFile } from "../../services/CompanyMapping/PostUploadFileForCompanyMapping.api";
import { GetCompanyMappingRequest } from "../../services/CompanyMapping/GetCompanyMappingRequest.api";
import { RequestStatus } from '../../model/ServiceRequestStatus.model'
import { getExcelFile } from "../../services/CompanyMapping/GetExcelFile.api";
import { getCompanyMappingInputFile } from "../../services/CompanyMapping/GetCompanyMappingInputFile.api";
import { getCompanyMappingProcessedFile } from "../../services/CompanyMapping/GetCompanyMappingProcessedFile.api";
import { GetCompanyMappingTemplate } from "../../services/CompanyMapping/GetCompanyMappingTemplate.api";
import { deleteUnmatchedCompanyMapping } from "../../services/CompanyMapping/DeleteUnmatchedCompany.api";

import * as XLSX from "xlsx";
import { ValidateFileData, ValidateFile } from "../inputValidation/ExcelValidation";


import * as Constants from "../inputValidation/Constants";

import "./CompanyMapping.css";

export const CompanyMapping: React.FC = () => {

    const [notAuthorized, setNotAuthorized] = useState<boolean>(false);
    const [isPermissionsFetched, setIsPermissionsFetched] = useState<boolean>(false);
    const [isActiveTab, setIsActiveTab] = useState("manual");
    const [createMapping, setCreateMapping] = useState<boolean>(false);
    const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
    const [editBtnClicked, setEditBtnClicked] = useState(false);
    const [editMapping, setEditMapping] = useState<boolean>(false);
    const [activeTab, setActiveTab] = useState<string>("manual");
    const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false);
    const [toDeleteRowId, setToDeleteRowId] = useState(0);
    const [validationStatus, setValidationStatus] = useState("");

    const dispatch = useDispatch();
    const dispatchApp = useDispatch<AppDispatch>();

    const divRefForEdit = useRef<HTMLDivElement>(null);

    const { companyMappingRowData, companyMappingColumnData, nothingToShow, isOpenMsgBar, error, bulkCompanyMappingColumnData, bulkCompanyMappingRowData, selectedCountry, unmatchedOnly, getAuthorizedCountryDataStatus, getCompaniesStatus, saveDataStatus, getMappingDataByCountryStatus, getCompanyMappingRequestStatus, deleteMappingStatus, uploadCompanyMappingBulkFileStatus, editData, errorFetch, errorUpload, successMsg } = useSelector((state: RootState) => state.companyMapping);
    const { authorizedCountriesForUser } = useSelector((state: RootState) => state.userLanguageCountryData);
    const { isNavPanelOpen } = useSelector((state: RootState) => state.nav);

    const fetchAuthorizedCountry = async () => {
        resetErrorMessages();
        try {
            var currentPage = "Company Mapping";
            if (authorizedCountriesForUser.length === 0) {
                const response = await dispatchApp(getAuthorizedCountryData(currentPage));
                if (response.payload.Error) {
                    throw new Error(response.payload.Error);
                }
                else {
                    const authorizedCountries = response.payload.map((countryData: any) => ({
                        DisplayName: countryData.DisplayName,
                        Value: countryData.Value,
                        IsEnabled: true,
                    }));
                    dispatch(setAuthorizedCountriesForUser(authorizedCountries));
                    dispatch(setSelectedCountry(authorizedCountries[0]));
                }
            }
            else {
                dispatch(setSelectedCountry(authorizedCountriesForUser[0]));
            }
            resetMessagesAfterFiveSeconds();
        } catch (err) {
            return err;
        }
    }

    //On click of edit button  set the values in the edit window
    const handleEditButtonClick = (row: any) => {
        if (divRefForEdit.current) {
            divRefForEdit.current.scrollIntoView({ behavior: 'smooth' });
        }
        resetErrorMessages();
        dispatch(setIsDataToBeEditedFound(true));
        dispatch(setIdForEditDelete(row.data.Id));
        dispatch(setEditDeleteRow(row.data));
        dispatch(setMappedCompany(row.data.MatchCompanyName ? row.data.MatchCompanyName : ""));
        dispatch(setInputCompany(row.data.InputCompanyName ? row.data.InputCompanyName : ""));
        dispatch(setCrmAccountId(row.data.CRMAccountId ? row.data.CRMAccountId : ""));
        setCreateMapping(false);
        setEditMapping(true);
        dispatch(setEditData(true));
    }

    const fetchCompanyMappingData = async (Country: string, loadedRows: number, rows: number, unmatchedOnly: boolean) => {
        resetErrorMessages();
        try {
            await dispatchApp(getMappingDataByCountry({ Country: Country, loadedRows: loadedRows, rows: rows, unmatchedOnly: unmatchedOnly }));
            resetMessagesAfterFiveSeconds();
        }
        catch (err) {
            return err;
        }
    }

    const fetchBulkUploadData = async () => {
        resetErrorMessages();
        try {
            await dispatchApp(GetCompanyMappingRequest({}));
            resetMessagesAfterFiveSeconds();
        }
        catch (err) {
            return err;
        }
    }

    const handleDelete = async () => {
        let id: number = toDeleteRowId;
        let payload = { id: id };
        unmatchedOnly ? await dispatchApp(deleteUnmatchedCompanyMapping(payload)) : await dispatchApp(deleteMapping(payload));
        await dispatchApp(getMappingDataByCountry({ Country: selectedCountry.DisplayName, loadedRows: 0, rows: 10, unmatchedOnly: unmatchedOnly }));
        resetMessagesAfterFiveSeconds();
    }

    const handleDeleteButtonClick = async (row: companyMappingRowObject) => {
        resetErrorMessages();
        setIsOpenDeleteDialog(true);
        setToDeleteRowId(row.id);
    }

    const handleDownload = async (rowid: any, type: any, fileName: any) => {
        resetErrorMessages();
        try {
            const payload = { "fileName": fileName };
            if (type === "inputfile") {
                await dispatchApp(getCompanyMappingInputFile(payload));

            }
            if (type === "processedfile") {
                await dispatchApp(getCompanyMappingProcessedFile(payload));
            }
        }
        catch (err) {
            return err;
        }
    }

    const handleUnmatchedCompaniesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(setUnmatchedOnly(event.target.checked));
    }

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let files = event.target;
        let fileList: FileList | null = event.target.files;
        let filesArray: File[] = [];
        let validationStatus = "";
        if (fileList) {
            filesArray = Array.from(fileList);
            for (let i = 0; i < filesArray.length; i++) {
                const file: File = filesArray[i];
                let fileValidationStatus = ValidateFile(file);
                if (fileValidationStatus !== Constants.INPUT_VALID) {
                    filesArray.splice(i, 1); // remove file from filesArray
                    validationStatus += "Not able to upload " + file.name + " because " + fileValidationStatus + "\n";
                    i--;
                }
            }
        }

        const newFilesArray: any[] = [];
        let correctFiles: number = 0;
        let incorrectFiles: number = 0;
        for (let i = 0; i < filesArray.length; i++) {
            const file = filesArray[i];
            const reader = new FileReader();
            reader.onload = async (event) => {
                const bstr = event.target!.result;
                let workbook;
                try {
                    workbook = XLSX.read(bstr, { type: 'binary' });
                } catch (e) {
                    validationStatus += "Not able to upload " + file.name + " because " + Constants.INPUT_COULD_NOT_PARSE + "\n";
                    incorrectFiles++;
                    return;
                }
                const fileDataValidationStatus = ValidateFileData(workbook, "CompanyMapping");
                if (fileDataValidationStatus !== Constants.INPUT_VALID) {
                    validationStatus += "Not able to upload " + file.name + " because " + fileDataValidationStatus + "\n";
                    incorrectFiles++;
                } else {
                    newFilesArray.push(file);
                    correctFiles++;
                }
                if (incorrectFiles + correctFiles === filesArray.length) {
                    setSelectedFiles(newFilesArray);
                    console.log("validationStatus", validationStatus);
                    setValidationStatus(validationStatus);
                    if (validationStatus.length > 0) {
                        dispatch(setErrorUploadMessage(validationStatus));
                    }
                    files.value = "";
                }
            };
            reader.readAsBinaryString(file);
        }
        setIsOpenMsgBar(true);
    };

    const handleClear = (event: any): void => {
        resetErrorMessages();
        if (event.target) {
            (event.target as HTMLFormElement).reset();
        }
        setSelectedFiles([]);
    };


    const uploadToExcel = async () => {
        resetErrorMessages();
        try {
            const payload = { "country": selectedCountry.DisplayName };
            const res = unmatchedOnly ? await dispatchApp(postExportToExcelUnmatched(payload)) : await dispatchApp(postExportToExcelMapping(payload));
            const filename = res.payload.fileName;
            var payloadForExcel = { "fileName": filename };
            await dispatchApp(getExcelFile(payloadForExcel));
            resetMessagesAfterFiveSeconds();
        }
        catch (error) {
            return error;
        }
    };


    const submitBulkFileUpload = async (event: any) => {
        event.preventDefault();
        event.target.reset();
        var reader = new FileReader();
        var tempSelectedFiles = selectedFiles;
        setSelectedFiles([]);
        if (tempSelectedFiles.length) {
            var fileData = new FormData();
            for (let i = 0; i < tempSelectedFiles.length; i++) {
                fileData.append(selectedFiles[i].name, selectedFiles[i], selectedFiles[i].name);
            }
            let payload = { fileData: fileData, country: selectedCountry.DisplayName };
            await dispatchApp(uploadCompanyMappingBulkFile(payload));
            resetMessagesAfterFiveSeconds();
            if (event.target) {
                (event.target as HTMLFormElement).reset();
            }
            fetchBulkUploadData();
        }

    };

    const downloadTemplate = async () => {
        await dispatchApp(GetCompanyMappingTemplate());

    };

    const handleCloseSuccessMsg = (): void => {
        dispatch(clearSuccessMsg());
    };

    const handleCloseUploadError = (): void => {
        dispatch(clearErrorUploadMessage());
    };


    const handleCloseFetchError = (): void => {
        dispatch(clearErrorFetchMessage());
    };

    const resetErrorMessages = (): void => {
        dispatch(clearError());
        dispatch(clearErrorUploadMessage());
        dispatch(clearErrorFetchMessage());
    };

    const resetMessagesAfterFiveSeconds = (): void => {
        setTimeout(() => {
            dispatch(clearSuccessMsg());
        }, 5000);
    };
    const handleAuthCountryDropdownChange = (event: any) => {
        dispatch(setSelectedCountry({ DisplayName: event.target.value, Value: event.target.value, IsEnabled: true }));// = event.target.value;
        setCreateMapping(false);
        setEditBtnClicked(false);
    };

    const isFileSelected = selectedFiles.length > 0;

    useEffect(() => {
        if (selectedCountry.DisplayName !== "" && isActiveTab === "manual") {
            fetchCompanyMappingData(selectedCountry.DisplayName, 0, 10, unmatchedOnly);
        }
    }, [selectedCountry, unmatchedOnly]);

    useEffect(() => {
        var userGroupsFromStorage = window.localStorage.getItem("user_groups");
        if (userGroupsFromStorage) {
            var parsedJSONgroups = JSON.parse(userGroupsFromStorage);
            if (parsedJSONgroups.length > 0 && (parsedJSONgroups.includes("PortalWideUser") || parsedJSONgroups.includes("SuperAdmin"))) {
                fetchAuthorizedCountry();
            }
            else {
                setNotAuthorized(true);
            }
            setIsPermissionsFetched(true);
        }
    }, []);

    useEffect(() => {
        setCreateMapping(false);
        dispatch(setMappedCompany(""))
        setEditBtnClicked(false);
        dispatch(setCrmAccountId(""));
        dispatch(clearErrorFetchMessage());
        dispatch(clearErrorUploadMessage());
        dispatch(clearSuccessMsg());
    }, [isActiveTab]);

    useEffect(() => {
        return () => {
            dispatch(resetState());
        };
    }, [dispatch]);

    return (
        isPermissionsFetched ?
            notAuthorized ? <NotAuthorizedPage /> :
                <div className={isNavPanelOpen ? "company-mapping-wrapper-collapsed" : "company-mapping-wrapper"}>
                    <div className={isNavPanelOpen ? "company-mapping-header-collapsed" : "company-mapping-header"}>
                        <div><Text role="heading" aria-level={1} appearance="heading-2" className="company-mapping-heading">Company Mapping</Text></div>
                        <Tabs active-id={isActiveTab} style={{ marginTop: "10px" }}>
                            <Tab id="manual" onClick={() => { setIsActiveTab("manual"); setCreateMapping(false); setEditMapping(false); dispatch(setMappedCompany(""));dispatch(setCrmAccountId("")); dispatch(setIsDataToBeEditedFound(false)); dispatch(clearNothingToShow()); fetchCompanyMappingData(selectedCountry.DisplayName, 0, 10, unmatchedOnly) }}
                                onKeyDown={(e) => { if (e.key === "Enter") { setIsActiveTab("manual"); setCreateMapping(false); setEditMapping(false); dispatch(setMappedCompany(""));dispatch(setCrmAccountId("")); dispatch(setIsDataToBeEditedFound(false)); dispatch(clearNothingToShow()); fetchCompanyMappingData(selectedCountry.DisplayName, 0, 10, unmatchedOnly) } }}
                            >Manual</Tab>
                            <Tab id="bulk" onClick={() => { setIsActiveTab("bulk"); fetchBulkUploadData(); dispatch(clearNothingToShow()); }}
                                onKeyDown={(e) => { if (e.key === "Enter") { setIsActiveTab("bulk"); fetchBulkUploadData(); dispatch(clearNothingToShow()) } }}>Bulk</Tab>

                            <TabPanel>

                                <div className={isNavPanelOpen ? "company-mapping-manual-collapsed" : "company-mapping-manual"}>
                                    <div className={isNavPanelOpen ? "company-mapping-select-country-collapsed" : "company-mapping-select-country"} ref={divRefForEdit}>
                                        <Text role="heading" aria-level={3} appearance="heading-4" className="company-mapping-heading">Select Country/Region</Text>
                                        <select className="company-mapping-authorized-countries" id="authorizedCountryDropdownManual" value={selectedCountry.DisplayName} onChange={handleAuthCountryDropdownChange} aria-label="Select a Country / Region" aria-labelledby="authorizedCountryDropdownManual">

                                            {authorizedCountriesForUser?.map(country => (
                                                <option className="company-mapping-authorized-countries-menu" key={country.Value} aria-selected={country.DisplayName === selectedCountry.DisplayName ? "true" : "false"}
                                                    value={country.DisplayName} onClick={handleAuthCountryDropdownChange}

                                                >{country.DisplayName}
                                                </option>
                                            ))
                                            }
                                        </select>
                                        {
                                            getAuthorizedCountryDataStatus === RequestStatus.loading && <Spinner />
                                        }
                                    </div>
                                    {activeTab === "manual" && <> <Button appearance="primary" className={isNavPanelOpen ? 'company-mapping-create_btn-collapsed' : 'company-mapping-create_btn'} onClick={() => { setEditMapping(false); setCreateMapping(true); dispatch(setEditData(false)); setEditDeleteRow([]); dispatch(setMappedCompany("")); dispatch(setInputCompany(""));dispatch(setCrmAccountId("")); resetErrorMessages() }}>
                                        <Icon name="contact" slot="start" />
                                        Create New Mapping
                                    </Button></>}



                                </div>
                                <div className='company-mapping-checkbox'>
                                    <input type="checkbox" id="unmatchedOnly" name="unmatchedOnly" value="unmatchedOnly" onChange={(e) => { handleUnmatchedCompaniesChange(e); setCreateMapping(false); setEditMapping(false) }} defaultChecked={false} onKeyDown={(e: any) => { if (e.key === 'Enter') { e.preventDefault(); e.currentTarget.checked = !e.currentTarget.checked; handleUnmatchedCompaniesChange(e) } }} />
                                    <label htmlFor="unmatchedOnly">Show Only Unmatched Companies</label>
                                </div>
                                {createMapping && <Text role="heading" aria-level={2} appearance="heading-3" className="company-mapping-heading" style={{ "marginBottom": "20px" }}>Create Mapping Entry:</Text>}
                                {createMapping && <InputFields createNewMapping editBtnClicked setCreateMapping={setCreateMapping} setEditMapping={setEditMapping} editData={editData} setEditData={setEditData} setEditBtnClicked={setEditBtnClicked} companyMappingRowData={companyMappingRowData} />}
                                {editMapping && <Text role="heading" aria-level={2} appearance="heading-3" className="company-mapping-heading" style={{ "marginBottom": "20px" }}>Edit Mapping Entry:</Text>}
                                {editMapping && <InputFields createNewMapping editBtnClicked setCreateMapping={setCreateMapping} setEditMapping={setEditMapping} editData={editData} setEditData={setEditData} setEditBtnClicked={setEditBtnClicked} companyMappingRowData={companyMappingRowData} />}
                                {(getAuthorizedCountryDataStatus === RequestStatus.failed || getMappingDataByCountryStatus === RequestStatus.failed || getCompanyMappingRequestStatus === RequestStatus.failed || getCompaniesStatus === RequestStatus.failed || saveDataStatus === RequestStatus.failed || deleteMappingStatus === RequestStatus.failed || uploadCompanyMappingBulkFileStatus === RequestStatus.failed) && (<MessageBar appearance="error" open={isOpenMsgBar} onHeAfterHide={() => dispatch(setIsOpenMsgBar(false))} style={{ marginBottom: '1rem' }}> {error}</MessageBar>)}
                            </TabPanel>
                            <TabPanel>
                                <div className={isNavPanelOpen ? "company-mapping-select-country-collapsed" : "company-mapping-select-country"} style={{ "marginTop": "20px", "marginBottom": "20px" }}>
                                    <Text role="heading" aria-level={3} appearance="heading-4" className="company-mapping-heading">Select Country/Region</Text>
                                    <select className="company-mapping-authorized-countries" id="authorizedCountryDropdownBulk" value={selectedCountry.DisplayName} onChange={handleAuthCountryDropdownChange} aria-label="Select Country / Region" aria-labelledby="authorizedCountryDropdownBulk">

                                        {authorizedCountriesForUser?.map(country => (
                                            <option className="company-mapping-authorized-countries-menu" key={country.Value} aria-selected={country.DisplayName === selectedCountry.DisplayName ? "true" : "false"}
                                                value={country.DisplayName} onClick={handleAuthCountryDropdownChange}

                                            >{country.DisplayName}
                                            </option>
                                        ))
                                        }
                                    </select>
                                    {
                                        getAuthorizedCountryDataStatus === RequestStatus.loading && <Spinner />
                                    }
                                </div>
                                <div>
                                    <Text role="heading" aria-level={3} appearance="heading-4" className="company-mapping-heading">Upload File</Text>
                                    <Text appearance="heading-6" className="company-mapping-heading">Please select a file to upload. The standard template can be downloaded here: <a href="#" onClick={downloadTemplate} style={{ color: "#0b62e4" }}>Download Template</a></Text>
                                </div>
                                <form onSubmit={submitBulkFileUpload} onReset={handleClear}>
                                    <div className={isNavPanelOpen ? "input-field-collapsed" : "input-field"}>
                                        <div className={isNavPanelOpen ? "input-text-collapsed" : "input-text"}>
                                            <input type="text" placeholder="Select files to upload" value={selectedFiles?.length > 0 ? selectedFiles.map(file => file.name).join(", ") : ""} onClick={() => { resetErrorMessages(); document.getElementById("file-input")!.click(); }}
                                                readOnly
                                                onKeyDown={(e) => { if (e.key === "Enter") { resetErrorMessages(); document.getElementById("file-input")!.click() } }} />
                                        </div>
                                        <div className="input-file">
                                            <input type="file" id="file-input" onChange={handleFileChange} onClick={() => { resetErrorMessages(); document.getElementById("file-input")!.click() }} multiple hidden />
                                            <label htmlFor="file-input" className="custom-button" onKeyDown={(e) => { if (e.key === 'Enter') { resetErrorMessages(); document.getElementById("file-input")?.click() } }} tabIndex={0} >Select</label>
                                        </div>
                                    </div>
                                    <div>
                                        <Button className="button" type="submit" appearance="primary" disabled={!isFileSelected} tabIndex={selectedFiles.length > 0 ? 0 : -1}>
                                            <Text>Submit</Text>
                                        </Button>
                                        <Button type="reset" className="button" disabled={!isFileSelected} tabIndex={selectedFiles.length > 0 ? 0 : -1}>
                                            <Text>Clear</Text>
                                        </Button>
                                    </div>
                                </form>

                            </TabPanel>
                        </Tabs>
                    </div>

                    {successMsg !== undefined && (
                        <div className="company-mapping-success">
                            <span><p>{successMsg}</p></span>
                            <Icon name="chromeclose" onClick={handleCloseSuccessMsg} onKeyDown={(e) => { if (e.key === 'Enter') { handleCloseSuccessMsg() } }} className="company-mapping-close-icon" tabIndex={0}></Icon>
                        </div>
                    )}
                    {errorUpload !== undefined && (
                        <div className="company-mapping-error">
                            <span><p>{errorUpload}</p></span>
                            <Icon name="chromeclose" onClick={handleCloseUploadError} onKeyDown={(e) => { if (e.key === 'Enter') { handleCloseUploadError() } }} className="company-mapping-close-icon" tabIndex={0}></Icon>
                        </div>
                    )}
                    {errorFetch !== undefined && (
                        <div className="company-mapping-error">
                            <span><p>{errorFetch}</p></span>
                            <Icon name="chromeclose" onClick={handleCloseFetchError} onKeyDown={(e) => { if (e.key === 'Enter') { handleCloseFetchError() } }} className="company-mapping-close-icon" tabIndex={0}></Icon>
                        </div>
                    )}

                    {isActiveTab === "manual" && getMappingDataByCountryStatus === RequestStatus.loading && <SpinnerBetween />}
                    {isActiveTab === "bulk" && getCompanyMappingRequestStatus === RequestStatus.loading && <SpinnerBetween />}
                    {isActiveTab === "manual" && getMappingDataByCountryStatus === RequestStatus.succeeded && companyMappingRowData.length > 0 && <Grid columnData={companyMappingColumnData} rowData={companyMappingRowData} handleDownload={handleDownload} handleDeleteButtonClick={handleDeleteButtonClick} handleEditButtonClick={handleEditButtonClick} page="CompanyMapping" isExcelUpload={true} uploadToExcel={uploadToExcel} sortType="DateUpdated" isAscending={false}></Grid>}
                    {isActiveTab === "bulk" && getCompanyMappingRequestStatus === RequestStatus.succeeded && bulkCompanyMappingRowData.length > 0 && <Grid columnData={bulkCompanyMappingColumnData} rowData={bulkCompanyMappingRowData} handleDownload={handleDownload} page="CompanyMapping" sortType="UploadedOn" isAscending={false}></Grid>}
                    {
                        nothingToShow !== undefined && (
                            <Text appearance="heading-3">{nothingToShow}</Text>
                        )
                    }
                    <Dialog
                        no-header
                        className="dialog-no-header"
                        open={isOpenDeleteDialog}
                        onHeAfterHide={
                            ({ target, currentTarget }) => (target === currentTarget ? setIsOpenDeleteDialog(false) : null)
                        }
                    >
                        <p>
                            Do you really want to delete this company mapping?
                        </p>

                        <Button slot="footer" appearance="primary" onClick={() => { setIsOpenDeleteDialog(false); handleDelete() }}>
                            Ok
                        </Button>
                        <Button slot="footer" onClick={() => { setIsOpenDeleteDialog(false); setToDeleteRowId(0) }}>
                            Close
                        </Button>
                    </Dialog>
                </div>
            :
            <></>
    )
}

