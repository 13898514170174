import { createAsyncThunk } from "@reduxjs/toolkit"
import { getRequest } from "../utils/GetRequest"
import { appInsights } from "../../config/appInsights";
import { v4 as uuidv4 } from 'uuid';

export const getJobRoleData = createAsyncThunk<any, any>('JobTitleMapping/GetJobRoleData', async (currentJobRole: string) => {
  var correlationId = uuidv4();
  appInsights.trackEvent({ name: "Sending request to JobTitleMapping/GetJobRoleData data", properties: { correlationId: correlationId, page: "Job Title Mapping" } });
  const response = await getRequest<any>(`JobTitleMapping/GetJobRoleData?currentJobRole=${currentJobRole}`, correlationId)
  response.data === null ? appInsights.trackEvent({ name: "Data not fetched from  JobTitleMapping/GetJobRoleData api", properties: { data: JSON.stringify(response.data), correlationId: correlationId } }) : appInsights.trackEvent({ name: "Data fetched from  JobTitleMapping/GetJobRoleData api", properties: { correlationId: correlationId } });
  return response.data
})


