export const CrmStatusFilter: any[] = [
    { field: "Active", content: "Active" },
    { field: "Inactive", content: "Inactive" }
  ];

  export const CertifiedStatusFilter: any[] = [
    { field: "All", content: "All" },
    { field: "Certified", content: "Certified" },
    { field: "NotCertified", content: "Not Certified" },
    
  ];  