import { createAsyncThunk } from "@reduxjs/toolkit";
import { appInsights } from "../../config/appInsights";
import { getRequestForExcel } from "../../services/utils/GetRequest";
import { DownloadExcelData } from "../../services/utils/ExcelHandler";
import { v4 as uuidv4 } from 'uuid';

export const getDomainSupressionInputFile = createAsyncThunk<any, any>('IspDomainMapping/GetIspDomainMappingInputFile', async (payload: any) => {
  var correlationId = uuidv4();
  appInsights.trackEvent({ name: "Sending request to IspDomainMapping/GetIspDomainMappingInputFile data", properties: { correlationId: correlationId, page: "Domain Suppression" } });
  await getRequestForExcel<string>(`IspDomainMapping/GetIspDomainMappingInputFile/${payload.fileName}`, correlationId)
    .then(response => {
      DownloadExcelData(response);
      appInsights.trackEvent({ name: "Data fetched from IspDomainMapping/GetIspDomainMappingInputFile api", properties: { correlationId: correlationId } })
    }).catch(error => {
      appInsights.trackEvent({ name: "Data fetched from IspDomainMapping/GetIspDomainMappingInputFile api", properties: { error: error, correlationId: correlationId } })
    });
})
