import { createAsyncThunk } from "@reduxjs/toolkit"
import { getRequest } from "../utils/GetRequest"
import { appInsights } from "../../config/appInsights";
import { v4 as uuidv4 } from 'uuid';

export const getJobRoleData = createAsyncThunk<any, any>('JobTitleMappingV2/GetJobRoleData', async (currentJobRole: string) => {
  var correlationId = uuidv4();
  appInsights.trackEvent({ name: "Sending request to JobTitleMappingV2/GetJobRoleData data", properties: { correlationId: correlationId, page: "Job Title MappingV2" } });
  const response = await getRequest<any>(`JobTitleMappingV2/GetJobRoleData?currentJobRole=${currentJobRole}`, correlationId)
  response.data === null ? appInsights.trackEvent({ name: "Data not fetched from  JobTitleMappingV2/GetJobRoleData api", properties: { data: JSON.stringify(response.data), correlationId: correlationId } }) : appInsights.trackEvent({ name: "Data fetched from JobTitleMappingV2/GetJobRoleData api", properties: { correlationId: correlationId } });
  return response.data
})


