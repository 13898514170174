import { createAsyncThunk } from "@reduxjs/toolkit"
import { getRequest } from "../utils/GetRequest"
import { appInsights } from "../../config/appInsights";
import { v4 as uuidv4 } from 'uuid';

export const getCompanies = createAsyncThunk<any, any>('CompanyMapping/GetCompanies', async (payload: any) => {
  var correlationId = uuidv4();
  appInsights.trackEvent({ name: "Sending request to get companies data", properties: { payload: JSON.stringify(payload), correlationId: correlationId, page: "Company Mapping" } });
  const response = await getRequest(`CompanyMapping/GetCompanies?currentCompanyName=${payload.currentCompanyName}&selectedCountry=${payload.selectedCountry}`, correlationId)
  response.data === null ? appInsights.trackEvent({ name: "Data not fetched from GetCompanies api",  properties: { correlationId: correlationId }  }) : appInsights.trackEvent({ name: "Data fetched from GetCompanies api", properties: { correlationId: correlationId } });
  return response.data
})


