import { createAsyncThunk } from "@reduxjs/toolkit"
import { appInsights } from "../../config/appInsights";
import { postRequest } from "../utils/PostRequest";
import { v4 as uuidv4 } from 'uuid';

export const getAccountDetails = createAsyncThunk<any, any>('DomainMapping/GetAccountDetails', async (payload: any) => {
  var correlationId = uuidv4();
  appInsights.trackEvent({ name: "Sending request to DomainMapping/GetAccountDetails data", properties: { payload: JSON.stringify(payload), correlationId: correlationId, page: "Domain Mapping" } });
  const response = await postRequest(`DomainMapping/GetAccountDetails`, payload, correlationId)
  response.data === null ? appInsights.trackEvent({ name: "Data not fetched from DomainMapping/GetAccountDetails api", properties: { data: JSON.stringify(response.data), correlationId: correlationId } }) : appInsights.trackEvent({ name: "Data fetched from DomainMapping/GetAccountDetails api", properties: { correlationId: correlationId } });
  return response.data
})


