import { Spinner } from '../../../shared-components/loader/Spinner';

import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../root-redux/RootState';
import { clearCompanyList, setMsxAccountNumber, setMsSalesAccountId, setAccountName, setIsDataToBeEditedFound, setViewCompanySuggestions, setMappedCompany, setError, clearSuccessMessage, clearError } from "../domain-mapping-redux/DomainMapping.redux";

import { Button, Menu, MenuItem, Icon, SearchBox } from '../../../common/HarmonyEnablers';
import { createDomainMappingSingle } from '../../../services/DomainMapping/PostCreateDomainMappingSingle.api';
import { updateDomainMappingSingle } from '../../../services/DomainMapping/PostUpdateDomainMappingSingle.api';
import { getAccountDetails } from '../../../services/DomainMapping/GetAccountDetails.api'
import { setIsOpenMsgBar, setResponse, setResStatus } from '../domain-mapping-redux/DomainMapping.redux';
import { getDomainMappingCount } from "../../../services/DomainMapping/GetDomainMappingCount.api";
import { RequestStatus } from '../../../model/ServiceRequestStatus.model';
import { getUnmappedDomainsSuggestions } from '../../../services/DomainMapping/GetUnmappedDomainMappingSuggestions.api';

import { inputFieldsProps } from '../DomainMappingAttributes';

export const InputFields: React.FC<inputFieldsProps> = ({ setCreateMapping, setEditMapping, setEditData, setIsUnmapped, editData, isUnmapped, fetchUnmappedDomains, GetPagedDomainMappingsByCountry }) => {
    const [createDomainValue, setCreateDomainValue] = useState<string>("");
    const dispatch = useDispatch();
    const dispatchApp = useDispatch<AppDispatch>();
    const { mappedCompanyList, mappedCompany, error, viewCompanySuggestions, activeTab, getUnmappedDomainsSuggestionsStatus, isSuggestionsAvailable, getAccountDetailsStatus, isCertifiedUser, selectedCountry, msSalesAccountId, certifyDomain, msxAccountNumber, accountName, idForEditDelete, editDomainValue, getAuthorizedCountryDataStatus, isOpenMsgBar, serverSidePagination } = useSelector((state: RootState) => state.domainMapping);
    const { isNavPanelOpen } = useSelector((state: RootState) => state.nav);
    const SearchTerm_FETCHALL = '<ALL>';

    const handleMappedCompanyName = async () => {
        // dispatch(setMappedCompany(event.target.value));
        if (mappedCompany.length > 1) {
            dispatch(clearError());
            const payload = {
                accountId: mappedCompany.toString(),
                country: selectedCountry.Value.toString()
            }
            await dispatchApp(getAccountDetails(payload));
        }
    }

    const handleMappedCompanyNameChange = async (event: any) => {
        dispatch(setMappedCompany(event.target.value));
    }

    const handleAvailableSuggestionsSelection = async (event: any, domain: any) => {
        dispatch(setMsSalesAccountId(domain.MssTpid));
        dispatch(setMsxAccountNumber(domain.AccountId));
        dispatch(setAccountName(domain.Company_Name));
        dispatch(setMappedCompany(domain.Suggestion));
        dispatch(clearCompanyList());
    }
    const handleClearAccountId = async (event: any) => {
        dispatch(setMsxAccountNumber(""));
        dispatch(setMsSalesAccountId(""));
        dispatch(setAccountName(""));
        dispatch(setMappedCompany(""));
    }

    const handleInputDomainName = async (event: any) => {
        setCreateDomainValue(event.target.value);
    }

    const getLatestData = async () => {
        const payload = {
            country: selectedCountry.Value,
            SearchTerm: SearchTerm_FETCHALL,
            companyStatusFilter: serverSidePagination.companyStatusFilter,
            certifiedFlagFilter: serverSidePagination.certifiedStatusFilter

        }
        dispatchApp(getDomainMappingCount(payload));
        GetPagedDomainMappingsByCountry(0, "", serverSidePagination.sortType, serverSidePagination.sortOrder, serverSidePagination.companyStatusFilter, serverSidePagination.certifiedStatusFilter);
    }

    const handleViewSuggestedCompaniesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(setViewCompanySuggestions(event.target.checked));
        dispatch(setMsSalesAccountId(""));
        dispatch(setMsxAccountNumber(""));
        dispatch(setAccountName(""));
        dispatch(setMappedCompany(""));
        if (event.target.checked) {
            var payload = {
                country: selectedCountry.Value,
                domain: editData ? editDomainValue : createDomainValue
            }
            dispatchApp(getUnmappedDomainsSuggestions(payload));
        }

    }
    const resetMessagesAfterFiveSeconds = (): void => {
        setTimeout(() => {
            dispatch(clearSuccessMessage());
        }, 5000);
    };
    const handleFormSubmit = async (event: any) => {
        event.preventDefault();

        if (msxAccountNumber === "" && mappedCompany === "") {
            setIsOpenMsgBar(true);
            dispatch(setResponse("error"));
            dispatch(setResStatus("Please fill all the fields"));
            return;
        }

        var certifyDomain = document.getElementById("certify-domain") as HTMLInputElement;
        var payload = {};

        if (editData && activeTab === "manual") {
            payload = {
                "country": selectedCountry.Value,
                "crmId": msxAccountNumber === "" ? null : msxAccountNumber,
                "mssalesId": msSalesAccountId === "" ? null : msSalesAccountId,
                "domain": editDomainValue,
                "id": idForEditDelete,
                "isCertified": certifyDomain ? certifyDomain.checked : false
            };
            await dispatchApp(updateDomainMappingSingle(payload));
        } else {
            payload = {
                "country": selectedCountry.Value,
                "crmId": msxAccountNumber === "" ? null : msxAccountNumber,
                "mssalesId": msSalesAccountId === "" ? null : msSalesAccountId,
                "domain": activeTab === "unmapped" ? editDomainValue : createDomainValue,
                "isCertified": certifyDomain ? certifyDomain.checked : false
            };
            await dispatchApp(createDomainMappingSingle(payload));
        }




        if (isUnmapped === true) {
            fetchUnmappedDomains();
        }
        else
            await getLatestData();

        resetMessagesAfterFiveSeconds();
        setCreateMapping(false);
        setEditMapping(false);
        dispatch(setMsxAccountNumber(""));
        dispatch(setMsSalesAccountId(""));
        dispatch(setAccountName(""));
        dispatch(setMappedCompany(""));
        dispatch(setIsDataToBeEditedFound(false));
    };


    return (
        <>
            <form className={isNavPanelOpen ? "domain-mapping-form-collapsed" : "domain-mapping-form"} onSubmit={(e) => e.preventDefault()} onLoad={handleClearAccountId} >
                <div className={isNavPanelOpen ? "domain-mapping-input-fields-collapsed" : "domain-mapping-input-fields"}>
                    <div>
                        <label htmlFor="domainName">Input Domain Name</label>
                        {editData && <input type="text" placeholder='i.e. bing.com' id="domainName" value={editDomainValue} className={isNavPanelOpen ? viewCompanySuggestions ? 'marginLeftForDomainName-collapsed' : 'nomargin' : viewCompanySuggestions ? 'marginLeftForDomainName' : 'nomargin'} disabled />}
                        {!editData && <input type="text" placeholder='i.e. bing.com' id="domainName" onChange={(event) => { handleInputDomainName(event) }} autoComplete='off' required />}

                    </div>
                </div>

                {activeTab === "unmapped" && isSuggestionsAvailable &&
                    <>

                        <div className={isNavPanelOpen ? 'view-suggested-companies-checkbox-collapsed' : 'view-suggested-companies-checkbox'}>
                            <input type="checkbox" id="view-suggested-companies" name="view-suggested-companies" value="view-suggested-companies" onChange={handleViewSuggestedCompaniesChange} className={isNavPanelOpen ? viewCompanySuggestions ? 'marginLeftForSuggestedCompaniesCheckbox' : 'nomargin' : viewCompanySuggestions ? 'marginLeftForSuggestedCompaniesCheckbox' : 'nomargin'} defaultChecked={false} />
                            <label htmlFor="view-suggested-companies">View Suggested Companies</label>
                        </div>
                    </>
                }
                <div className={isNavPanelOpen ? "domain-mapping-input-fields-collapsed" : "domain-mapping-input-fields"}>
                    {viewCompanySuggestions && <>

                        <div>
                            <label htmlFor="mapped-company-name">Suggested Companies</label>
                            <div>
                                <input type="text" id="mapped-company-name" disabled value={mappedCompany} autoComplete='off' required />
                                {<Menu className='menu'>

                                    {
                                        mappedCompanyList.map((domain: any) => (<MenuItem className="menu-item" onClick={(event) => { handleAvailableSuggestionsSelection(event, domain) }}>{domain.Suggestion})</MenuItem>))
                                    }
                                </Menu>}
                                {getUnmappedDomainsSuggestionsStatus === RequestStatus.loading && <div style={{ "display": 'flex', "justifyContent": 'center' }}>
                                    <div style={{ "width": '20px' }}>
                                        <Spinner />
                                    </div>
                                </div>}
                            </div>
                        </div>
                    </>

                    }
                    {/* {!viewCompanySuggestions && <div>

                        <label htmlFor="account-id">Input Account Id</label>
                        <div>
                            <SearchBox placeholder='i.e. CRM ID or TPID' onHeSearch={(event) => handleMappedCompanyName(event)} onHeClear={(event) => handleClearAccountId(event)} value={mappedCompany} className={isNavPanelOpen ? 'marginLeftForSearchPane-collapsed' : 'marginLeftForSearchPane'} required ></SearchBox>
                            {
                                getAccountDetailsStatus === RequestStatus.loading && <div style={{ "display": 'flex', "justifyContent": 'center' }}>
                                    <div style={{ "width": '20px' }}>
                                        <Spinner />
                                    </div>
                                </div>
                            }
                            {
                                ((getAccountDetailsStatus === 2) && error !== undefined) && <div style={{ "color": "red", "display": 'flex', "marginLeft": "25px" }}>{error}</div>
                            }
                        </div>
                    </div>
                    } */}
                    {!viewCompanySuggestions && <div>

                        <label htmlFor="account-id">Input Account Id</label>
                        <div>
                            <div style={{ display: "flex" }}>
                                <input type='text' placeholder='i.e. CRM ID or TPID' value={mappedCompany} onChange={(event) => handleMappedCompanyNameChange(event)} onKeyDown={(e) => { if (e.key === 'Enter') { handleMappedCompanyName() } }} className={isNavPanelOpen ? 'marginLeftForSearchPane-collapsed' : 'marginLeftForSearchPane'} required ></input>
                                <Button appearance='primary' style={{ "width": "35px", "height": "35px" }} onClick={handleMappedCompanyName}><Icon name='search' role='button' aria-label='Search by CRMId of TPId' /></Button>
                            </div>
                            {
                                getAccountDetailsStatus === RequestStatus.loading && <div style={{ "display": 'flex', "justifyContent": 'center' }}>
                                    <div style={{ "width": '20px' }}>
                                        <Spinner />
                                    </div>
                                </div>
                            }

                            {
                                ((getAccountDetailsStatus === 2) && error !== undefined) && mappedCompany.length > 1 && <div style={{ "color": "#d90000", "display": 'flex', "marginLeft": "25px" }}>{error}</div>
                            }
                        </div>
                    </div>
                    }
                    <div>
                        <label htmlFor="crm-id">CRM ID</label>
                        <input type="text" id="crm-id" value={msxAccountNumber} className={isNavPanelOpen ? viewCompanySuggestions ? 'nomargin' : 'marginLeftForCrmId-collapsed' : viewCompanySuggestions ? 'nomargin' : 'marginLeftForCrmId'} disabled />
                    </div>
                    <div>
                        <label htmlFor="tpid">TPID</label>
                        <input type="text" id="tpid" value={msSalesAccountId} className={isNavPanelOpen ? viewCompanySuggestions ? 'nomargin' : 'marginLeftForTpId-collapsed' : viewCompanySuggestions ? 'nomargin' : 'marginLeftForTpId'} disabled />
                    </div>
                    <div>
                        <label htmlFor="company-name">Company Name</label>
                        <input type="text" id="company-name" value={accountName} className={isNavPanelOpen ? viewCompanySuggestions ? 'nomargin' : 'marginLeftForCompanyName-collapsed' : viewCompanySuggestions ? 'nomargin' : 'marginLeftForCompanyName'} disabled />
                    </div>
                </div>
                {isCertifiedUser && <div className={isNavPanelOpen ? 'certify-domain-checkbox-collapsed' : 'certify-domain-checkbox'}>
                    <input type="checkbox" id="certify-domain" name="certify-domain" value="certify-domain" className={isNavPanelOpen ? viewCompanySuggestions ? 'marginLeftForCertifyDomainCheckbox40px-collapsed' : 'marginLeftForCertifyDomainCheckbox25px-collapsed' : viewCompanySuggestions ? 'marginLeftForCertifyDomainCheckbox40px' : 'marginLeftForCertifyDomainCheckbox25px'} defaultChecked={false} />
                    <label htmlFor="certify-domain">Mark as Certified</label>
                </div>}
                <div className={isNavPanelOpen ? "domain-mapping-btn-footer-collapsed" : "domain-mapping-btn-footer"}>
                    <Button appearance="primary" className="domain-mapping-func_btn" onClick={(e) => { handleFormSubmit(e) }} type="submit"><Icon name="save" slot="start" />Save Changes</Button>
                    <Button appearance="secondary" className="domain-mapping-func_btn" onClick={() => { setCreateMapping(false); setEditMapping(false); dispatch(setMappedCompany("")); dispatch(setMsxAccountNumber("")); dispatch(setMsSalesAccountId("")); dispatch(setAccountName("")); dispatch(clearCompanyList()); setEditData(false); setIsUnmapped(false); dispatch(setIsDataToBeEditedFound(false)) }}><Icon name="clear" slot="start" />Cancel</Button>
                </div>

            </form>

        </>
    )
}

