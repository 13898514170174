import { createAsyncThunk } from "@reduxjs/toolkit";
import { appInsights } from "../../config/appInsights";
import { getRequestForExcel } from "../utils/GetRequest";
import { DownloadExcelData } from "../utils/ExcelHandler";
import { v4 as uuidv4 } from 'uuid';

export const getDomainMappingInputFile = createAsyncThunk<any, any>('DomainMapping/GetDomainMappingInputFile', async (payload: any) => {
  var correlationId = uuidv4();
  appInsights.trackEvent({ name: "Sending request to DomainMapping/GetDomainMappingInputFile data", properties: { correlationId: correlationId, page: "Domain Mapping" } });

  await getRequestForExcel<string>(`DomainMapping/GetDomainMappingInputFile/${payload.fileName}`, correlationId)
    .then(response => {
      DownloadExcelData(response);
      appInsights.trackEvent({ name: "Data fetched from DomainMapping/GetDomainMappingInputFile api", properties: { correlationId: correlationId } })
    }).catch(error => {
      appInsights.trackEvent({ name: "Data fetched from DomainMapping/GetDomainMappingInputFile api", properties: { error: error, correlationId: correlationId } })
    });
})
