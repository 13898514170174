import { createAsyncThunk } from "@reduxjs/toolkit"
import { getRequest } from "./utils/GetRequest"
import { appInsights } from "../config/appInsights";
import { v4 as uuidv4 } from 'uuid';

export const getUserGroups = createAsyncThunk<string[], string>('UserAuthorization/GetAuthorizedRolesForUser', async (userEmailId: string) => {
  var correlationId = uuidv4();
  appInsights.trackEvent({ name: "Sending request to UserAuthorization/GetAuthorizedRolesForUser data", properties: { correlationId: correlationId } });
  const response = await getRequest<string[]>(`UserAuthorization/GetAuthorizedRolesForUser/`, correlationId);
  response.data.length === 0 ? appInsights.trackEvent({ name: "Data not fetched from Home/GetAuthorizedRolesForUser api", properties: { data: JSON.stringify(response.data), correlationId: correlationId } }) : appInsights.trackEvent({ name: "Data fetched from Home/GetAuthorizedRolesForUser api", properties: { correlationId: correlationId } });
  return response.data
})

