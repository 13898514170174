import { Grid } from "../grid/grid";
import { NotAuthorizedPage } from "../no-authorization-page/NotAuthorizedPage";
import { Spinner, SpinnerBetween } from "../../shared-components/loader/Spinner";
import { InputFields } from "./input-fields/InputFields";

import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../root-redux/RootState";
import { setSelectedCountry, setErrorUploadMessage, clearErrorUploadMessage, clearErrorFetchMessage, clearSuccessMsg, clearNothingToShow, setEditIspName, setEditIspLink, setEditDomain, setEditSuppressed, resetState } from "./domain-suppression-redux/DomainSuppression.redux";

import { Button, Icon, Text, Tab, Tabs, TabPanel, Dialog } from '../../common/HarmonyEnablers';
import { domainSuppressionRowObject } from "./DomainSuppressionAttributes";

import { setAuthorizedCountriesForUser } from "../../shared-components/user-language-country-data/UserLanguageCountryData.redux";
import { getAuthorizedCountryData } from "../../services/CommonApi/GetAuthorizedCountryData.api";
import { getMappingDataByCountry } from "../../services/DomainSuppression/GetMappingDataByCountry.api";
import { getIspDomainMappingRequest } from "../../services/DomainSuppression/GetIspDomainMappingRequest.api";
import { deleteMapping } from "../../services/DomainSuppression/Delete.api";
import { postExportToExcelMapping } from "../../services/DomainSuppression/ExportToExcelMapping.api";
import { uploadFileForSuppression } from "../../services/DomainSuppression/PostUploadFileForSuppression.api";
import { RequestStatus } from '../../model/ServiceRequestStatus.model'
import { getExcelFile } from "../../services/DomainSuppression/GetExcelFile.api";
import { getDomainSupressionInputFile } from "../../services/DomainSuppression/GetIspDomainMappingInputFile";
import { getDomainSupressionProcessedFile } from "../../services/DomainSuppression/GetDomainSupressionProcessedFile.api";
import { GetDomainSupressionTemplate } from "../../services/DomainSuppression/GetDomainSupressionTemplate.api";

import { ValidateFileData, ValidateFile } from "../inputValidation/ExcelValidation";
import * as XLSX from "xlsx";
import * as Constants from "../inputValidation/Constants";

import "./DomainSuppression.css";

export const DomainSuppression: React.FC = () => {
    const [notAuthorized, setNotAuthorized] = useState<boolean>(false);
    const [isPermissionsFetched, setIsPermissionsFetched] = useState<boolean>(false);
    const [isActiveTab, setIsActiveTab] = useState("manual");
    const [createNewMapping, setCreateNewMapping] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
    const [editBtnClicked, setEditBtnClicked] = useState(false);
    const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false);
    const [toDeleteRowId, setToDeleteRowId] = useState(0);
    const [toEditRow, setToEditRow] = useState<domainSuppressionRowObject>();
    const [validationStatus, setValidationStatus] = useState("");
    const [isOpenMsgBar, setIsOpenMsgBar] = useState(false);
    const dispatch = useDispatch();
    const dispatchApp = useDispatch<AppDispatch>();
    const divRefForEdit = useRef<HTMLDivElement>(null);

    const { domainSuppressionRowData, domainSuppressionColumnData, bulkDomainSuppressionColumnData, bulkDomainSuppressionRowData, selectedCountry, getAuthorizedCountryDataStatus, getMappingDataByCountryStatus, getIspDomainMappingRequestStatus, errorUpload, errorFetch, successMsg, nothingToShow } = useSelector((state: RootState) => state.domainSuppression);
    const { authorizedCountriesForUser } = useSelector((state: RootState) => state.userLanguageCountryData);
    const { isNavPanelOpen } = useSelector((state: RootState) => state.nav);
    const { list } = useSelector((state: RootState) => state.userGroups);

    const fetchAuthorizedCountry = async () => {
        try {
            var currentPage = "Domain Suppression";
            if (authorizedCountriesForUser.length === 0) {
                const response = await dispatchApp(getAuthorizedCountryData(currentPage));
                if (response.payload.Error) {
                    throw new Error(response.payload.Error);
                }
                else {
                    const authorizedCountries = response.payload.map((countryData: any) => ({
                        DisplayName: countryData.DisplayName,
                        Value: countryData.Value,
                        IsEnabled: countryData.IsEnabled,
                    }));
                    dispatch(setAuthorizedCountriesForUser(authorizedCountries));
                    dispatch(setSelectedCountry(authorizedCountries[0]));
                }
            }
            else {
                // dispatch(setAuthorizedCountry(authorizedCountriesForUser));
                dispatch(setSelectedCountry(authorizedCountriesForUser[0]));
            }
            resetMessagesAfterFiveSeconds();


        } catch (err) {
            return err;
        }
    };

    const handleAuthCountryDropdownChange = (event: any) => {
        dispatch(setSelectedCountry({ DisplayName: event.target.value, Value: event.target.value, IsEnabled: true }));// = event.target.value;
        setCreateNewMapping(false);
        setEditBtnClicked(false);
    };

    const fetchDomainSuppressionData = async (Country: string, Dummy: string) => {
        resetErrorMessages();
        try {
            await dispatchApp(getMappingDataByCountry({ Country: Country, Dummy: Dummy }));
            resetMessagesAfterFiveSeconds();
        }
        catch (err) {
            return err;
        }
    };

    const fetchBulkUploadData = async () => {
        resetErrorMessages();
        try {
            await dispatchApp(getIspDomainMappingRequest());
            resetMessagesAfterFiveSeconds();
        }
        catch (err) {
            return err;
        }
    };

    const handleDelete = async () => {
        let id: number = toDeleteRowId;
        let payload = { id: id };
        await dispatchApp(deleteMapping(payload));
        await dispatchApp(getMappingDataByCountry({ Country: selectedCountry.DisplayName, Dummy: new Date().getTime().toString() }));
        resetMessagesAfterFiveSeconds();
    };

    const handleDeleteButtonClick = async (row: domainSuppressionRowObject) => {
        resetErrorMessages();
        setIsOpenDeleteDialog(true);
        setToDeleteRowId(row.id);
    };

    const handleEditButtonClick = (row: any) => {
        if (divRefForEdit.current) {
            divRefForEdit.current.scrollIntoView({ behavior: 'smooth' });
        }
        resetErrorMessages();
        setEditBtnClicked(true);
        setCreateNewMapping(false);
        setToEditRow(row);
        dispatch(setEditIspName(row.data.ProviderName));
        dispatch(setEditIspLink(row.data.ProviderLink));
        dispatch(setEditDomain(row.data.Domain));
        dispatch(setEditSuppressed(row.data.Suppressed === "true" ? true : false));
    };


    const handleDownload = async (rowid: any, type: any, fileName: any) => {
        resetErrorMessages();
        try {
            const payload = { "fileName": fileName };
            if (type === "inputfile") {
                await dispatchApp(getDomainSupressionInputFile(payload));
            }
            if (type === "processedfile") {
                await dispatchApp(getDomainSupressionProcessedFile(payload));
            }
            resetMessagesAfterFiveSeconds();
        }
        catch (err) {
            return err;
        }
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let files = event.target;
        let fileList: FileList | null = event.target.files;
        let filesArray: File[] = [];
        let validationStatus = "";
        if (fileList) {
            filesArray = Array.from(fileList);
            for (let i = 0; i < filesArray.length; i++) {
                const file: File = filesArray[i];
                let fileValidationStatus = ValidateFile(file);
                if (fileValidationStatus !== Constants.INPUT_VALID) {
                    filesArray.splice(i, 1); // remove file from filesArray
                    validationStatus += "Not able to upload " + file.name + " because " + fileValidationStatus + "\n";
                    i--;
                }
            }
        }

        const newFilesArray: any[] = [];
        let correctFiles = 0;
        let incorrectFiles = 0;
        for (let i = 0; i < filesArray.length; i++) {
            const file = filesArray[i];
            const reader = new FileReader();
            reader.onload = (event) => {
                const bstr = event.target!.result;
                let workbook;
                try {
                    workbook = XLSX.read(bstr, { type: 'binary' });
                } catch (e) {
                    validationStatus += "Not able to upload " + file.name + " because " + Constants.INPUT_COULD_NOT_PARSE + "\n";
                    incorrectFiles++;
                    return;
                }
                const fileDataValidationStatus = ValidateFileData(workbook, "DomainSuppression");
                if (fileDataValidationStatus !== Constants.INPUT_VALID) {
                    validationStatus += "Not able to upload " + file.name + " because " + fileDataValidationStatus + "\n";
                    incorrectFiles++;
                } else {
                    newFilesArray.push(file);
                    correctFiles++;
                }
                if (incorrectFiles + correctFiles === filesArray.length) {
                    setSelectedFiles(newFilesArray);
                    setValidationStatus(validationStatus);
                    if (validationStatus.length > 0) {
                        dispatch(setErrorUploadMessage(validationStatus));
                    }
                    files.value = "";
                }
            };
            reader.readAsBinaryString(file);
        }
        setIsOpenMsgBar(true);
    };

    const handleClear = (event: any): void => {
        resetErrorMessages();
        if (event.target) {
            (event.target as HTMLFormElement).reset();
        }
        setSelectedFiles([]);
    };


    const uploadToExcel = async () => {
        resetErrorMessages();
        try {
            const payload = { "country": selectedCountry.DisplayName };
            const res = await dispatchApp(postExportToExcelMapping(payload));
            const filename = res.payload.fileName;
            var payloadForExcel = { "fileName": filename };
            await dispatchApp(getExcelFile(payloadForExcel));
            resetMessagesAfterFiveSeconds();
        }
        catch (error) {
            return error;
        }
    };

    const downloadTemplate = async () => {
        resetErrorMessages();
        await dispatchApp(GetDomainSupressionTemplate());
        resetMessagesAfterFiveSeconds();
    };

    const submitBulkFileUpload = async (event: any) => {
        event.preventDefault();
        event.target.reset();
        var reader = new FileReader();
        var tempSelectedFiles = selectedFiles;

        setSelectedFiles([]);
        if (tempSelectedFiles.length) {
            var fileData = new FormData();
            for (let i = 0; i < tempSelectedFiles.length; i++) {
                fileData.append(selectedFiles[i].name, selectedFiles[i], selectedFiles[i].name);
            }
            let payload = { fileData: fileData, country: selectedCountry.DisplayName };
            await dispatchApp(uploadFileForSuppression(payload));
            resetMessagesAfterFiveSeconds();
            if (event.target) {
                (event.target as HTMLFormElement).reset();
            }
            fetchBulkUploadData();
        }

    };

    const handleCloseSuccessMsg = (): void => {
        dispatch(clearSuccessMsg());
    };

    const handleCloseUploadError = (): void => {
        dispatch(clearErrorUploadMessage());
    };

    const handleCloseFetchError = (): void => {
        dispatch(clearErrorFetchMessage());
    };

    const isFileSelected = selectedFiles.length > 0;

    const resetErrorMessages = (): void => {
        dispatch(clearErrorUploadMessage());
        dispatch(clearErrorFetchMessage());
    };

    const resetMessagesAfterFiveSeconds = (): void => {
        setTimeout(() => {
            dispatch(clearSuccessMsg());
        }, 5000);
    };

    useEffect(() => {
        if (selectedCountry.DisplayName !== "" && isActiveTab === 'manual') {
            fetchDomainSuppressionData(selectedCountry.DisplayName, "FETCH");
        }
    }, [selectedCountry]);

    useEffect(() => {
        var userGroupsFromStorage = window.localStorage.getItem("user_groups");
        if (userGroupsFromStorage) {
            var parsedJSONgroups = JSON.parse(userGroupsFromStorage);
            if (parsedJSONgroups.length > 0 && (parsedJSONgroups.includes("PortalWideUser") || parsedJSONgroups.includes("SuperAdmin"))) {
                fetchAuthorizedCountry();
            }
            else {
                setNotAuthorized(true);
            }
            setIsPermissionsFetched(true);
        }
    }, []);

    useEffect(() => {
        setCreateNewMapping(false);
        setEditBtnClicked(false);
        dispatch(clearErrorFetchMessage());
        dispatch(clearErrorUploadMessage());
        dispatch(clearSuccessMsg());
    }, [isActiveTab]);

    useEffect(() => {
        return () => {
            dispatch(resetState());
        };
    }, [dispatch]);

    return (
        isPermissionsFetched ?
            notAuthorized ? <NotAuthorizedPage /> :
                <div className={isNavPanelOpen ? "domain-suppression-wrapper-collapsed" : "domain-suppression-wrapper"}>
                    <div className={isNavPanelOpen ? "domain-suppression-header-collapsed" : "domain-suppression-header"}>
                        <div> <Text role="heading" aria-level={1} appearance="heading-2" className="domain-suppression-heading">Domain Suppression</Text></div>
                        <Tabs active-id={isActiveTab} style={{ marginTop: "10px" }}>
                            <Tab id="manual" onClick={() => { setIsActiveTab("manual"); fetchDomainSuppressionData(selectedCountry.DisplayName, "Fetch"); dispatch(clearNothingToShow()) }} onKeyDown={(e: any) => { if (e.key === 'Enter') { { setIsActiveTab("manual"); fetchDomainSuppressionData(selectedCountry.DisplayName, "Fetch"); dispatch(clearNothingToShow()) } } }}>Manual</Tab>
                            <Tab id="bulk" onClick={() => { setIsActiveTab("bulk"); fetchBulkUploadData(); dispatch(clearNothingToShow()) }} onKeyDown={(e: any) => { if (e.key === 'Enter') { setIsActiveTab("bulk"); fetchBulkUploadData(); dispatch(clearNothingToShow()) } }}>Bulk</Tab>
                            <TabPanel>
                                <div className={isNavPanelOpen ? "domain-suppression-manual-collapsed" : "domain-suppression-manual"}>
                                    <div className={isNavPanelOpen ? "domain-suppression-select-country-collapsed" : "domain-suppression-select-country"} ref={divRefForEdit}>
                                        <Text role="heading" aria-level={3} appearance="heading-4" className="domain-suppression-heading">Select Country/Region</Text>
                                        <select className="company-mapping-authorized-countries" id="authorizedCountryDropdownManual" value={selectedCountry.DisplayName} onChange={handleAuthCountryDropdownChange} aria-label="Select a Country / Region" aria-labelledby="authorizedCountryDropdownChange">

                                            {authorizedCountriesForUser?.map(country => (
                                                <option className="company-mapping-authorized-countries-menu" key={country.Value} aria-selected={country.Value === selectedCountry.Value}
                                                    value={country.DisplayName} onClick={handleAuthCountryDropdownChange}

                                                >{country.DisplayName}
                                                </option>
                                            ))
                                            }
                                        </select>
                                        {
                                            getAuthorizedCountryDataStatus === RequestStatus.loading && <Spinner />
                                        }
                                    </div>
                                    <Button appearance="primary" className={isNavPanelOpen ? 'domain-suppression-create_btn-collapsed' : 'domain-suppression-create_btn'} onClick={() => { resetErrorMessages(); setCreateNewMapping(true); setEditBtnClicked(false); }}>
                                        <Icon name="contact" slot="start" />
                                        Create New Mapping
                                    </Button>

                                </div>
                                {createNewMapping && <Text role="heading" aria-level={2} appearance="heading-3" className="domain-suppression-heading" style={{ "marginBottom": "20px" }}>Create Mapping Entry:</Text>}
                                {createNewMapping && <InputFields createNewMapping={createNewMapping} editBtnClicked={editBtnClicked} setCreateNewMapping={setCreateNewMapping} setEditBtnClicked={setEditBtnClicked} domainSuppressionRowData={domainSuppressionRowData} />}
                                {editBtnClicked && <Text role="heading" aria-level={2} appearance="heading-3" className="domain-suppression-heading" style={{ "marginBottom": "20px" }}>Edit Mapping Entry:</Text>}
                                {editBtnClicked && <InputFields createNewMapping={createNewMapping} editBtnClicked={editBtnClicked} setCreateNewMapping={setCreateNewMapping} setEditBtnClicked={setEditBtnClicked} toEditRow={toEditRow} />}
                            </TabPanel>
                            <TabPanel>
                                <div className={isNavPanelOpen ? "domain-suppression-select-country-collapsed" : "domain-suppression-select-country"} style={{ "marginTop": "20px", "marginBottom": "20px" }}>
                                    <Text role="heading" aria-level={3} appearance="heading-4" className="domain-suppression-heading">Select Country/Region</Text>
                                    <select className="company-mapping-authorized-countries" id="authorizedCountryDropdownBulk" value={selectedCountry.DisplayName} onChange={handleAuthCountryDropdownChange} aria-label="Select a Country / Region" aria-labelledby="authorizedCountryDropdownBulk">

                                        {authorizedCountriesForUser?.map((country) => (
                                            <option className="company-mapping-authorized-countries-menu" key={country.Value} aria-selected={country.Value === selectedCountry.Value}
                                                value={country.DisplayName} onClick={handleAuthCountryDropdownChange}

                                            >{country.DisplayName}
                                            </option>
                                        ))
                                        }
                                    </select>
                                    {
                                        getAuthorizedCountryDataStatus === RequestStatus.loading && <Spinner />
                                    }
                                </div>
                                <div>
                                    <Text role="heading" aria-level={3} appearance="heading-4" className="domain-suppression-heading">Upload File</Text>
                                    <Text appearance="heading-6" className="domain-suppression-heading">Please select a file to upload. The standard template can be downloaded here: <a href="#" onClick={downloadTemplate} style={{ color: "#0b62e4" }}>Download Template</a></Text>
                                </div>
                                <form onSubmit={submitBulkFileUpload} onReset={handleClear}>
                                    <div className={isNavPanelOpen ? "input-field-collapsed" : "input-field"}>
                                        <div className={isNavPanelOpen ? "input-text-collapsed" : "input-text"}>
                                            <input type="text" placeholder="Select files to upload" value={selectedFiles?.length > 0 ? selectedFiles.map(file => file.name).join(", ") : ""} onClick={() => { dispatch(clearErrorUploadMessage()); document.getElementById("file-input")?.click() }} onKeyDown={(e) => { if (e.key === 'Enter') { dispatch(clearErrorUploadMessage()); document.getElementById("file-input")?.click() } }} readOnly />
                                        </div>
                                        <div className="input-file">
                                            <input type="file" id="file-input" onChange={handleFileChange} onClick={() => dispatch(clearErrorUploadMessage())} multiple hidden />
                                            <label htmlFor="file-input" className="custom-button" onKeyDown={(e) => { if (e.key === 'Enter') { dispatch(clearErrorUploadMessage()); document.getElementById("file-input")?.click() } }} tabIndex={0}>Select</label>
                                        </div>
                                    </div>
                                    <div>
                                        <Button className="button" type="submit" appearance="primary" disabled={!isFileSelected} tabIndex={selectedFiles.length > 0 ? 0 : -1}>
                                            <Text>Submit</Text>
                                        </Button>
                                        <Button type="reset" className="button" disabled={!isFileSelected} tabIndex={selectedFiles.length > 0 ? 0 : -1}>
                                            <Text>Clear</Text>
                                        </Button>
                                    </div>
                                </form>

                            </TabPanel>
                        </Tabs>
                    </div>

                    {
                        successMsg !== undefined && (
                            <div className="domain-suppression-success">
                                <span><p>{successMsg}</p></span>
                                <Icon name="chromeclose" onClick={handleCloseSuccessMsg} onkeydown={(e) => { if (e.key === 'Enter') { handleCloseSuccessMsg() } }} className="domain-suppression-close-icon" tabIndex={0}></Icon>
                            </div>
                        )
                    }
                    {errorUpload !== undefined && (
                        <div className="domain-suppression-error">
                            <span><p>{errorUpload}</p></span>
                            <Icon name="chromeclose" onClick={handleCloseUploadError} onkeydown={(e) => { if (e.key === 'Enter') { handleCloseUploadError() } }} className="domain-suppression-close-icon" tabIndex={0}></Icon>
                        </div>
                    )}
                    {errorFetch !== undefined && (
                        <div className="domain-suppression-error">
                            <span><p>{errorFetch}</p></span>
                            <Icon name="chromeclose" onClick={handleCloseFetchError} onkeydown={(e) => { if (e.key === 'Enter') { handleCloseFetchError() } }} className="domain-suppression-close-icon" tabIndex={0}></Icon>
                        </div>
                    )}

                    {isActiveTab === "manual" && getMappingDataByCountryStatus === RequestStatus.loading && <SpinnerBetween />}
                    {isActiveTab === "bulk" && getIspDomainMappingRequestStatus === RequestStatus.loading && <SpinnerBetween />}
                    {isActiveTab === "manual" && getMappingDataByCountryStatus === RequestStatus.succeeded && domainSuppressionRowData.length > 0 && <Grid columnData={domainSuppressionColumnData} rowData={domainSuppressionRowData} handleDownload={handleDownload} handleDeleteButtonClick={handleDeleteButtonClick} handleEditButtonClick={handleEditButtonClick} page="domainSuppression" isExcelUpload={true} uploadToExcel={uploadToExcel} sortType="DateUpdated" isAscending={false}></Grid>}
                    {isActiveTab === "bulk" && getIspDomainMappingRequestStatus === RequestStatus.succeeded && bulkDomainSuppressionRowData.length > 0 && <Grid columnData={bulkDomainSuppressionColumnData} rowData={bulkDomainSuppressionRowData} handleDownload={handleDownload} page="domainSuppression" sortType="UploadedOn" isAscending={false}></Grid>}
                    {
                        nothingToShow !== undefined && (
                            <Text appearance="heading-3">{nothingToShow}</Text>
                        )
                    }

                    <Dialog
                        no-header
                        className="dialog-no-header"
                        open={isOpenDeleteDialog}
                        onHeAfterHide={
                            ({ target, currentTarget }) => (target === currentTarget ? setIsOpenDeleteDialog(false) : null)
                        }
                    >
                        <p>
                            Do you really want to delete this domain suppression?
                        </p>

                        <Button slot="footer" appearance="primary" onClick={() => { setIsOpenDeleteDialog(false); handleDelete() }}>
                            Ok
                        </Button>
                        <Button slot="footer" onClick={() => { setIsOpenDeleteDialog(false); setToDeleteRowId(0) }}>
                            Close
                        </Button>
                    </Dialog>
                </div>
            :
            <></>
    );
}

