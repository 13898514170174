import { createAsyncThunk } from "@reduxjs/toolkit"
import { getRequest } from "../../services/utils/GetRequest"
import { appInsights } from "../../config/appInsights";
import { v4 as uuidv4 } from 'uuid';

export const getAuthorizedCountryData = createAsyncThunk<any, any>('IspDomainMapping/GetAuthorizedCountryData', async (page) => {
  var correlationId = uuidv4();
  appInsights.trackEvent({ name: "Sending request to IspDomainMapping/GetAuthorizedCountryData data for Company Mapping page", properties: { correlationId: correlationId, page: page } });
  const response = await getRequest<string>('IspDomainMapping/GetAuthorizedCountryData', correlationId);
  response.data === null ? appInsights.trackEvent({ name: "Data not fetched from IspDomainMapping/GetAuthorizedCountryData api for Company Mapping page", properties: { data: JSON.stringify(response.data), correlationId: correlationId } }) : appInsights.trackEvent({ name: "Data fetched from IspDomainMapping/GetAuthorizedCountryData api  for Company Mapping page", properties: {correlationId: correlationId } });
  return response.data;
})
