import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { NavMenuItemType } from '../../../model/NavMenu.model'
import { appInsights } from '../../../config/appInsights';

interface NavState {
  selectedNavItem: NavMenuItemType
  isNavPanelOpen: boolean
}

const initialState: NavState = {
  selectedNavItem: NavMenuItemType.Home,
  isNavPanelOpen: false
}

export const navSlice = createSlice({
  name: 'nav',
  initialState,
  reducers: {
    setNavState: (state, action: PayloadAction<NavMenuItemType>) => {
      state.selectedNavItem = action.payload
    },
    setIsNavPanelOpen: (state, action: PayloadAction<boolean>) => {
      state.isNavPanelOpen = action.payload
    }
  }
});

const navReducer = navSlice.reducer;

export {navReducer}