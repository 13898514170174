import { Spinner } from '../../../shared-components/loader/Spinner';

import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../root-redux/RootState';
import { clearJobList, clearMappedJobRowData, clearSuccessMessage, clearUnmappedJobRowData, setIsDataToBeEditedFound } from "../job-title-mapping-redux/JobTitleMapping.redux";
import { RequestStatus } from '../../../model/ServiceRequestStatus.model';

import { Button, Menu, MenuItem, Icon, DataGridView } from '../../../common/HarmonyEnablers';

import { getJobRoleData } from '../../../services/JobTitleMapping/GetJobRoleData.api';
import { createJobTitleMappingSingle } from '../../../services/JobTitleMapping/PostCreateJobTitleMappingSingle.api';
import { menuItem } from '@harmony/enablers/react';
import { updateJobTitleMappingSingle } from '../../../services/JobTitleMapping/PostUpdateJobTitleMappingSingle.api';
import { setResponse } from '../job-title-mapping-redux/JobTitleMapping.redux';

import { inputFieldsProps } from '../JobTitleMappingAttributes';
import { getTranslatedValues } from '../../../services/JobTitleMapping/PostGetTranslatedValues.api';


export const InputFields: React.FC<inputFieldsProps> = ({ setCreateMapping, setEditMapping, setEditData, setIsUnmapped, editData, isUnmapped, fetchUnmappedJobTitles }) => {
    const [mappedJob, setMappedJob] = useState<string>("");
    const [marketingAudienceName, setMarketingAudience] = useState<string>("");
    const [createDataJobTitle, setCreateDataJobTitle] = useState<string>("");

    var [rows, setTranslationGridRows] = useState<any>([{
        id: 0,
        cells: { field1: "", field2: "", field3: "" }
    }]);
    var [isTranslationGridVisible, setTranslationGridVisibility] = useState<boolean>(true);
    var [noRecordsMessage, setNoRecordsMessage] = useState<string>("No values were returned.");
    const [selectedMappedJob, setSelectedMappedJob] = useState<any>();
    const languageNameTolanguageCodeMapping: { [key: string]: string } = {
        ar: "Arabic",
        zh: "Chinese",
        cs: "Czech",
        da: "Danish",
        nl: "Dutch",
        en: "English",
        fi: "Finnish",
        fr: "French",
        de: "German",
        it: "Italian",
        ja: "Japanese",
        ko: "Korean",
        nb: "Norwegian",
        pl: "Polish",
        pt: "Portuguese",
        ru: "Russian",
        es: "Spanish",
        sv: "Swedish",
        tr: "Turkish"
    };
    
    const { mappedJobList,error, selectedLanguage, editDataJobTitle, jobTitleMappingData, getJobRoleDataStatus } = useSelector((state: RootState) => state.jobTitleMapping);
    const { isNavPanelOpen } = useSelector((state: RootState) => state.nav);

    const dispatch = useDispatch();
    const dispatchApp = useDispatch<AppDispatch>();
    
    const handleMappedJobRole = async (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(clearJobList());
        setMarketingAudience("");
        setMappedJob(event.target.value);
        if (event.target.value.length > 2) {
            dispatchApp(getJobRoleData(event.target.value.toString()));

        }
        else {
            dispatch(clearJobList());
        }

    };

    const handleSelectedMappedJob = async (job: menuItem) => {
        setSelectedMappedJob(job);
    };

    const handleInputJobTitle = async (event: React.ChangeEvent<HTMLInputElement>) => {
        setCreateDataJobTitle(event.target.value);
    };

    const handleUpdate = async (event: any) => {
        event.preventDefault();
        if (marketingAudienceName === "" || mappedJob === "") {
            dispatch(setResponse("error"));
            return;
        }

        var oldJobRoleData = selectedMappedJob;
        var oldJobRoleDataList = jobTitleMappingData;
        var payload = { "jobTitle": editDataJobTitle, "languageCode": selectedLanguage.LanguageCode, "languageName": selectedLanguage.Language, "jobRoleData": selectedMappedJob, "oldJobRoleData": oldJobRoleData, "oldJobRoleDataList": oldJobRoleDataList }
        dispatchApp(updateJobTitleMappingSingle(payload));
        setEditMapping(false);
        dispatch(clearUnmappedJobRowData());
        dispatch(clearMappedJobRowData());
        dispatch(clearJobList());
    };

    const resetMessagesAfterFiveSeconds = (): void => {
        setTimeout(() => {
            dispatch(clearSuccessMessage());
          }, 5000);
    };

 const columns = [
    {
        field: 'field1',
        content: 'Job Title',
    },
    {
        field: 'field2',
        content: 'Language',
    },
    {
        field: 'field3',
        content: 'Modified Date',
    }
];

    const handleShowHideTranslationsGrid = async () => {
        var translationGrid = document.getElementById("translationGrid");
        if(rows.length==0)
        {
            setTranslationGridRows([{
                id: 0,
                cells: { field1: "", field2: "", field3: "" }
            }]);
        }
        if(translationGrid)
        {
            translationGrid.setAttribute("loading", 'true');
        }
        setTranslationGridVisibility(false);
        let payload = {
            textToTranslate: createDataJobTitle,
            languageCode: selectedLanguage.LanguageCode
        }
        var response = await dispatchApp(getTranslatedValues(payload));
        rows=[];
        if(response.meta.requestStatus !== "fulfilled")
        {
            setTranslationGridRows([]);
            setNoRecordsMessage("Error in fetching translations. Please try again later.");
            return;
        }
        if(translationGrid)
        {
            translationGrid.removeAttribute("loading");
        }
        for(let i=0;i<response.payload.Response[0].length; i++)
        {
            let row = {id:i+1, cells:{field1:response.payload.Response[0][i], field2:response.payload.Response[1][i]=='zh-Hans'?"Chinese":languageNameTolanguageCodeMapping[response.payload.Response[1][i]], field3:response.payload.Response[2][i]}};
            rows.push(row);
        }
        setTranslationGridRows(rows);  
    }

    const handleSubmit = async (event: any) => {
        event.preventDefault();
        if (marketingAudienceName === "" || mappedJob === "") {
            dispatch(setResponse("error"));
            return;
        }
        const selectedRows = rows.filter((x: { selected: any; }) => x.selected == true);
        var translatedpayloads = [];
        if(isUnmapped && !editData)
        {
            for(let i=0;i<selectedRows.length;i++)
            {
                var detectedLanguageCode = Object.keys(languageNameTolanguageCodeMapping).find(key =>
                    languageNameTolanguageCodeMapping[key] === selectedRows[i].cells.field2);
                let translatedpayload = { "jobTitle": selectedRows[i].cells.field1, "languageCode": detectedLanguageCode == "nb" ? "no" : detectedLanguageCode , "languageName": selectedRows[i].cells.field2, "jobRoleData": selectedMappedJob, "editUnmapped": isUnmapped, "isTranslatedByService":"1", "translatedFrom": createDataJobTitle};
                translatedpayloads.push(translatedpayload);
            }
        }
        var payload = { "jobTitle": createDataJobTitle, "languageCode": selectedLanguage.LanguageCode, "languageName": selectedLanguage.Language, "jobRoleData": selectedMappedJob, "editUnmapped": false , "isTranslatedByService":"0", "translatedFrom": null };
        if (isUnmapped === true && createDataJobTitle === "") {
            payload = { "jobTitle": editDataJobTitle, "languageCode": selectedLanguage.LanguageCode, "languageName": selectedLanguage.Language, "jobRoleData": selectedMappedJob, "editUnmapped": isUnmapped, "isTranslatedByService":"0", "translatedFrom": null };
        }
        var res = await dispatchApp(createJobTitleMappingSingle(payload));
        var response = await dispatchApp(getJobRoleData(selectedMappedJob.standardJobRoleName.toString()));
        if (isUnmapped === true && !editData) {
            for(let i=0;i<translatedpayloads.length;i++)
            {
                translatedpayloads[i].jobRoleData = response.payload[0];
                res = await dispatchApp(createJobTitleMappingSingle(translatedpayloads[i]));
                if(res.payload.Response === "success")
                {
                    response = await dispatchApp(getJobRoleData(selectedMappedJob.standardJobRoleName.toString()));
                }
            }
            await fetchUnmappedJobTitles();
        }
        resetMessagesAfterFiveSeconds();
        setCreateMapping(false);
        setEditMapping(false);
        dispatch(setIsDataToBeEditedFound(false));
    };

    return (
        <>
            <form className={isNavPanelOpen?"jobtitle-form-collapsed":"jobtitle-form"} onSubmit={(editData === true && isUnmapped === false) ? handleUpdate : handleSubmit} >
                <div className={isNavPanelOpen?"jobtitle-input-fields-collapsed":"jobtitle-input-fields"}>
                    <div>
                        <label htmlFor="jobTitle">Input Job Title</label>
                        {editData && <input type="text" id="jobTitle" value={editDataJobTitle} disabled />}
                        {!editData && <input type="text" id="jobTitle" onChange={(event) => { handleInputJobTitle(event) }} autoComplete='off' required />}
                    </div>

                    <div>
                        <label htmlFor="mapped-job-role">Mapped Standard Job Role</label>
                        <div><input type="text" id="mapped-job-role" onChange={(event) => { dispatch(clearJobList); handleMappedJobRole(event) }} value={mappedJob} autoComplete='off' required />
                            {mappedJob.length > 2 && <Menu className='menu'>

                                {
                                    mappedJobList.map((job: any) => (<MenuItem className="menu-item" onClick={() => { setMappedJob(job.standardJobRoleName); handleSelectedMappedJob(job); setMarketingAudience(job.marketingAudienceName); dispatch(clearJobList()); }}>{job.standardJobRoleName} (Marketing Audience: {job.marketingAudienceName})</MenuItem>))
                                }
                            </Menu>}
                            {getJobRoleDataStatus === RequestStatus.loading && <div style={{ "display": 'flex', "justifyContent": 'center' }}>
                                <div style={{ "width": '20px' }}>
                                    <Spinner />
                                </div>
                            </div>}
                            {
                                ((getJobRoleDataStatus === 2) && error!==undefined) && <div style={{ "color":"#d90000","display": 'flex' }}>{error}</div>
                            }
                        </div>
                    </div>
                    <div>
                        <label htmlFor="marketing-audience">Marketing Audience</label>
                        <input type="text" id="marketing-audience" value={marketingAudienceName} disabled />
                    </div>
                </div>
                <div className="job-title-btn-footer">
                    {
                        (isUnmapped === true && !editData) && 
                        <Button appearance="primary" className='jobtitle-func_btn' onClick={() => { handleShowHideTranslationsGrid() }} disabled = {createDataJobTitle==""}>Detect in other languages</Button>
                    }
                    <Button appearance="primary" type="submit" className='jobtitle-func_btn'><Icon name="save" slot="start" />Save Changes</Button>
                    <Button appearance="secondary" className='jobtitle-func_btn' onClick={() => { setCreateMapping(false); setEditMapping(false); setMappedJob(""); setMarketingAudience(""); dispatch(clearJobList()); setEditData(false); setIsUnmapped(false); dispatch(setIsDataToBeEditedFound(false)) }}><Icon name="clear" slot="start" />Cancel</Button>
    
                </div>
                <div>
                    <DataGridView
                        id="translationGrid"
                        rows={rows}
                        columns={columns}
                        hidden={isTranslationGridVisible}
                        select="multiple"
                        label="Multiselect Example"
                    >
                        <span slot="no-records">{noRecordsMessage}</span>
                    </DataGridView>
                </div>

            </form>

        </>
    )
}

