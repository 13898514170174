import { Spinner } from '../../../shared-components/loader/Spinner';

import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../root-redux/RootState';
import { clearJobList, clearMappedJobRowData, clearSuccessMessage, clearUnmappedJobRowData, setIsDataToBeEditedFound, setJobRoleData, setNewJobRoleData, clearNewJobRoleData, clearJobRoleData } from "../job-title-mapping-redux/JobTitleMapping.redux";
import { RequestStatus } from '../../../model/ServiceRequestStatus.model';

import { Button, Menu, MenuItem, Icon } from '../../../common/HarmonyEnablers';

import { getJobRoleData } from '../../../services/JobTitleMappingV2/GetJobRoleData.api';
import { createJobTitleMappingSingle } from '../../../services/JobTitleMappingV2/PostCreateJobTitleMappingSingle.api';
import { menuItem } from '@harmony/enablers/react';
import { updateJobTitleMappingSingle } from '../../../services/JobTitleMappingV2/PostUpdateJobTitleMappingSingle.api';
import { setResponse } from '../job-title-mapping-redux/JobTitleMapping.redux';

import { inputFieldsProps } from '../JobTitleMappingAttributes';

export const InputFields: React.FC<inputFieldsProps> = ({ setCreateMapping, setEditMapping, setEditData, setIsUnmapped, editData, isUnmapped, fetchUnmappedJobTitles }) => {
    const [mappedJob, setMappedJob] = useState<string>("");
    const [marketingAudienceName, setMarketingAudience] = useState<string>("");
    const [functionName, setFunctionName] = useState<string>("");
    const [seniorityName, setSeniorityName] = useState<string>("");
    const [createDataJobTitle, setCreateDataJobTitle] = useState<string>("");
    const [selectedMappedJob, setSelectedMappedJob] = useState<any>();
    
    const { mappedJobList, error, selectedLanguage, editDataJobTitle, getJobRoleDataStatus, jobRoleData, newJobRoleData  } = useSelector((state: RootState) => state.jobTitleMappingV2);
    const { isNavPanelOpen } = useSelector((state: RootState) => state.nav);

    const dispatch = useDispatch();
    const dispatchApp = useDispatch<AppDispatch>();
    
    const handleMappedJobRole = async (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(clearJobList());
        setMarketingAudience("");
        setFunctionName("");
        setSeniorityName("");
        setMappedJob(event.target.value);
        if (event.target.value.length > 2) {
            dispatchApp(getJobRoleData(event.target.value.toString()));

        }
        else {
            dispatch(clearJobList());
        }

    };

    const handleSelectedMappedJob = async (job: any) => {
        setSelectedMappedJob(job);
    };

    const handleInputJobTitle = async (event: React.ChangeEvent<HTMLInputElement>) => {
        setCreateDataJobTitle(event.target.value);
    };

    const handleUpdate = async (event: any) => {
        event.preventDefault();
        if (marketingAudienceName === "" || mappedJob === "") {
            dispatch(setResponse("error"));
            return;
        }
        //var oldJobRoleData = selectedMappedJob;
        setNewJobRoleData({selectedMappedJob});
        console.log("selectedMappedJob", selectedMappedJob);
        //var oldJobRoleDataList = jobTitleMappingData;
        var payload = { "jobTitle": editDataJobTitle, "languageCode": selectedLanguage.LanguageCode, "languageName": selectedLanguage.Language, "jobRoleData": selectedMappedJob, "oldJobRoleData": jobRoleData }
        dispatchApp(updateJobTitleMappingSingle(payload));
        setEditMapping(false);
        dispatch(clearUnmappedJobRowData());
        dispatch(clearMappedJobRowData());
        dispatch(clearJobList());
    };

    const resetMessagesAfterFiveSeconds = (): void => {
        setTimeout(() => {
            dispatch(clearSuccessMessage());
          }, 5000);
    };

    const handleSubmit = async (event: any) => {
        event.preventDefault();
        if (marketingAudienceName === "" || mappedJob === "") {
            dispatch(setResponse("error"));
            return;
        }

        var payload = { "jobTitle": createDataJobTitle, "languageCode": selectedLanguage.LanguageCode, "languageName": selectedLanguage.Language, "jobRoleData": selectedMappedJob, "editUnmapped": false };
        if (isUnmapped === true && createDataJobTitle === "") {
            payload = { "jobTitle": editDataJobTitle, "languageCode": selectedLanguage.LanguageCode, "languageName": selectedLanguage.Language, "jobRoleData": selectedMappedJob, "editUnmapped": isUnmapped };
        }
        await dispatchApp(createJobTitleMappingSingle(payload));
        if (isUnmapped === true) {
            await fetchUnmappedJobTitles();
        }

        resetMessagesAfterFiveSeconds();
        setCreateMapping(false);
        setEditMapping(false);
        dispatch(setIsDataToBeEditedFound(false));
        dispatch(clearMappedJobRowData());
    };

    return (
        <>
            <form className={isNavPanelOpen?"jobtitle-form-collapsed":"jobtitle-form"} onSubmit={(editData === true && isUnmapped === false) ? handleUpdate : handleSubmit} >
                <div className={isNavPanelOpen?"jobtitle-input-fields-collapsed":"jobtitle-input-fields"}>
                    <div>
                        <label htmlFor="jobTitle">Input Job Title</label>
                        {editData && <input type="text" id="jobTitle" value={editDataJobTitle} disabled />}
                        {!editData && <input type="text" id="jobTitle" onChange={(event) => { handleInputJobTitle(event) }} autoComplete='off' required />}
                    </div>

                    <div>
                        <label htmlFor="mapped-job-role">Mapped Standard Job Role</label>
                        <div><input type="text" id="mapped-job-role" onChange={(event) => { dispatch(clearJobList); handleMappedJobRole(event) }} value={mappedJob} autoComplete='off' required />
                            {mappedJob.length > 2 && <Menu className='menu'>

                                {
                                    mappedJobList.map((job: any) => (<MenuItem className="menu-item" onClick={() => { setMappedJob(job.StandardJobRoleName); handleSelectedMappedJob(job); setMarketingAudience(job.MarketingAudienceName); setFunctionName(job.FunctionName); setSeniorityName(job.SeniorityName); dispatch(clearJobList()); }}>{job.StandardJobRoleName} (Marketing Audience: {job.MarketingAudienceName})</MenuItem>))
                                }
                            </Menu>}
                            {getJobRoleDataStatus === RequestStatus.loading && <div style={{ "display": 'flex', "justifyContent": 'center' }}>
                                <div style={{ "width": '20px' }}>
                                    <Spinner />
                                </div>
                            </div>}
                            {
                                ((getJobRoleDataStatus === 2) && error!==undefined) && <div style={{ "color":"red","display": 'flex' }}>{error}</div>
                            }
                        </div>
                    </div>
                    <div>
                        <label htmlFor="marketing-audience">Marketing Audience</label>
                        <input type="text" id="marketing-audience" value={marketingAudienceName} disabled />
                    </div>
                    <div>
                        <label htmlFor="function-name">Function</label>
                        <input type="text" id="function-name" value={functionName} disabled />
                    </div>
                    <div>
                        <label htmlFor="seniority-name">Seniority</label>
                        <input type="text" id="seniority-name" value={seniorityName} disabled />
                    </div>
                </div>
                <div className="job-title-btn-footer">
                    <Button appearance="primary" type="submit" className='jobtitle-func_btn'><Icon name="save" slot="start" />Save Changes</Button>
                    <Button appearance="secondary" className='jobtitle-func_btn' onClick={() => { setCreateMapping(false); setEditMapping(false); setMappedJob(""); setMarketingAudience(""); setFunctionName(""); setSeniorityName(""); dispatch(clearJobList()); setEditData(false); setIsUnmapped(false); dispatch(setIsDataToBeEditedFound(false));}}><Icon name="clear" slot="start" />Cancel</Button>
    
                </div>

            </form>

        </>
    )
}

