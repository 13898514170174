import { createAsyncThunk } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from 'uuid';
import { appInsights } from "../../config/appInsights";
import { postRequest } from "../utils/PostRequest";



export const getUnmappedDomainMappings = createAsyncThunk<any, any>('DomainMapping/RetreiveUnmappedDomains', async (payload: any) => {
  var correlationId = uuidv4();
  appInsights.trackEvent({ name: "Sending request to DomainMapping/RetreiveUnmappedDomains data", properties: { payload: JSON.stringify(payload), correlationId: correlationId, page: "Domain Mapping" } });
  const response = await postRequest<any, any>('DomainMapping/RetreiveUnmappedDomains', payload, correlationId);
  response.data === null ? appInsights.trackEvent({ name: "Data not fetched from DomainMapping/RetreiveUnmappedDomains api", properties: { data: JSON.stringify(response.data), correlationId: correlationId } }) : appInsights.trackEvent({ name: "Data fetched from DomainMapping/RetreiveUnmappedDomains api", properties: { correlationId: correlationId } });
  return response.data;
})
