import { createAsyncThunk } from "@reduxjs/toolkit"
import { getRequest } from "../../services/utils/GetRequest"
import { appInsights } from "../../config/appInsights";
import { v4 as uuidv4 } from 'uuid';

export const getMappingDataByCountry = createAsyncThunk<any, { Country: string, loadedRows: number, rows: number, unmatchedOnly: boolean }>('CompanyMapping/GetMappingDataByCountry', async ({ Country, loadedRows, rows, unmatchedOnly }) => {
  var correlationId = uuidv4();
  appInsights.trackEvent({ name: "Sending request to CompanyMapping/GetMappingDataByCountry data", properties: { correlationId: correlationId, page: "Company Mapping" } });
  const response = await getRequest<any>(`CompanyMapping/GetMappingDataByCountry?Country=${Country}&loadedRows=${loadedRows}&rows=${rows}&unmatchedOnly=${unmatchedOnly}`, correlationId)
  response.data === null ? appInsights.trackEvent({ name: "Data not fetched from CompanyMapping/GetMappingDataByCountry api", properties: { data: JSON.stringify(response.data), correlationId: correlationId } }) : appInsights.trackEvent({ name: "Data fetched from CompanyMapping/GetMappingDataByCountry", properties: { correlationId: correlationId } });
  return response.data
})
