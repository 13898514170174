export const convert_milliseconds_to_date = (date: string) => {
    const date_milliseconds = date.substring(6, date.length - 2);
    const date_actual = new Date(parseInt(date_milliseconds)).toUTCString();
    const formattedDate = formatDate(date_actual);
    return formattedDate;
}

export const convert_seconds_to_date = (date: number) => {
    const date_actual = new Date(date * 1000).toUTCString();
    const formattedDate = formatDate(date_actual);
    return formattedDate;
}

export const convert_UTC_to_localdate = (utcDateTimeString: string) => {

    // Create a Date object from the UTC date-time string
    const utcDate = new Date(utcDateTimeString);
    // Get the user's time zone offset in minutes
    const timeZoneOffsetMinutes = new Date().getTimezoneOffset();
    // Calculate the local time by adding the time zone offset
    const localDate = new Date(utcDate.getTime() - timeZoneOffsetMinutes * 60000);
    const localDateTimeString = formatDate(localDate);
    return localDateTimeString;
}

export const formatDate = (inputDate: any) => {
    const date = new Date(inputDate);

    // Get the components of the date
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const day = String(date.getDate()).padStart(2, '0');
    const year = String(date.getFullYear()).slice(-2);
    const hours = String(date.getHours() % 12 || 12).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const amPm = date.getHours() >= 12 ? 'PM' : 'AM';

    // Combine the components into the desired format
    const formattedDate = `${month}/${day}/${year} ${hours}:${minutes} ${amPm}`;

    return formattedDate;
}