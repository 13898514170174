export const MAX_LEADS = 4000;
export const MAX_DOMAIN_MAPPINGS = 10000;
export const MAX_DOMAIN_SUPRESSIONS = 10000;
export const MAX_COMPANY_MAPPINGS = 10000;
export const LEADS = "leads";
export const DOMAIN_MAPPINGS = "domain mappings";
export const COMPANY_MAPPINGS = "company mappings";
export const JOB_TITLE_MAPPINGS = "job title mappings";
export const DOMAIN_SUPPRESSIONS = "domain suppressions";
export const INPUT_NOFILE = "NOFILE";
export const INPUT_VALID = "VALID";
export const INPUT_BAD_HEADERS = "The file headers do not match the template.";
export const INPUT_BAD_HEADERS_HELP = "Download Template";
export const INPUT_TOO_LONG_HELP = "Seeing this message in error?";
export const INPUT_NO_LEADS = "No leads were found in the selected file.";
export const INPUT_BLANK = "No data was found in the selected file.";
export const INPUT_COULD_NOT_PARSE = "An error occured while reading the file, is it a valid Excel file?";
export const INPUT_NOREAD = "An unknown error occured while reading the file.";
export const INPUT_NOT_XLSX = "Only *.xlsx files are supported at this time.";