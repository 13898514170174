import { createAsyncThunk } from "@reduxjs/toolkit"
import { postRequest } from "../utils/PostRequest"
import { appInsights } from "../../config/appInsights";
import { v4 as uuidv4 } from 'uuid';

export const createDomainMappingSingle = createAsyncThunk<any, any>('DomainMapping/CreateDomainMappingSingle', async (payload) => {
  var correlationId = uuidv4();
  appInsights.trackEvent({ name: "Sending request to DomainMapping/CreateDomainMappingSingle data", properties: { payload: JSON.stringify(payload), correlationId: correlationId, page: "Domain Mapping" } });
  const response = await postRequest<any, any>('DomainMapping/CreateDomainMappingSingle', payload, correlationId)
  response.data === null ? appInsights.trackEvent({ name: "Data not fetched from DomainMapping/CreateDomainMappingSingle api", properties: { data: JSON.stringify(response.data), correlationId: correlationId } }) : appInsights.trackEvent({ name: "Data fetched from DomainMapping/CreateDomainMappingSingle api", properties: {correlationId: correlationId } });
  return response.data
});

