import { createAsyncThunk } from "@reduxjs/toolkit"
import { postRequest } from "../utils/PostRequest"
import { appInsights } from "../../config/appInsights";
import { v4 as uuidv4 } from 'uuid';

export const deleteUnmatchedCompanyMapping = createAsyncThunk<any, any>('CompanyMapping/DeleteUnmatchedCompany', async (payload) => {
    var correlationId = uuidv4();
    appInsights.trackEvent({ name: "Sending request to CompanyMapping/DeleteUnmatchedCompany data", properties: { payload: JSON.stringify(payload), correlationId: correlationId, page: "Company Mapping" } });
    const response = await postRequest<any, any>(`CompanyMapping/DeleteUnmatchedCompany/${payload.id}`, payload, correlationId)
    response.data === null ? appInsights.trackEvent({ name: "Data not fetched from CompanyMapping/DeleteUnmatchedCompany api", properties: { data: JSON.stringify(response.data), correlationId: correlationId } }) : appInsights.trackEvent({ name: "Data fetched from CompanyMapping/DeleteUnmatchedCompany api", properties: {correlationId: correlationId } });
    return response.data
});