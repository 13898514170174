import { createAsyncThunk } from "@reduxjs/toolkit"
import { postRequestForExcel } from "../utils/PostRequest"
import { appInsights } from "../../config/appInsights";
import { v4 as uuidv4 } from 'uuid';


export const uploadLeadsForEnrichment = createAsyncThunk<any, any>('FileUpload/Submit', async (payload) => {
  var correlationId = uuidv4();
  appInsights.trackEvent({ name: "Sending request to FileUpload/Submit data", properties: { correlationId: correlationId, page: "Lead Enrichment" } });
  const response = await postRequestForExcel<any, any>('FileUpload/Submit', payload, correlationId)
  response.data === null ? appInsights.trackEvent({ name: "Data not fetched from FileUpload/Submit api", properties: { data: JSON.stringify(response.data), correlationId: correlationId } }) : appInsights.trackEvent({ name: "Data fetched from FileUpload/Submit api ", properties: { correlationId: correlationId } });
  return response.data
});

