import { Grid } from "../grid/grid";
import { ServerGrid } from "../grid/ServerGrid";
import { NotAuthorizedPage } from "../no-authorization-page/NotAuthorizedPage";
import { Spinner, SpinnerBetween } from "../../shared-components/loader/Spinner";
import { InputFields } from "./input-fields/InputFields";

import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../root-redux/RootState";
import { setIdForEditDelete, setErrorUploadMessage, clearErrorUploadMessage, clearErrorFetchMessage, setMappedCompany, setIsSuggestionsAvailable, setActiveTab, setValidationStatus, setEditDomainValue, setEditData, setIsUnmapped, clearMappedDomainRowData, clearUnmappedDomainRowData, setIsDataToBeEditedFound, clearNothingToShow, setSelectedCountry, setServerSidePaginationTotalRecordsCount, setServerSidePaginationTotalPages, setServerSidePaginationMinPage, setServerSidePaginationMaxPage, setServerSidePaginationSearchTerm, setServerSidePaginationSortOrder, setServerSidePaginationSortType, setIsCertifiedUser, setServerSidePaginationCurrentPage, setViewCompanySuggestions, setMsSalesAccountId, setMsxAccountNumber, setAccountName, clearSuccessMessage, setCompanyStatusFilter, setCertifiedFilter, setCertifiedRowCheck, clearError, clearCompanyList, setDomainMappingManualPageLoaded } from "./domain-mapping-redux/DomainMapping.redux";
import { setAuthorizedCountriesForUser } from "../../shared-components/user-language-country-data/UserLanguageCountryData.redux";

import { Text, Tab, Tabs, TabPanel, Icon, Button, Dialog } from "../../common/HarmonyEnablers";

import { RequestStatus } from '../../model/ServiceRequestStatus.model'
import { updateDomainMappingSingle } from "../../services/DomainMapping/PostUpdateDomainMappingSingle.api";
import { deleteDomainMappingSingle } from "../../services/DomainMapping/PostDeleteDomainMapping.api";
import { getUnmappedDomainMappings } from "../../services/DomainMapping/GetUnmappedDomainMappings.api";
import { getBulkUploadsAll } from "../../services/DomainMapping/GetBulkUploadsAll.api";
import { postDomainMappingBulkFile } from "../../services/DomainMapping/PostDomainMappingBulkFile.api";
import { getAuthorizedCountryData } from "../../services/CommonApi/GetAuthorizedCountryData.api";
import { getDomainMappingCount } from "../../services/DomainMapping/GetDomainMappingCount.api";
import { getPagedDomainMappings } from "../../services/DomainMapping/GetPagedDomainMappings.api";
import { postAllDomainMappingsToExcel } from "../../services/DomainMapping/PostAllDomainMappingsToExcel.api";
import { postAllUnmappedDomainsToExcel } from "../../services/DomainMapping/PostAllUnmappedDomainsToExcel.api";
import { resetState } from "./domain-mapping-redux/DomainMapping.redux";
import { getExcelFile } from "../../services/DomainMapping/GetExcelFile.api";
import { getDomainMappingTemplate } from "../../services/DomainMapping/GetDomainMappingTemplate.api";
import { getDomainMappingInputFile } from "../../services/DomainMapping/GetDomainMappingInputFile.api";
import { getDomainMappingProcessedFile } from "../../services/DomainMapping/GetDomainMappingProcessedFile.api";

import * as XLSX from "xlsx";
import * as Constants from "../inputValidation/Constants";
import { ValidateFileData, ValidateFile } from "../inputValidation/ExcelValidation";

import "../domain-mapping/DomainMapping.css";


export const DomainMapping: React.FC = () => {
    const [notAuthorized, setNotAuthorized] = useState<boolean>(false);
    const [isPermissionsFetched, setIsPermissionsFetched] = useState<boolean>(false);
    const [createMapping, setCreateMapping] = useState<boolean>(false);
    const [editMapping, setEditMapping] = useState<boolean>(false);
    const [onCreateRes, setOnCreateRes] = useState<string>("");
    const [onCreateStatus, setOnCreateStatus] = useState<string>("");
    const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState<boolean>(false);
    const [isOpenCertifiedDialog, setIsOpenCertifiedDialog] = useState<boolean>(false);
    const [editDeleteRow, setEditDeleteRow] = useState<any>({});
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const SearchTerm_FETCHALL = '<ALL>';

    const { activeTab, error, idForEditDelete, isCertifiedUser, getAuthorizedCountryDataStatus, getBulkUploadsAllStatus, getPagedDomainMappingsStatus, getUnmappedDomainMappingsStatus, getDomainMappingCountStatus, itemsPerPage, activateSearch, mappedDomainRowData, mappedDomainColumnData, unmappedDomainColumnData, bulkDomainRowData, bulkDomainColumnData, unmappedDomainRowData, editData, isUnmapped, isDataToBeEditedFound, selectedCountry, serverSidePagination, errorUpload, errorFetch, successMsg, nothingToShow, certifiedRowCheck, domainMappingManualPageLoaded } = useSelector((state: RootState) => state.domainMapping);
    const { isNavPanelOpen } = useSelector((state: RootState) => state.nav);
    const { authorizedCountriesForUser } = useSelector((state: RootState) => state.userLanguageCountryData);

    const dispatch = useDispatch();
    const dispatchApp = useDispatch<AppDispatch>();

    const divRefForEditManual = useRef<HTMLDivElement>(null);
    const divRefForEditUnmapped = useRef<HTMLDivElement>(null);

    const isFileSelected = selectedFile !== null;

    //Selecting country once country changes
    const handleAuthCountryDropdownChange = (event: any) => {
        dispatch(setSelectedCountry({ DisplayName: event.target.value, Value: event.target.value, IsEnabled: true }));// = event.target.value;
        dispatch(setDomainMappingManualPageLoaded(false));
        setCreateMapping(false);
        setEditMapping(false);
    };

    //API call to delete a record and get the latest data
    const handleDelete = async () => {
        var payload = { "id": idForEditDelete }
        await dispatchApp(deleteDomainMappingSingle(payload));
        resetMessagesAfterFiveSeconds();
        getLatestData("", serverSidePagination.sortType, serverSidePagination.sortOrder, serverSidePagination.companyStatusFilter, serverSidePagination.certifiedStatusFilter);
    };

    //API Call to fetch domains which are unmapped(in the Unmapped tab)
    const fetchUnmappedDomains = async () => {
        resetErrorMessages();
        dispatch(clearNothingToShow());
        try {
            const payload = { "country": selectedCountry.Value }
            await dispatchApp(getUnmappedDomainMappings(payload));
            resetMessagesAfterFiveSeconds();
            dispatch(setIsUnmapped(true));
        } catch (err) {
            return err;
        }
    };

    //API call to get the domain mapping template
    const downloadTemplate = async () => {
        await dispatchApp(getDomainMappingTemplate());
        resetMessagesAfterFiveSeconds();
    };

    //API call to post the domain mapping data to excel template
    const uploadToExcel = async () => {
        resetErrorMessages();
        try {
            const payload = { "country": selectedCountry.Value }
            const res = activeTab === "manual" ? await dispatchApp(postAllDomainMappingsToExcel(payload)) : await dispatchApp(postAllUnmappedDomainsToExcel(payload));
            const filename = res.payload.fileName;
            var payloadForExcel = { "fileName": filename };
            await dispatchApp(getExcelFile(payloadForExcel));
        }
        catch (err) {
            return error;
        }
    };


    //API call to download input and processed file for bulk upload grid
    const handleDownload = async (rowid: number, type: any, fileName: any) => {
        resetErrorMessages();
        try {
            const payload = { "fileName": fileName };
            if (type === "inputfile") {
                await dispatchApp(getDomainMappingInputFile(payload));
                resetMessagesAfterFiveSeconds();

            }
            if (type === "processedfile") {
                await dispatchApp(getDomainMappingProcessedFile(payload));
                resetMessagesAfterFiveSeconds();
            }
        }
        catch (err) {
            return error;
        }
    };

    //Setting the total pages for pagination
    const getTotalPages = async (totalRecords: number) => {
        const totalPages = Math.ceil(totalRecords / itemsPerPage);
        dispatch(setServerSidePaginationTotalPages(totalPages));
        dispatch(setServerSidePaginationMinPage(1));
        dispatch(setServerSidePaginationMaxPage(Math.min(1000 / itemsPerPage, Math.ceil(totalRecords / itemsPerPage))));
        dispatch(setServerSidePaginationTotalRecordsCount(totalRecords));
    };

    //API call to get the latest data for pagination
    const getLatestData = async (searchTerm: string, sortType: string, sortOrder: string, companyStatusFilter: string, CertifiedStatusFilter: string) => {
        resetErrorMessages();
        const payload = {
            country: selectedCountry.Value,
            SearchTerm: searchTerm.length > 0 ? searchTerm : SearchTerm_FETCHALL,
            companyStatusFilter: companyStatusFilter,
            certifiedFlagFilter: CertifiedStatusFilter
        }
        const response = await dispatchApp(getDomainMappingCount(payload));
        await getTotalPages(response.payload.Response);
        GetPagedDomainMappingsByCountry(0, searchTerm, sortType, sortOrder, companyStatusFilter, CertifiedStatusFilter);
    };

    //API Call to fetch domains which are mapped(in the Mapped tab)
    const fetchMappedDomains = async () => {
        resetErrorMessages();
        try {
            if (selectedCountry.Value) {
                let searchTerm = "";
                if (activateSearch) {
                    searchTerm = searchTerm?.toLowerCase()
                }
                else {
                    searchTerm = SearchTerm_FETCHALL
                }

                getLatestData("", "ModifiedDate", "DESC", "Active", "All");
                dispatch(setIsUnmapped(false));
            }
        } catch (err) {
            return err;
        }
    };

    //API call to get domain mappings page wise
    const GetPagedDomainMappingsByCountry = async (offsetRowsCount: number, searchTerm: string, sortType: string, sortOrder: string, companyStatusFilter: string, certifiedStatusFilter: string) => {
        dispatch(clearErrorFetchMessage());
        let payload = {
            country: selectedCountry.DisplayName,
            pageSize: serverSidePagination.pageSize,
            offsetRowsCount: offsetRowsCount,
            sortType: sortType,
            sortOrder: sortOrder,
            searchTerm: searchTerm.length > 0 ? searchTerm : SearchTerm_FETCHALL,
            CompanyStatusFilter: companyStatusFilter ? companyStatusFilter : "Active",
            CertifiedFlagFilter: certifiedStatusFilter ? certifiedStatusFilter : "All"
        }
        await dispatchApp(getPagedDomainMappings(payload));
        resetMessagesAfterFiveSeconds();

    };

    //API call to fetch the authorized countries for the user
    const fetchAuthorizedCountry = async () => {
        try {
            var currentPage = "Domain Mapping";
            if (authorizedCountriesForUser.length === 0) {
                const response = await dispatchApp(getAuthorizedCountryData(currentPage));
                if (response.payload.Error) {
                    throw new Error(response.payload.Error);
                }
                else {
                    const authorizedCountries = response.payload.map((countryData: any) => ({
                        DisplayName: countryData.DisplayName,
                        Value: countryData.Value,
                        IsEnabled: countryData.IsEnabled,
                    }));
                    dispatch(setAuthorizedCountriesForUser(authorizedCountries));
                    dispatch(setSelectedCountry(authorizedCountries[0]));
                }
            }
            else {
                dispatch(setSelectedCountry(authorizedCountriesForUser[0]));
            }
            resetMessagesAfterFiveSeconds();

            var userGroupsFromStorage = window.localStorage.getItem("user_groups");
            if (userGroupsFromStorage) {
                var userGroups = JSON.parse(userGroupsFromStorage);
                if (userGroups && userGroups.includes("DomainCertifiedUser")) {
                    dispatch(setIsCertifiedUser(true));
                }
            }
        } catch (err) {
            return err;
        }
    };

    //On click of edit button for the row to be edited open the confirmation dialog box
    const handleDeleteButtonClick = (row: any) => {
        dispatch(setIdForEditDelete(row.data.MappingId));
        setIsOpenDeleteDialog(true);
    };

    //Function to handle the file selection for bulk upload
    const handleFileChange = (event: any) => {
        const fileInput = event.target;
        const file = event.target.files![0];
        const fileValidationStatus = ValidateFile(file);
        if (fileValidationStatus !== Constants.INPUT_VALID) {
            dispatch(setValidationStatus(fileValidationStatus));
            dispatch(setErrorUploadMessage(fileValidationStatus));
            event.target.value = "";
            return;
        }
        const reader = new FileReader();
        reader.onload = (event) => {
            const bstr = event.target!.result;
            let workbook;
            try {
                workbook = XLSX.read(bstr, { type: 'binary' });
            } catch (e) {
                dispatch(setValidationStatus(Constants.INPUT_COULD_NOT_PARSE));
                dispatch(setErrorUploadMessage(fileValidationStatus));
                fileInput.value = "";
                return;
            }

            const fileDataValidationStatus = ValidateFileData(workbook, "DomainMapping", isCertifiedUser);
            if (fileDataValidationStatus !== Constants.INPUT_VALID) {
                dispatch(setValidationStatus(fileDataValidationStatus));
                dispatch(setErrorUploadMessage(fileDataValidationStatus));
                fileInput.value = "";
                return;
            }
            if (!error) {
                setSelectedFile(file);
            }
        }
        reader.readAsBinaryString(file);

    };

    //API to submit the bulk upload file
    const submitBulkFileUpload = async (event: any) => {
        event.preventDefault();
        if (selectedFile) {
            var fileData = new FormData();
            fileData.append(selectedFile.name, selectedFile, selectedFile.name);
            await dispatchApp(postDomainMappingBulkFile(fileData));
            if (event.target) {
                (event.target as HTMLFormElement).reset();
            }
            fetchBulkUploadData();
            setSelectedFile(null);
        }
    };

    //API to fetch the bulk upload data
    const fetchBulkUploadData = async () => {
        dispatch(clearErrorFetchMessage());
        try {
            const payload = {};
            dispatchApp(getBulkUploadsAll(payload));
            resetMessagesAfterFiveSeconds();
        }
        catch (err) {
            return err;
        }
    };

    const handleCertifiedCheck = async () => {
        if (isCertifiedUser) {
            var payload = { "id": certifiedRowCheck.id, "domain": certifiedRowCheck.cells.Domain, "mssalesId": certifiedRowCheck.cells.TPID === "" ? null : certifiedRowCheck.cells.TPID, "country": selectedCountry.Value, "crmId": certifiedRowCheck.cells.CRMId === "" ? null : certifiedRowCheck.cells.CRMId, "isCertified": !certifiedRowCheck.cells.certified };

            await dispatchApp(updateDomainMappingSingle(payload));
            resetMessagesAfterFiveSeconds();
            getLatestData(serverSidePagination.searchTerm, serverSidePagination.sortType, serverSidePagination.sortOrder, serverSidePagination.companyStatusFilter, serverSidePagination.certifiedStatusFilter);
        }
    };

    //On click of certified button  set the values in the edit window
    const handleCertifiedButtonClick = async (row: any) => {
        resetErrorMessages();
        dispatch(setCertifiedRowCheck(row));
        setIsOpenCertifiedDialog(true);
    };

    //On click of edit button  set the values in the edit window
    const handleEditButtonClick = async (row: any) => {
        if (activeTab === 'manual' && divRefForEditManual.current) {
            divRefForEditManual.current.scrollIntoView({ behavior: 'smooth' });
        }
        if (activeTab === 'unmapped' && divRefForEditUnmapped.current) {
            divRefForEditUnmapped.current.scrollIntoView({ behavior: 'smooth' });
        }
        resetErrorMessages();
        dispatch(setMsxAccountNumber(""));
        dispatch(setMsSalesAccountId(""));
        dispatch(setAccountName(""));
        dispatch(setMappedCompany(""));
        dispatch(setViewCompanySuggestions(false));
        dispatch(setIsDataToBeEditedFound(true));
        if (activeTab === "manual") {
            dispatch(setIsUnmapped(false));

        }
        else if (activeTab === "unmapped") {
            dispatch(setIsUnmapped(true));
            dispatch(setIsSuggestionsAvailable(row.data.CompanyName === 'Yes' ? true : false));

        }
        dispatch(setEditDomainValue(row.cells.Domain))
        dispatch(setIdForEditDelete(row.data.MappingId));
        setCreateMapping(false);
        setEditMapping(true);
        setEditDeleteRow(row);
        setEditData(true);

    };

    //Function to handle clear button on Bulk Upload tab
    const handleClear = (event: any): void => {
        if (event.target) {
            (event.target as HTMLFormElement).reset();
        }
        setSelectedFile(null);
    };

    const handleCloseSuccessMsg = (): void => {
        dispatch(clearSuccessMessage());
    };
    const handleCloseUploadError = (): void => {
        dispatch(clearErrorUploadMessage());
    };
    const handleCloseFetchError = (): void => {
        dispatch(clearErrorFetchMessage());
    };

    const resetErrorMessages = (): void => {
        dispatch(clearError());
        dispatch(clearErrorUploadMessage());
        dispatch(clearErrorFetchMessage());
    };

    const resetMessagesAfterFiveSeconds = (): void => {
        setTimeout(() => {
            dispatch(clearSuccessMessage());
        }, 5000);
    };

    useEffect(() => {
        var userGroupsFromStorage = window.localStorage.getItem("user_groups");
        if (userGroupsFromStorage) {
            var parsedJSONgroups = JSON.parse(userGroupsFromStorage);
            if (parsedJSONgroups.length > 0 && (parsedJSONgroups.includes("PortalWideUser") || parsedJSONgroups.includes("CertifiedDomainUser") || parsedJSONgroups.includes("SuperAdmin"))) {
                fetchAuthorizedCountry();
            }
            else {
                setNotAuthorized(true);
            }
            setIsPermissionsFetched(true);
        }
    }, []);

    useEffect(() => {
        if (activeTab === "unmapped" && selectedCountry.DisplayName !== "") {
            fetchUnmappedDomains();
        }
        else if (activeTab === "manual" && selectedCountry.DisplayName !== "") {
            dispatch(setServerSidePaginationSearchTerm(""));
            dispatch(setServerSidePaginationSortOrder("DESC"));
            dispatch(setServerSidePaginationSortType("ModifiedDate"));
            dispatch(setServerSidePaginationCurrentPage(1));
            fetchMappedDomains();
        }
        else if (activeTab === "bulk") {
            fetchBulkUploadData();
        }
    }, [selectedCountry, activeTab]);

    useEffect(() => {
        resetErrorMessages();
        dispatch(clearSuccessMessage());
    }, [activeTab]);

    useEffect(() => {
        return () => {
            dispatch(resetState());
        };
    }, [dispatch]);


    return (
        isPermissionsFetched ?
            notAuthorized ? <NotAuthorizedPage /> :
                <div className={isNavPanelOpen ? "domain-mapping-wrapper-collapsed" : "domain-mapping-wrapper"}>
                    <div className={isNavPanelOpen ? "domain-mapping-header-collapsed" : "domain-mapping-header"}>
                        <div> <Text role="heading" aria-level={1} appearance="heading-2" className="domain-mapping-heading">Domain Mapping</Text></div>
                        <Tabs active-id={activeTab} style={{ marginTop: "10px" }}>
                            <Tab id="manual" onClick={() => { dispatch(setActiveTab("manual")); dispatch(clearUnmappedDomainRowData()); dispatch(setIsUnmapped(false)); setCreateMapping(false); setEditMapping(false); dispatch(setIsDataToBeEditedFound(false)); dispatch(clearNothingToShow()) }} onKeyDown={(e) => { if (e.key === 'Enter') { dispatch(setActiveTab("manual")); dispatch(clearUnmappedDomainRowData()); dispatch(setIsUnmapped(false)); setCreateMapping(false); setEditMapping(false); dispatch(setIsDataToBeEditedFound(false)); dispatch(clearNothingToShow()) } }}>Manual</Tab>
                            <Tab id="bulk" onClick={() => { dispatch(setDomainMappingManualPageLoaded(false)); dispatch(setActiveTab("bulk")); dispatch(setCompanyStatusFilter('Active')); dispatch(setCertifiedFilter('All')); setCreateMapping(false); setEditMapping(false); dispatch(clearNothingToShow()) }} onKeyDown={(e) => { if (e.key === 'Enter') { dispatch(setActiveTab("bulk")); setCreateMapping(false); setEditMapping(false); dispatch(clearNothingToShow()) } }}>Bulk</Tab>
                            <Tab id="unmapped" onClick={() => { dispatch(setDomainMappingManualPageLoaded(false)); dispatch(setActiveTab("unmapped")); dispatch(setCompanyStatusFilter('Active')); dispatch(setCertifiedFilter('All')); fetchUnmappedDomains(); dispatch(clearMappedDomainRowData()); dispatch(setIsUnmapped(true)); setCreateMapping(false); setEditMapping(false); dispatch(clearNothingToShow()) }} onkeydown={(e) => { if (e.key === 'Enter') { dispatch(setActiveTab("unmapped")); fetchUnmappedDomains(); dispatch(clearMappedDomainRowData()); dispatch(setIsUnmapped(true)); setCreateMapping(false); setEditMapping(false); dispatch(clearNothingToShow()); } }}>Unmapped</Tab>
                            <TabPanel>

                                <div className={isNavPanelOpen ? "domain-mapping-manual-collapsed" : "domain-mapping-manual"}>
                                    <div className={isNavPanelOpen ? "domain-mapping-select-country-collapsed" : "domain-mapping-select-country"} ref={divRefForEditManual}>
                                        <Text role="heading" aria-level={3} appearance="heading-4" className="domain-mapping-heading" >Select Country / Region</Text>
                                        <div className='input-row'>

                                            <select className={isNavPanelOpen ? "domain-mapping-authorized-countries-collapsed" : "domain-mapping-authorized-countries"} id="authorizedCountryDropdownManual" value={selectedCountry.DisplayName} onChange={handleAuthCountryDropdownChange} aria-label="Select a country/Region" aria-labelledby="authorizedCountryDropdownManual">

                                                {authorizedCountriesForUser?.map(country => (
                                                    <option className="domain-mapping-authorized-countries-menu" key={country.Value}
                                                        value={country.DisplayName} onClick={handleAuthCountryDropdownChange} aria-selected={selectedCountry.DisplayName === country.DisplayName}
                                                    >{country.DisplayName}
                                                    </option>
                                                ))
                                                }
                                            </select>
                                        </div>
                                        {
                                            getAuthorizedCountryDataStatus === RequestStatus.loading && <Spinner />
                                        }
                                    </div>
                                    <Button className={isNavPanelOpen ? "domain-mapping-create_btn-collapsed" : "domain-mapping-create_btn"} appearance="primary" onClick={() => { dispatch(setViewCompanySuggestions(false)); dispatch(setMappedCompany("")); dispatch(setMsxAccountNumber("")); dispatch(setMsSalesAccountId("")); dispatch(setAccountName("")); dispatch(clearCompanyList()); dispatch(clearError()); dispatch(setIsDataToBeEditedFound(false)); setEditMapping(false); setCreateMapping(true); setEditData(false); resetErrorMessages() }}>
                                        <Icon name="contact" slot="start"></Icon>
                                        <Text style={{ color: "white" }}>Create New Mapping</Text>
                                    </Button>
                                </div>

                            </TabPanel>
                            <TabPanel>
                                <div>
                                    <Text role="heading" aria-level={3} appearance="heading-4" className="domain-mapping-heading" style={{ marginTop: "20px" }}>Upload File</Text>
                                    <Text appearance="heading-6" className="domain-mapping-heading">Please select a file to upload. The standard template can be downloaded here: <a href="#" onClick={downloadTemplate} style={{ color: "#0b62e4" }}>Download Template</a></Text>
                                </div>
                                <form onSubmit={submitBulkFileUpload} onReset={handleClear}>
                                    <div className={isNavPanelOpen ? "input-field-collapsed" : "input-field"}>
                                        <div className={isNavPanelOpen ? "input-text-collapsed" : "input-text"}>
                                            <input type="text"
                                                placeholder="Select files to upload"
                                                onClick={() => { resetErrorMessages(); document.getElementById("file-input")!.click() }}
                                                value={selectedFile ? selectedFile.name : ""}
                                                readOnly />
                                        </div>
                                        <div className="input-file">
                                            <input type="file" id="file-input" onChange={handleFileChange} hidden />
                                            <label htmlFor="file-input" className="custom-button" tabIndex={0} onClick={resetErrorMessages} onKeyDown={(e) => {
                                                if (e.key === "Enter") {
                                                    resetErrorMessages();
                                                    document.getElementById("file-input")!.click();
                                                }
                                            }}>Select</label>
                                        </div>
                                    </div>
                                    <div>
                                        <Button className="button" type="submit" appearance="primary" disabled={!isFileSelected} tabIndex={selectedFile ? 0 : -1}>
                                            <Text>Submit</Text>
                                        </Button>
                                        <Button type="reset" className="button" disabled={!isFileSelected} tabIndex={selectedFile ? 0 : -1}>
                                            <Text>Clear</Text>
                                        </Button>
                                    </div>
                                </form>

                            </TabPanel>
                            <TabPanel>
                                <div className={isNavPanelOpen ? "domain-mapping-select-country-collapsed" : "domain-mapping-select-country"} style={{ marginTop: "20px" }} ref={divRefForEditUnmapped}>
                                    <Text role="heading" aria-level={3} appearance="heading-4" className="domain-mapping-heading">Select Country / Region</Text>

                                    <select className="domain-mapping-authorized-countries" id="authorizedCountryDropdownUnmapped" value={selectedCountry.DisplayName} onChange={handleAuthCountryDropdownChange} aria-label="Select a country" aria-labelledby="authorizedCountryDropdown">
                                        {authorizedCountriesForUser?.map(country => (
                                            <option className="domain-mapping-authorized-countries-menu" key={country.Value} value={country.DisplayName} onClick={handleAuthCountryDropdownChange} aria-selected={selectedCountry.DisplayName === country.DisplayName} >{country.DisplayName}
                                            </option>
                                        ))
                                        }
                                    </select>

                                </div>
                            </TabPanel>

                        </Tabs>
                        {
                            createMapping && (
                                <>
                                    <Text role="heading" aria-level={2} appearance="heading-3" className="domain-mapping-heading" style={{ "marginTop": "20px", "marginBottom": "20px" }}>Create Mapping</Text>
                                    <InputFields setCreateMapping={setCreateMapping} setEditMapping={setEditMapping} GetPagedDomainMappingsByCountry={GetPagedDomainMappingsByCountry} setOnCreateRes={setOnCreateRes} setOnCreateStatus={setOnCreateStatus} setEditData={setEditData} setIsUnmapped={setIsUnmapped} editData={editData} isUnmapped={isUnmapped} fetchUnmappedDomains={fetchUnmappedDomains} />
                                </>
                            )
                        }
                        {
                            editMapping && isDataToBeEditedFound && (
                                <>
                                    <Text role="heading" aria-level={2} appearance="heading-3" className="domain-mapping-heading" style={{ "marginTop": "20px", "marginBottom": "20px" }}>Edit Existing Mapping</Text>
                                    <InputFields setCreateMapping={setCreateMapping} setEditMapping={setEditMapping} setOnCreateRes={setOnCreateRes} setOnCreateStatus={setOnCreateStatus} setEditData={setEditData} GetPagedDomainMappingsByCountry={GetPagedDomainMappingsByCountry} setIsUnmapped={setIsUnmapped} editData={true} isUnmapped={isUnmapped} fetchUnmappedDomains={fetchUnmappedDomains} />
                                </>
                            )
                        }
                    </div>
                    {
                        successMsg !== undefined && (
                            <div className="domain-mapping-success">
                                <span><p>{successMsg}</p></span>
                                <Icon name="chromeclose" onClick={handleCloseSuccessMsg} onkeydown={(e) => { if (e.key === 'Enter') { handleCloseSuccessMsg() } }} className="domain-mapping-close-icon" tabIndex={0}></Icon>
                            </div>
                        )
                    }
                    {errorUpload !== undefined && (
                        <div className="domain-mapping-error">
                            <span><p>{errorUpload}</p></span>
                            <Icon name="chromeclose" onClick={handleCloseUploadError} onkeydown={(e) => { if (e.key === 'Enter') { handleCloseUploadError() } }} className="domain-mapping-close-icon" tabIndex={0}></Icon>
                        </div>
                    )}
                    {errorFetch !== undefined && (
                        <div className="domain-mapping-error">
                            <span><p>{errorFetch}</p></span>
                            <Icon name="chromeclose" onClick={handleCloseFetchError} onkeydown={(e) => { if (e.key === 'Enter') { handleCloseFetchError() } }} className="domain-mapping-close-icon" tabIndex={0}></Icon>
                        </div>
                    )}
                    {activeTab === "manual" && (getPagedDomainMappingsStatus === RequestStatus.loading || getDomainMappingCountStatus===RequestStatus.loading) && <div><SpinnerBetween /></div>}
                    {activeTab === "manual" && getPagedDomainMappingsStatus === RequestStatus.succeeded && domainMappingManualPageLoaded && <ServerGrid columnData={mappedDomainColumnData} rowData={mappedDomainRowData} handleDownload={handleDownload} handleCertifiedButtonClick={handleCertifiedButtonClick} handleDeleteButtonClick={handleDeleteButtonClick} handleEditButtonClick={handleEditButtonClick} page="domainMappingManual" isExcelUpload={true} uploadToExcel={uploadToExcel} totalPages={serverSidePagination.totalPages} GetPagedDomainMappingsByCountry={GetPagedDomainMappingsByCountry} getLatestData={getLatestData} />}
                    {activeTab === "unmapped" && getUnmappedDomainMappingsStatus === RequestStatus.loading && <div><SpinnerBetween /></div>}
                    {activeTab === "unmapped" && getUnmappedDomainMappingsStatus === RequestStatus.succeeded && unmappedDomainRowData.length !== 0 && <Grid columnData={unmappedDomainColumnData} rowData={unmappedDomainRowData} handleDownload={handleDownload} handleDeleteButtonClick={handleDeleteButtonClick} handleEditButtonClick={handleEditButtonClick} page="domainMapping" isExcelUpload={true} uploadToExcel={uploadToExcel} sortType="HitCount" isAscending={false} />}
                    {activeTab === "bulk" && getBulkUploadsAllStatus === RequestStatus.loading && <div><SpinnerBetween /></div>}
                    {activeTab === "bulk" && getBulkUploadsAllStatus === RequestStatus.succeeded && bulkDomainRowData.length !== 0 && <Grid columnData={bulkDomainColumnData} rowData={bulkDomainRowData} handleDownload={handleDownload} handleDeleteButtonClick={handleDeleteButtonClick} handleEditButtonClick={handleEditButtonClick} page="domainMappingBulk" sortType="UploadedOn" isAscending={false} />}
                    {
                        nothingToShow !== undefined && (
                            <Text appearance="heading-3">{nothingToShow}</Text>
                        )
                    }
                    <Dialog
                        no-header
                        className="dialog-no-header"
                        open={isOpenDeleteDialog}
                        onHeAfterHide={
                            ({ target, currentTarget }) => (target === currentTarget ? setIsOpenDeleteDialog(false) : null)
                        }
                    >
                        <p>
                            Do you really want to delete this domain mapping?
                        </p>

                        <Button slot="footer" appearance="primary" onClick={() => { setIsOpenDeleteDialog(false); handleDelete() }}>
                            Ok
                        </Button>
                        <Button slot="footer" onClick={() => setIsOpenDeleteDialog(false)}>
                            Close
                        </Button>
                    </Dialog>

                    <Dialog
                        no-header
                        className="dialog-no-header"
                        open={isOpenCertifiedDialog}
                        onHeAfterHide={
                            ({ target, currentTarget }) => (target === currentTarget ? setIsOpenCertifiedDialog(false) : null)
                        }
                    >
                        <p>
                            This mapping will be updated from {certifiedRowCheck.cells?.certified ? "Certified" : "Non-Certified"} to {certifiedRowCheck.cells?.certified ? "Non-Certified" : "Certified"}, proceed?
                        </p>

                        <Button slot="footer" appearance="primary" onClick={() => { setIsOpenCertifiedDialog(false); handleCertifiedCheck(); dispatch(setServerSidePaginationCurrentPage(1)) }}>
                            Ok
                        </Button>
                        <Button slot="footer" onClick={() => { setIsOpenCertifiedDialog(false) }}>
                            Close
                        </Button>
                    </Dialog>
                </div>
            :
            <></>
    )
};
