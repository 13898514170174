import { createAsyncThunk } from "@reduxjs/toolkit";
import { appInsights } from "../../config/appInsights";
import { getRequestForExcel } from "../utils/GetRequest";
import { DownloadExcelData } from "../utils/ExcelHandler";
import { v4 as uuidv4 } from 'uuid';

export const getCompanyMappingProcessedFile = createAsyncThunk<any, any>('CompanyMapping/GetCompanyMappingProcessedFile', async (payload: any) => {
  var correlationId = uuidv4();
  appInsights.trackEvent({ name: "Sending request to CompanyMapping/GetCompanyMappingProcessedFile data", properties: { correlationId: correlationId, page: "Company Mapping" } });
  await getRequestForExcel<string>(`CompanyMapping/GetCompanyMappingProcessedFile/${payload.fileName}`, correlationId)
    .then(response => {
      DownloadExcelData(response);
      appInsights.trackEvent({ name: "Data fetched from CompanyMapping/GetCompanyMappingProcessedFile api", properties: { correlationId: correlationId } })
    }).catch(error => {
      appInsights.trackEvent({ name: "Data fetched from CompanyMapping/GetCompanyMappingProcessedFile api", properties: { error: error, correlationId: correlationId } })
    });
})
