import './common/styles/global-styles.css';
import { AuthenticatedTemplate, MsalProvider, UnauthenticatedTemplate, useMsalAuthentication } from '@azure/msal-react';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { reactPlugin } from "./config/appInsights";
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import withAppInsights from './config/appInsights';
import { ErrorBoundary } from './shared-components/ErrorBoundary';
import { rootStore } from './root-redux/RootState';
import { Provider } from 'react-redux';
import { msalConfig } from './auth/authConfig';
import { SigningIn } from './core-components/signing-in/SigningIn';
import { AuthorizedApp } from './core-components/authorized-app/AuthorizedApp';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { HealthCheck } from './feature-components/healthCheck';

const Main: React.FC = () => {

  useMsalAuthentication(InteractionType.Redirect);

  return (

    <ErrorBoundary fallbackUi={<div>Could not load content.</div>}>

      <AuthenticatedTemplate>
        <AppInsightsContext.Provider value={reactPlugin}>
          <AuthorizedApp />
        </AppInsightsContext.Provider>
      </AuthenticatedTemplate>

      <UnauthenticatedTemplate>
        <SigningIn />
      </UnauthenticatedTemplate>

    </ErrorBoundary>

  );
};

const AppC: React.FC = () => {
  const msalInstance = new PublicClientApplication(msalConfig);
  const path = window.location.pathname;
  const parts = path.split('/');
  const health = parts[1];

  if (health === 'health') {
    return <HealthCheck />
  }

  return (
    <Provider store={rootStore}>
      <MsalProvider instance={msalInstance}>
        <Main />
      </MsalProvider>
    </Provider>
  )
}

export const App = withAppInsights(AppC)
