import {useEffect} from 'react';
import { Icon, Button, Tooltip } from '../../common/HarmonyEnablers';
import { NavMenuItemType } from '../../model/NavMenu.model';
import { useNavigate } from 'react-router-dom';
import './nav-panel-styles.css'
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../root-redux/RootState';
import { setIsNavPanelOpen } from './nav-panel-redux/NavPanel.actions.redux';

export const NavPanel: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const isNavPanelOpen = useSelector((state: RootState) => state.nav.isNavPanelOpen);
    const selectedNavItem = useSelector((state: RootState) => state.nav.selectedNavItem);

    const _onNavMenuItemClick = (navMenuItemType: NavMenuItemType) => {
        navigate("/"+ NavMenuItemType[navMenuItemType]);
    };

    const iconPath = '../assets/icons/';

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth > 1400) {
                dispatch(setIsNavPanelOpen(true));
            } else {
                dispatch(setIsNavPanelOpen(false));
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize();
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    return (
        <nav className={`nav-panel ${!isNavPanelOpen ? 'collapsed' : ''}`} role="navigation" aria-label="Primary">
            <div className='nav-menu-icon'>
                <Button appearance="stealth" onClick={() => dispatch(setIsNavPanelOpen(!isNavPanelOpen))} tabIndex={0}>
                    <Icon name="globalnavbutton" role='button' aria-label='menu-button' />
                </Button>
            </div>

            {/* Wrap your tab items in a parent with role="tablist" */}
            <div role="tablist">
                <div className={`nav-item ${selectedNavItem === NavMenuItemType.Home ? 'selected' : ''}`} onClick={() => _onNavMenuItemClick(NavMenuItemType.Home)} onKeyDown={e => e.key === 'Enter' ? _onNavMenuItemClick(NavMenuItemType.Home) : ''} tabIndex={0}
                    role="tab"
                    aria-selected={selectedNavItem === NavMenuItemType.Home ? "true" : "false"}
                    aria-label={selectedNavItem === NavMenuItemType.Home ? "Home" : "Home"}
                >
                    <div id='home' >
                        <Icon slot='start' name='homesolid'style={{ color: '#045EA4' }} ></Icon>
                        {isNavPanelOpen && (<span>Home</span>)}
                    </div>
                    <Tooltip anchor='home'>
                        Home
                    </Tooltip>
                </div>

                <div className={`nav-item ${selectedNavItem === NavMenuItemType.LeadEnrichment ? 'selected' : ''}`} onClick={() => _onNavMenuItemClick(NavMenuItemType.LeadEnrichment)} onKeyDown={e => e.key === 'Enter' ? _onNavMenuItemClick(NavMenuItemType.LeadEnrichment) : ''} tabIndex={0}
                    role="tab"
                    aria-selected={selectedNavItem === NavMenuItemType.LeadEnrichment ? "true" : "false"}
                    aria-label={selectedNavItem === NavMenuItemType.LeadEnrichment ? "Lead Enrichment" : "Lead Enrichment"}
                >
                    <div id='leadEnrichment' >
                        <Icon slot='start' url={iconPath+'lead-enrichment.svg'}></Icon>
                        {isNavPanelOpen && (<span>Lead Enrichment</span>)}
                    </div>
                    <Tooltip anchor='leadEnrichment'>
                        Lead Enrichment
                    </Tooltip>
                </div>

                <div className={`nav-item ${selectedNavItem === NavMenuItemType.CompanyMapping ? 'selected' : ''}`} onClick={() => _onNavMenuItemClick(NavMenuItemType.CompanyMapping)} onKeyDown={e => e.key === 'Enter' ? _onNavMenuItemClick(NavMenuItemType.CompanyMapping) : ''} tabIndex={0}
                    role="tab"
                    aria-selected={selectedNavItem === NavMenuItemType.CompanyMapping ? "true" : "false"}
                    aria-label={selectedNavItem === NavMenuItemType.CompanyMapping ? "Company Mapping" : "Company Mapping"}
                >
                    <div id='companyMapping' >
                        <Icon slot='start' url={iconPath+'company-mapping.svg'}></Icon>
                        {isNavPanelOpen && (<span>Company Mapping</span>)}
                    </div>
                    <Tooltip anchor='companyMapping'>
                        Company Mapping
                    </Tooltip>
                </div>

                {/* <div className={`nav-item ${selectedNavItem === NavMenuItemType.JobTitleMapping ? 'selected': ''}`} onClick={() => _onNavMenuItemClick(NavMenuItemType.JobTitleMapping)} onKeyDown={e => e.key === 'Enter' ? _onNavMenuItemClick(NavMenuItemType.JobTitleMapping): ''} tabIndex={0}>
          <div id='jobTitleMapping' >
            <Icon slot='start' url={iconPath+'jobtitle-mapping.svg'}></Icon>
            {isNavPanelOpen && (<span>Job Title Mapping</span>)}
          </div>
          <Tooltip anchor='jobTitleMapping'>
            Job Title Mapping
          </Tooltip>
        </div> */}

                <div className={`nav-item ${selectedNavItem === NavMenuItemType.JobTitleMappingV2 ? 'selected' : ''}`} onClick={() => _onNavMenuItemClick(NavMenuItemType.JobTitleMappingV2)} onKeyDown={e => e.key === 'Enter' ? _onNavMenuItemClick(NavMenuItemType.JobTitleMappingV2) : ''} tabIndex={0}
                    role="tab"
                    aria-selected={selectedNavItem === NavMenuItemType.JobTitleMappingV2 ? "true" : "false"}
                    aria-label={selectedNavItem === NavMenuItemType.JobTitleMappingV2 ? "Job Title Mapping" : "Job Title Mapping"}
                >
                    <div id='jobTitleMappingV2' >
                        <Icon slot='start' url={iconPath+'jobtitle-mapping.svg'}></Icon>
                        {isNavPanelOpen && (<span>Job Title Mapping</span>)}
                    </div>
                    <Tooltip anchor='jobTitleMappingV2'>
                        Job Title Mapping
                    </Tooltip>
                </div>

                <div className={`nav-item ${selectedNavItem === NavMenuItemType.DomainMapping ? 'selected' : ''}`} onClick={() => _onNavMenuItemClick(NavMenuItemType.DomainMapping)} onKeyDown={e => e.key === 'Enter' ? _onNavMenuItemClick(NavMenuItemType.DomainMapping) : ''} tabIndex={0}
                    role="tab"
                    aria-selected={selectedNavItem === NavMenuItemType.DomainMapping ? "true" : "false"}
                    aria-label={selectedNavItem === NavMenuItemType.DomainMapping ? "Domain Mapping" : "Domain Mapping"}
                >
                    <div id='domainMapping' >
                        <Icon slot='start' url={iconPath+'domain-mapping.svg'}></Icon>
                        {isNavPanelOpen && (<span>Domain Mapping</span>)}
                    </div>
                    <Tooltip anchor='domainMapping'>
                        Domain Mapping
                    </Tooltip>
                </div>

                <div className={`nav-item ${selectedNavItem === NavMenuItemType.DomainSuppression ? 'selected' : ''}`} onClick={() => _onNavMenuItemClick(NavMenuItemType.DomainSuppression)} onKeyDown={e => e.key === 'Enter' ? _onNavMenuItemClick(NavMenuItemType.DomainSuppression) : ''} tabIndex={0}
                    role="tab"
                    aria-selected={selectedNavItem === NavMenuItemType.DomainSuppression ? "true" : "false"}
                    aria-label={selectedNavItem === NavMenuItemType.DomainSuppression ? "Domain Suppression" : "Domain Suppression"}
                >
                    <div id='domainSuppression' >
                        <Icon slot='start' url={iconPath+'domain-supression.svg'}></Icon>
                        {isNavPanelOpen && (<span>Domain Suppression</span>)}
                    </div>
                    <Tooltip anchor='domainSuppression'>
                        Domain Suppression
                    </Tooltip>
                </div>
            </div>
        </nav>
    );
}