import * as XLSX from 'xlsx';
import * as Constants from "./Constants";

var fileReadSupported = false;
if (window.File && window.FileReader) {
  fileReadSupported = true;
}

export const ValidateFileData = (workbook: any, UploadFileSource: string, isCertifiedUser?: boolean): string => {
  //now that the workbook has been read, we can verify its contents
  /* Get first worksheet */
  var sheet = workbook.Sheets[workbook.SheetNames[0]];
  var rawRange = sheet['!ref'];
  if (!rawRange) { //if the sheet range is not defined, it is safe to assume the sheet is blank (or the file is corrupt)
    return Constants.INPUT_BLANK;

  }
  var sheetRange = XLSX.utils.decode_range(rawRange);
  //let's make sure the workbook has rows in it!
  if (!sheetRange) {
    return Constants.INPUT_BLANK;

  }
  //now we ensure that there is at least one lead in the file
  if (sheetRange.e.r === 0) {
    return Constants.INPUT_NO_LEADS;

  }
  //now we check the contents of the first row
  var columnsValid = false;
  for (var i = 0; i <= sheetRange.e.c; ++i) {
    var address = XLSX.utils.encode_cell({ c: i, r: 0 });
    var cell = sheet[address];
    if (UploadFileSource === "LeadEnrichment") {
      columnsValid = true;
      if (cell && (cell.v.toLowerCase() === "email" || cell.v.toLowerCase() === "country")) {
        columnsValid = columnsValid && true;
      }
      else {
        columnsValid = columnsValid && false;
        break;
      }
      break;
    }
    else if (UploadFileSource === "CompanyMapping") {
      columnsValid = true;
      if (cell && (cell.v.toLowerCase() === "inputcompanyname" || cell.v.toLowerCase() === "matchcompanyname" || cell.v.toLowerCase() === "crmaccountid")) {
        columnsValid = columnsValid && true;
      }
      else {
        columnsValid = columnsValid && false;
        break;
      }

    }
    else if (UploadFileSource === "DomainSuppression") {
      columnsValid = true;
      if (cell && (cell.v.toLowerCase() === "isp name" || cell.v.toLowerCase() === "isp link" || cell.v.toLowerCase() === "domain" || cell.v.toLowerCase() === "suppressed")) {
        columnsValid = columnsValid && true;
      }
      else {
        columnsValid = columnsValid && false;
        break;
      }

    }
    else if (UploadFileSource === "DomainMapping") {

      columnsValid = true;
      if (isCertifiedUser && (cell && (cell.v.toLowerCase() === "domain" || cell.v.toLowerCase() === "country" || cell.v.toLowerCase() === "crmid" || cell.v.toLowerCase() === "tpid" || cell.v.toLowerCase() === "iscertified(yes/no)"))) {
        columnsValid = columnsValid && true;
      }
      else if (!isCertifiedUser && (cell && (cell.v.toLowerCase() === "domain" || cell.v.toLowerCase() === "country" || cell.v.toLowerCase() === "crmid" || cell.v.toLowerCase() === "tpid"))) {
        columnsValid = columnsValid && true;
      }
      else {
        columnsValid = columnsValid && false;
        break;
      }

    }


  }
  if (!columnsValid) {
    return Constants.INPUT_BAD_HEADERS;

  }

  //and last, we check to make sure that there are not more than 2000 leads
  if (UploadFileSource === "LeadEnrichment") {
    if (sheetRange.e.r > Constants.MAX_LEADS) {
      var input_too_long_message =  "there are too many "+ Constants.LEADS+" in the file. There is currently a limit of " + Constants.MAX_LEADS + " "+ Constants.LEADS+" per file.";
      return input_too_long_message;
    }
  }
  else if(UploadFileSource === "CompanyMapping"){
    if (sheetRange.e.r > Constants.MAX_COMPANY_MAPPINGS) {
      var input_too_long_message =  "there are too many "+ Constants.COMPANY_MAPPINGS+" in the file. There is currently a limit of " + Constants.MAX_COMPANY_MAPPINGS + " "+ Constants.COMPANY_MAPPINGS+" per file.";
      return input_too_long_message;
    }
  }
  else if(UploadFileSource === "DomainMapping"){
    if (sheetRange.e.r > Constants.MAX_DOMAIN_MAPPINGS) {
      var input_too_long_message =  "there are too many "+ Constants.DOMAIN_MAPPINGS+" in the file. There is currently a limit of " + Constants.MAX_DOMAIN_MAPPINGS + " "+ Constants.DOMAIN_MAPPINGS+" per file.";
      return input_too_long_message;
    }
  }
  else if(UploadFileSource === "DomainSuppression"){
    if (sheetRange.e.r > Constants.MAX_DOMAIN_SUPRESSIONS) {
      var input_too_long_message =  "there are too many "+ Constants.DOMAIN_SUPPRESSIONS+" in the file. There is currently a limit of " + Constants.MAX_DOMAIN_SUPRESSIONS + " "+ Constants.DOMAIN_SUPPRESSIONS+" per file.";
      return input_too_long_message;
    }
  }

  //if we make it to this point, the file is valid!
  return Constants.INPUT_VALID;

  /* Convert array of arrays */
  const data = XLSX.utils.sheet_to_json(sheet);
}
export const ValidateFile = (file: any): string => {
  //first check if the file exists
  if (!file || file.name === "") {
    return Constants.INPUT_NOFILE;
  }
  //next check if the file name ends with .xlsx
  var ext = file.name.substring(file.name.lastIndexOf(".") + 1, file.name.length).toLowerCase();
  if (ext !== "xlsx") {
    return Constants.INPUT_NOT_XLSX;

  }
  //if we determined before that we cannot read files, we need to stop here
  if (!fileReadSupported) {
    return Constants.INPUT_VALID;
  }
  return Constants.INPUT_VALID;
}
