import { createAsyncThunk } from "@reduxjs/toolkit"
import { postRequest } from "../utils/PostRequest"
import { appInsights } from "../../config/appInsights";
import { v4 as uuidv4 } from 'uuid';

export const getTranslatedValues = createAsyncThunk<any, any>('JobTitleMapping/GetTranslatedValues', async (payload) => {
  var correlationId = uuidv4();
  appInsights.trackEvent({ name: "Sending request to JobTitleMapping/GetTranslatedValues data", properties: { payload: JSON.stringify(payload), correlationId: correlationId, page: "Job Title Mapping" } });
  const response = await postRequest<any, any>('JobTitleMapping/GetTranslatedValues', payload, correlationId)
  response.data === null ? appInsights.trackEvent({ name: "Data not fetched from JobTitleMapping/GetTranslatedValues api", properties: { data: JSON.stringify(response.data), correlationId: correlationId } }) : appInsights.trackEvent({ name: "Data fetched from JobTitleMapping/GetTranslatedValues api", properties: {correlationId: correlationId } });
  return response.data
});

