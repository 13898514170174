import axios from 'axios';
import { BASE_URL } from './Constants';
import { v4 as uuidv4 } from 'uuid';
import config from '../../config/config-dev';
import { read, writeFile } from 'xlsx';

export const getRequest = async<T>(apiPath: string, correlationId: any) => {
  const token = window.localStorage.getItem('api_token');
  try {
    const response = await axios.get<T>(`${process.env.REACT_APP_CDES_BASE_URL}/${apiPath}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "x-ms-correlation-id": correlationId
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const getRequestForExcel = async<T>(apiPath: string, correlationId: any) => {
  const token = window.localStorage.getItem('api_token');
  try {
    const response = await axios.get<T>(`${process.env.REACT_APP_CDES_BASE_URL}/${apiPath}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
        "Access-Control-Allow-Origin": "*",
        "x-ms-correlation-id": correlationId
      }
    });

    var workbook = read(response.data, { type: 'buffer' });
    return response;
  } catch (error) {
    throw error;
  }
};
