import { createAsyncThunk } from "@reduxjs/toolkit";
import { appInsights } from "../../config/appInsights";
import { postRequest } from "../utils/PostRequest";
import { v4 as uuidv4 } from 'uuid';


export const getJobTitleMappingData = createAsyncThunk<any, any>('JobTitleMappingV2/GetJobTitleMappingData', async (payload: any) => {
  var correlationId = uuidv4();
  appInsights.trackEvent({ name: "Sending request to JobTitleMappingV2/GetJobTitleMappingData data", properties: { payload: JSON.stringify(payload), correlationId: correlationId, page: "Job Title Mapping" } });
  const response = await postRequest<any, any>('JobTitleMappingV2/GetJobTitleMappingData', payload, correlationId);
  response.data === null ? appInsights.trackEvent({ name: "Data not fetched from JobTitleMappingV2/GetJobTitleMappingData api", properties: { data: JSON.stringify(response.data), correlationId: correlationId } }) : appInsights.trackEvent({ name: "Data fetched from JobTitleMappingV2/GetJobTitleMappingData api", properties: { correlationId: correlationId } });
  return response.data;
})
