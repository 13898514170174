import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { appInsights } from '../../../config/appInsights';

interface EnvState {
    selectedEnvItem: string
}
  
const envInitialState: EnvState = {
    selectedEnvItem: "dev"
}

export const envSlice = createSlice({
    name: 'env',
    initialState : envInitialState,
    reducers: {
      setEnvState: (state, action: PayloadAction<string>) => {
        state.selectedEnvItem = action.payload
        appInsights.trackEvent({name: "Setting Environment state", properties: {envSetItem: action.payload}});
      }
    }
});

export const envReducer = envSlice.reducer;