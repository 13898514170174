import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import { Home } from '../../feature-components/home/Home';
import { LeadEnrichment } from '../../feature-components/lead-enrichment/LeadEnrichment';
import { JobTitleMapping } from '../../feature-components/job-title-mapping/JobTitleMapping';
import { JobTitleMappingV2 } from '../../feature-components/job-title-mapping-v2/JobTitleMapping';
import { DomainSuppression } from '../../feature-components/domain-suppression/DomainSuppression';
import { CompanyMapping } from '../../feature-components/company-mapping/CompanyMapping';
import { DomainMapping } from '../../feature-components/domain-mapping/DomainMapping';
import { NavMenuItemType } from '../../model/NavMenu.model';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../root-redux/RootState';
import { setNavState } from '../nav-panel/nav-panel-redux/NavPanel.actions.redux';

const RootRouterC: React.FC = () => { 
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  useEffect(() => {
    const pathName = location.pathname.split('/')[1] || NavMenuItemType[NavMenuItemType.Home];
    dispatch(setNavState(NavMenuItemType[pathName as keyof typeof NavMenuItemType]));
  }, [location]);
   
  return ( 
    
    <Routes >
        <Route path="/" element={<Home />} />
        <Route path={"/"+NavMenuItemType[NavMenuItemType.Home]} element={<Home />} />
        <Route path={"/"+NavMenuItemType[NavMenuItemType.LeadEnrichment]} element={<LeadEnrichment />} />
        <Route path={"/"+NavMenuItemType[NavMenuItemType.CompanyMapping]} element={<CompanyMapping />} />
        <Route path={"/"+NavMenuItemType[NavMenuItemType.DomainMapping]} element={<DomainMapping />} />
        <Route path={"/"+NavMenuItemType[NavMenuItemType.DomainSuppression]} element={<DomainSuppression />} />
        <Route path={"/"+NavMenuItemType[NavMenuItemType.JobTitleMapping]} element={<JobTitleMapping />} />
        <Route path={"/"+NavMenuItemType[NavMenuItemType.JobTitleMappingV2]} element={<JobTitleMappingV2 />} />
    </Routes>  
    
  );
}

export const RootRouter = React.memo(RootRouterC);


