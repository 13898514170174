import * as React from 'react'
interface IErrorBoundaryProps{
    fallbackUi: React.ReactNode
    fallbackCallback?: (e: Error, errorInfo: React.ErrorInfo) => void
}

interface IErrorBoundaryState {
    hasError: boolean
}

/**
 * Setup error boundary for a React subtree.
 * Any error captured is considered unexpected thus logged. Provide fallbackUi prop
 * to display a alternative UI if error happens. DO NOT attempt to use this as a control flow.
 */
export class ErrorBoundary extends React.PureComponent<any, IErrorBoundaryState> {
    public static getDerivedStateFromError(): IErrorBoundaryState {
        return { hasError: true }
    }

    public constructor(props: IErrorBoundaryProps) {
        super(props)
        this.state = { hasError: false }
    }
    
    public componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
        if (this.props.fallbackCallback) {
                this.props.fallbackCallback(error, errorInfo)
        }
    }

    public render(): React.ReactNode {
        if (this.state.hasError) {
            // can render any custom fallback UI
            return this.props.fallbackUi
        }
        return this.props.children
    }
}