import { combineReducers } from "redux";
import { navReducer } from "../core-components/nav-panel/nav-panel-redux/NavPanel.redux";
import { userDetailsReducer } from "../shared-components/user-details/UserDetails.redux";
import { userGroupsReducer } from "../shared-components/user-groups/UserGroups.redux";
import { envReducer } from "../core-components/user-profile/user-profile-redux/UserProfile.redux";
import leadEnrichmentReducer  from "../feature-components/lead-enrichment/lead-enrichment-redux/LeadEnrichment.redux";
import { JobTitleMappingReducer } from "../feature-components/job-title-mapping/job-title-mapping-redux/JobTitleMapping.redux";
import { JobTitleMappingV2Reducer } from "../feature-components/job-title-mapping-v2/job-title-mapping-redux/JobTitleMapping.redux"; 
import domainSuppressionReducer from "../feature-components/domain-suppression/domain-suppression-redux/DomainSuppression.redux";
import companyMappingReducer from "../feature-components/company-mapping/company-mapping-redux/CompanyMapping.redux";
import { DomainMappingReducer } from "../feature-components/domain-mapping/domain-mapping-redux/DomainMapping.redux";
import { userLanguageCountryDataReducer } from "../shared-components/user-language-country-data/UserLanguageCountryData.redux";

export const rootReducer = combineReducers({ 
    nav: navReducer, 
    env: envReducer, 
    userGroups: userGroupsReducer,
    userDetails: userDetailsReducer,
    //cdes portal data
    leadEnrichment: leadEnrichmentReducer,
    jobTitleMapping: JobTitleMappingReducer,
    jobTitleMappingV2: JobTitleMappingV2Reducer,
    domainMapping: DomainMappingReducer,
    domainSuppression: domainSuppressionReducer,
    companyMapping:companyMappingReducer,
    userLanguageCountryData: userLanguageCountryDataReducer
    
});
